import React, { Component } from 'react';
import { db } from '../firebase/Firebase';
import ReactToPrint from "react-to-print";
import { flattenDoc } from '../Util';
import ColType from '../Types';
import { Collapse, notification, Button, Icon, Popconfirm, DatePicker } from 'antd';
import Empty from '../customcomponents/Empty';
import PersonAvatar from '../customcomponents/PersonAvatar';
import moment from 'moment';
import ProfessionalLearningReportForm from './ProfessionalLearningReportForm';
import ProfessionalLearningReportUpdateForm from './ProfessionalLearningReportUpdateForm';
import ConsultationReportForm from './ConsultationReportForm';
import ConsultationReportUpdateForm from './ConsultationReportUpdateForm';
const { Panel } = Collapse;
var html2pdf = null
if (typeof window !== 'undefined') {
    html2pdf = require('html2pdf.js')
}

const successMessage = (description) => {
    notification.open({
        type: 'success',
        message: description,
        placement: 'bottomRight'
    });
};

const errorMessage = (description) => {
    notification.open({
        type: 'error',
        message: description,
        placement: 'bottomRight'
    });
};

class ConsultationReport extends Component {
    state = {
        reports: [],
        reportsMounted: false,
        reportsListener: null,
        reportKeys: [
            'description',
        ],
    };

    componentDidMount() {
        let reportsListener = db.collection(ColType.consultationReport)
            .where('districtId', '==', this.props.districtId)
            .where('schoolId', '==', this.props.schoolId)
            .orderBy('timeStamp', 'desc')
            .limit(50)
            .onSnapshot((querySnapshot) => {
                let reports = [];
                querySnapshot.forEach((doc) => {
                    reports.push(flattenDoc(doc));
                });
                this.setState({
                    reports: reports,
                    reportsMounted: true
                });
            });

        this.setState({
            reportsListener: reportsListener
        });
    }

    componentWillUnmount() {
        if (this.state.reportsListener) {
            this.state.reportsListener();
        }
    }

    render() {
        return (
            <div>
                <div className='w-100 flex flex-v-center mb-2'>
                    <div className='font-30 font-bold'>Consultation Reports</div>
                    <ConsultationReportForm
                        districtId={this.props.districtId}
                        schoolId={this.props.schoolId}
                        personId={this.props.personId}
                    />
                </div>

                <div>
                    {this.state.reportsMounted ?
                        this.state.reports.length === 0 ?
                            <Empty
                                containerClassName="mt-2 text-center"
                                width={55}
                                height={55}
                                image={'/empty/goals.png'}
                                description={'There are currently no consultation reports.'}
                            />
                            :
                            <Collapse bordered={true}>
                                {this.state.reports.map((report) => {
                                    let contentRefName = 'content-' + report.id;
                                    return (
                                        <Panel
                                            header={
                                                <div className='font-20 w-100 flex flex-v-center'>
                                                    <div className='ellipsis' style={{ maxWidth: '400px' }}>{report.firstName + ' ' + report.lastName}</div>
                                                    <div className='ml-auto flex flex-v-center'>
                                                        <span className='font-16 pl-05'>
                                                            {(report.timeStamp ? moment.utc(report.timeStamp.seconds * 1000).format("MM/DD/YYYY") : '')}
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                            key={report.id}
                                        >
                                            <div className='pt-2'>
                                                <div className='font-20 mb-2 flex flex-v-center w-100 font-black'>
                                                    <div className='ml-auto flex flex-v-center'>
                                                        <ConsultationReportUpdateForm
                                                            districtId={this.props.districtId}
                                                            schoolId={this.props.schoolId}
                                                            report={report}
                                                        />
                                                        <ReactToPrint
                                                            trigger={() =>
                                                                <Button
                                                                    type="default"
                                                                    className="lg-btn mr-1"
                                                                >
                                                                    <Icon type="download" /> Download
                                                                </Button>
                                                            }
                                                            content={() => this[contentRefName]}
                                                            print={async (printIframe) => {
                                                                if (!html2pdf) {
                                                                    return new Promise((resolve, reject) => { resolve() })
                                                                }
                                                                const document = printIframe.contentDocument
                                                                if (document) {
                                                                    const html = document.getElementsByTagName('html')[0]
                                                                    await html2pdf().from(html).set({
                                                                        image: { type: 'png', quality: 1.0 },
                                                                        html2canvas: { scale: 1.0 },
                                                                        margin: 12,
                                                                        pagebreak: { mode: ['avoid-all', 'css'], avoid: '.page-break-inside-avoid-not-important' },
                                                                    }).save('Dot-It-Consultation-Report.pdf')
                                                                }

                                                                return new Promise((resolve, reject) => { resolve() })
                                                            }}
                                                        />
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button
                                                                    type="default"
                                                                    className="lg-btn mr-1"
                                                                >
                                                                    <Icon type="printer" /> Print
                                                                </Button>
                                                            )}
                                                            content={() => this[contentRefName]}
                                                        />


                                                        {this.props.personId === report.personId ?
                                                            <Popconfirm
                                                                title="Are you sure you want to delete this report?"
                                                                onConfirm={() => {
                                                                    db.collection(ColType.consultationReport)
                                                                        .doc(report.id)
                                                                        .delete()
                                                                        .then(() => {
                                                                            successMessage('Report deleted successfully.');
                                                                        })
                                                                        .catch((error) => {
                                                                            console.log(error);
                                                                            errorMessage('Error deleting report, please try again.');
                                                                        });
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button
                                                                    type="danger"
                                                                    className="br-50 flex flex-v-center flex-h-center font-20"
                                                                    style={{
                                                                        padding: '16px',
                                                                        width: '50px',
                                                                        height: '50px'
                                                                    }}
                                                                >
                                                                    <Icon type="delete" />
                                                                </Button>
                                                            </Popconfirm> : ''}
                                                    </div>
                                                </div>
                                                <div ref={el => (this[contentRefName] = el)}>
                                                    <div className='font-black'>
                                                        <div>
                                                            <img src="/dotitlogo.png" alt="logo" height="50" className='mb-1' />
                                                        </div>
                                                        <div>
                                                            <div className='font-24 mb-2'>Consultation Report</div>
                                                        </div>
                                                        <div>
                                                            <div className='font-20'>{report.firstName + ' ' + report.lastName}</div>
                                                        </div>
                                                        <div className='mb-2'>
                                                            <div className='font-16'>{report.timeStamp ? moment.utc(report.timeStamp.seconds * 1000).format("MM/DD/YYYY") : ''}</div>
                                                        </div>
                                                        <div className='mb-2'>
                                                            <div className='font-16'>
                                                                {report.description.includes('\n')
                                                                    ? report.description.split('\n').map((line, index) => (
                                                                        <React.Fragment key={index}>
                                                                            {line}
                                                                            <br />
                                                                        </React.Fragment>
                                                                    ))
                                                                    : report.description}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Panel>
                                    );
                                })}
                            </Collapse>
                        : ''}
                </div>
            </div>
        );
    }
}

export default ConsultationReport;