import React, { Component } from 'react'
import { Route } from 'react-router-dom'
//import { Link } from 'react-router-dom'
import '../../styles/GlobalStyle.css'
//import CustomFooter from '../../login/CustomFooter'
//import SubjectHistogram from '../../admin/SubjectHistogram'
//import SupportPieChartTiers from '../../admin/SupportPieChartTiers'
//import PersonAvatar from '../../customcomponents/PersonAvatar'
//import Empty from '../../customcomponents/Empty'
//import { getIDFromURL } from '../.././Util'
import PacingGuideSC from './PacingGuideSC'
import PacingGuideLessonPlanV2 from './PacingGuideLessonPlanV2'
import PacingGuideLessonPlanV3 from './PacingGuideLessonPlanV3'
import WeeklyPlanner from './WeeklyPlanner'
import { db, firebase } from '../../firebase/Firebase'
import ColType from '../.././Types'
import { flattenDoc, getSCBiology1Standards, getStandardsColType, getStateStandardNamesDict } from '../../Util'
import { Button } from 'antd'
//const { Content } = Layout

const PacingGuideComponent = (props, pacingGuideBaseProps, state) => {
    // other state pacing guides can be handled here with conditionals
    return <PacingGuideSC
        pacingGuideBaseProps={state}
        teacherBaseProps={pacingGuideBaseProps.teacherBaseProps}
        {...props}
    />
}

const LessonPlanComponent = (props, pacingGuideBaseProps, state) => {
    return <PacingGuideLessonPlanV3
        pacingGuideBaseProps={state}
        teacherBaseProps={pacingGuideBaseProps.teacherBaseProps}
        {...props}
    />
}

const WeeklyPlannerComponent = (props, pacingGuideBaseProps, state) => {
    return <WeeklyPlanner
        pacingGuideBaseProps={state}
        teacherBaseProps={pacingGuideBaseProps.teacherBaseProps}
        {...props}
    />
}

class PacingGuide extends Component {
    state = {
        prevStandardName: '',
        prevStandardCategory: '',
        standardName: 'Kindergarten',
        standardCategory: 'ELA',
        standardCategories: ['ELA', 'Math', 'Social Studies', 'Science'],
        standards: [],
        standardsDict: {},
        standardNamesDict: {},
        // standards that will be official in the future
        futureStandards: [],
        futureStandardsDict: {},
        setWeeksFunc: (weeks) => { },
        onStandardNameChangeFunc: (value) => { },
        onStandardCategoryChangeFunc: (value) => { },
        setStandardNameAndCategoryFunc: (standardName, standardCategory) => { },
        onSetActiveYearFunc: (year) => { },
        onSetPacingGuideMode: (mode) => { },
        totalWeeks: 37,
        weeks: null,
        weekNums: [],
        gradeDictAcrossCategories: {
            'Kindergarten': 'K',
            '1st Grade': '1',
            '2nd Grade': '2',
            '3rd Grade': '3',
            '4th Grade': '4',
            '5th Grade': '5',
            '6th Grade': '6',
            '7th Grade': '7',
            '8th Grade': '8',
        },
        pacingGuide: null,
        pacingGuideListener: null,
        futurePacingGuide: null,
        scheduledStandardsDict: {},
        mounted: false,
        pacingGuidePDFURL: '',
        pacingGuidePDFURLLoading: false,
        pacingGuideHasData: false,
        assessments: null,
        assessmentsDict: null,
        assessmentsWeekDict: {},
        biology1SCStandardsDict: getSCBiology1Standards(),
        quizzes: [],
        quizzesForEndWeek: {},
        state: '',
        isV3: true,
        standardYears: null,
        prevYear: '-1',
        activeYear: '',
        pacingGuideMode: 'current',
        initialized: false,
        userSettingsInitialized: false,
    }

    componentDidMount() {
        this.setState({
            setWeeksFunc: this.setWeeks,
            onStandardNameChangeFunc: this.onStandardNameChange,
            onStandardCategoryChangeFunc: this.onStandardCategoryChange,
            setStandardNameAndCategoryFunc: this.setStandardNameAndCategory,
            onSetActiveYearFunc: this.onSetActiveYear,
            onSetPacingGuideMode: this.onSetPacingGuideMode
        })

        let weekNums = []
        for (let i = 0; i < this.state.totalWeeks; i++) {
            weekNums.push(i)
        }
        this.setState({
            weekNums: weekNums,
        })

        this.getData()

        this.setState({
            mounted: true,
        })
    }

    componentWillReceiveProps(nextProps) {
        this.getData()
    }

    componentWillUnmount() {
        if (this.state.pacingGuideListener) {
            this.state.pacingGuideListener()
        }
    }

    tryUpdateUserSettings = () => {
        if (this.props.teacherBaseProps &&
            this.props.teacherBaseProps.user &&
            this.props.teacherBaseProps.user.id) {
            db.collection(ColType.userSettings)
                .doc(this.props.teacherBaseProps.user.id)
                .set({
                    pacingGuideStandardCategory: this.state.standardCategory,
                    pacingGuideStandardName: this.state.standardName,
                    updateTimestamp: firebase.firestore.FieldValue.serverTimestamp(),
                }, { merge: true });
        }
    }

    onSetPacingGuideMode = (mode) => {
        let stateObj = {
            pacingGuideMode: mode,
        }

        // current year standard names do not have this future standardName, go back to K
        if (mode === 'current' &&
            this.state.standardNamesDict &&
            this.state.standardNamesDict.hasOwnProperty(this.state.standardCategory) &&
            this.state.standardNamesDict[this.state.standardCategory] &&
            !this.state.standardNamesDict[this.state.standardCategory].includes(this.state.standardName)) {
            stateObj.standardName = 'Kindergarten';
        } else if (mode === 'future' &&
            this.state.standardNamesDict &&
            this.state.standardNamesDict.hasOwnProperty('Future' + this.state.standardCategory) &&
            this.state.standardNamesDict['Future' + this.state.standardCategory] &&
            !this.state.standardNamesDict['Future' + this.state.standardCategory].includes(this.state.standardName)) {
            stateObj.standardName = 'Kindergarten';
        }

        this.setState(stateObj, () => {
            // a change in standardName occurred, get data
            if (stateObj.hasOwnProperty('standardName')) {
                this.getData()
            }
        });
    }

    onSetActiveYear = (year) => {
        this.setState({
            activeYear: year,
        }, () => {
            this.getData()
        })
    }

    setStateAsync(state) {
        return new Promise((resolve) => {
            this.setState(state, () => resolve(this.state));
        });
    }

    getData = async () => {
        if (!(this.props.teacherBaseProps &&
            this.props.teacherBaseProps.user &&
            this.props.teacherBaseProps.district)) {
            return
        }

        if (!this.state.userSettingsInitialized) {
            try {
                const userSettings = await db.collection(ColType.userSettings)
                    .doc(this.props.teacherBaseProps.user.id)
                    .get()
                if (userSettings.exists) {
                    const userSettingsData = flattenDoc(userSettings)
                    if (userSettingsData.hasOwnProperty('pacingGuideStandardCategory') &&
                        userSettingsData.hasOwnProperty('pacingGuideStandardName') &&
                        userSettingsData.pacingGuideStandardCategory &&
                        userSettingsData.pacingGuideStandardName) {
                        await this.setStateAsync({
                            standardCategory: userSettingsData.pacingGuideStandardCategory,
                            standardName: userSettingsData.pacingGuideStandardName,
                        });
                    }
                } else {

                }
            } catch (e) {
                //console.error(e)
            }
            this.setState({
                userSettingsInitialized: true,
            });
        }

        this.setState({
            state: this.props.teacherBaseProps.district.state,
        })

        if (Object.keys(this.state.standardNamesDict).length === 0) {
            this.setState({
                standardNamesDict: getStateStandardNamesDict(this.props.teacherBaseProps.district.state)
            })
        }

        if (this.state.standardName === this.state.prevStandardName &&
            this.state.standardCategory === this.state.prevStandardCategory &&
            this.state.activeYear === this.state.prevYear) {
            return
        }

        this.setState({
            prevStandardName: this.state.standardName,
            prevStandardCategory: this.state.standardCategory,
            prevYear: this.state.activeYear,
        })

        const standardsColType = getStandardsColType(this.props.teacherBaseProps.district.state)
        //console.log(this.props.teacherBaseProps.district.state, standardsColType)

        if (this.state.pacingGuideListener) {
            this.state.pacingGuideListener()
            this.setState({
                pacingGuideListener: null,
            })
        }

        this.setState({
            pacingGuidePDFURLLoading: true,
        }, () => {
            var storage = firebase.app().storage("gs://pacingguidepdfs")
            var child = storage.ref().child(this.props.teacherBaseProps.district.id)
            // get year folders
            child.listAll().then((res) => {
                //console.log('storage res', res)
                // this is the year folder, maybe need to sort by year later
                // always using idx 0 for now
                if (res.prefixes.length > 0) {
                    let yearFolder = res.prefixes[0]
                    let fn = this.state.standardCategory + '_' + this.state.standardName + '.pdf'
                    fn = fn.replaceAll(' ', '_')
                    yearFolder.child(fn).getDownloadURL().then((url) => {
                        //console.log('url', url)
                        this.setState({
                            pacingGuidePDFURL: url,
                            pacingGuidePDFURLLoading: false,
                        })
                    }).catch((error) => {
                        //console.log(error)
                        this.setState({
                            pacingGuidePDFURL: '',
                            pacingGuidePDFURLLoading: false,
                        })
                    })
                }
            }).catch((error) => {
                //console.log(error)
            })
        })

        let standardsSnapshot = null

        let state = this.props.teacherBaseProps.district.state
        // test district is NY, but we want SC standards
        if (state === 'NY') {
            state = 'SC'
        }
        let standardYears = this.state.standardYears
        let activeYear = this.state.activeYear
        if (!this.state.standardYears) {
            let doc = await db.collection(ColType.standardYears)
                .doc('all')
                .get()

            if (!doc.exists) {
                //console.log('standardYears doc does not exist')
                return
            }
            standardYears = flattenDoc(doc)
            activeYear = standardYears.currentYear
            this.setState({
                standardYears: standardYears,
                activeYear: standardYears.currentYear,
            })
        }

        const stateAndStandardCategory = state + this.state.standardCategory
        if (!standardYears.hasOwnProperty(stateAndStandardCategory)) {
            console.error('standardYears does not have key', stateAndStandardCategory)
            return
        }
        let standardYear = standardYears[stateAndStandardCategory]
        let standardName = this.state.standardName
        // KY ELA standards are 9-10 and 11-12
        if (state === 'KY' && this.state.standardCategory === 'ELA') {
            if (standardName === '10th Grade') {
                standardName = '9th Grade'
            } else if (standardName === '12th Grade') {
                standardName = '11th Grade'
            }
        }

        if (this.state.standardCategory === 'Math' &&
            state === 'SC') {
            db.collection(ColType.standards)
                .where('state', '==', state)
                .where('standardName', '==', standardName)
                .where('standardCategory', '==', this.state.standardCategory)
                .where('year', '==', '2023')
                .orderBy('sortOrder')
                .get()
                .then((querySnapshot) => {
                    let futureStandards = []
                    let futureStandardsDict = {}
                    querySnapshot.forEach((doc) => {
                        let standard = flattenDoc(doc)
                        futureStandards.push(standard)
                        futureStandardsDict[standard.standard] = standard
                    })
                    //console.log('futureStandards', futureStandards)
                    this.setState({
                        futureStandards: futureStandards,
                        futureStandardsDict: futureStandardsDict,
                    }, () => {
                        db.collection(ColType.futurePacingGuides)
                            .where('standardName', '==', this.state.standardName)
                            .where('standardCategory', '==', this.state.standardCategory)
                            .get()
                            .then((querySnapshot) => {
                                let futurePacingGuide = null
                                querySnapshot.forEach((doc) => {
                                    futurePacingGuide = flattenDoc(doc)
                                })
                                //console.log('futurePacingGuide', futurePacingGuide)
                                if (futurePacingGuide) {
                                    // add standards to week 0
                                    let weeks = futurePacingGuide.weeks
                                    weeks[0] = []
                                    for (let standard of futureStandards) {
                                        weeks[0].push(standard)
                                    }
                                }
                                let pacingGuideMode = this.state.pacingGuideMode
                                if (!futurePacingGuide && pacingGuideMode === 'future') {
                                    pacingGuideMode = 'current'
                                }
                                this.setState({
                                    futurePacingGuide: futurePacingGuide,
                                    pacingGuideMode: pacingGuideMode,
                                })
                            })
                    })
                })
        } else {
            // clear future standards
            this.setState({
                pacingGuideMode: 'current',
                futurePacingGuide: null,
                futureStandards: [],
                futureStandardsDict: {},
            })
        }

        standardsSnapshot = await db.collection(ColType.standards)
            .where('state', '==', state)
            .where('standardName', '==', standardName)
            .where('standardCategory', '==', this.state.standardCategory)
            .where('year', '==', standardYear)
            .orderBy('sortOrder')
            .get()

        let standards = []
        let standardsDict = {}
        standardsSnapshot.forEach((doc) => {
            let standard = flattenDoc(doc)
            standards.push(standard)
            standardsDict[standard.standard] = standard
        })

        //console.log(standards)

        let pacingGuideListener = db.collection(ColType.pacingGuides)
            .where('standardName', '==', this.state.standardName)
            .where('standardCategory', '==', this.state.standardCategory)
            .where('districtId', '==', this.props.teacherBaseProps.district.id)
            .where('year', '==', activeYear)
            .limit(20)
            .onSnapshot((querySnapshot) => {
                if (querySnapshot.empty) {
                    // pacing guides are created ahead of time
                    console.error('pacing guide does not exist')
                    // set pacing guide to empty
                    this.setState({
                        initialized: true,
                        pacingGuide: null,
                    })
                    return
                }
                if (querySnapshot.docs.length > 1) {
                    console.error('more than one pacing guide exists')
                }

                let pacingGuide = flattenDoc(querySnapshot.docs[0])

                // always set week[0] to standards as they may have changed
                let weeks = pacingGuide.weeks
                weeks[0] = []
                for (let standard of standards) {
                    weeks[0].push(standard)
                }

                // only used to determine which select we should show in LessonPlanV2
                let pacingGuideHasData = false
                let scheduledStandardsDict = {}
                // skip week 0 (side menu)
                for (let i = 1; i < this.state.totalWeeks; i++) {
                    let scheduledStandards = weeks[i]
                    if (scheduledStandards.length > 0) {
                        pacingGuideHasData = true
                    }
                    for (let standard of scheduledStandards) {
                        let key = standard.standard
                        // if the scheduled standard is not in the side menu standards,
                        // continue (this is to handle Biology standards in Earth Science)
                        // Biology standards won't be in the side menu standards for Earth Science
                        if (!standardsDict.hasOwnProperty(key)) {
                            continue
                        }
                        if (!scheduledStandardsDict.hasOwnProperty(key)) {
                            scheduledStandardsDict[key] = 0
                        }
                        scheduledStandardsDict[key] += 1
                    }
                }

                //console.log(pacingGuide)

                this.setState({
                    initialized: true,
                    pacingGuide: pacingGuide,
                    pacingGuideHasData: true,
                    scheduledStandardsDict: scheduledStandardsDict,
                })
            })

        this.setState({
            pacingGuideListener: pacingGuideListener,
            standards: standards,
            standardsDict: standardsDict,
        })

        db.collection(ColType.quizzes)
            .where('standardName', '==', this.state.standardName)
            .where('standardCategory', '==', this.state.standardCategory)
            .where('districtId', '==', this.props.teacherBaseProps.district.id)
            .where('queryId', '==', this.props.teacherBaseProps.district.id)
            .where('year', '==', activeYear)
            .where('live', '==', true)
            .orderBy('createdAt', 'desc')
            .get()
            .then((querySnapshot) => {
                let quizzes = []
                let quizzesForEndWeek = {}
                querySnapshot.forEach((doc) => {
                    let quiz = flattenDoc(doc)
                    //console.log('quiz', quiz)
                    quiz.docs = JSON.parse(quiz.docs)
                    // shuffle questions
                    quiz.docs.map((doc) => {
                        try {
                            // will throw error here before shuffle, determining if format is correct
                            let answers = doc.components[0].answers
                            // format is known, shuffle
                            doc.components[0].answers = shuffleArray(doc.components[0].answers)
                        } catch (e) {
                            // pass
                            return false
                        }
                    })

                    quizzes.push(quiz)
                    if (!quizzesForEndWeek.hasOwnProperty(quiz.endWeek)) {
                        quizzesForEndWeek[quiz.endWeek] = []
                    }
                    quizzesForEndWeek[quiz.endWeek].push(quiz)
                })

                Object.keys(quizzesForEndWeek).map((key) => {
                    quizzesForEndWeek[key].sort((a, b) => {
                        if (a.hasOwnProperty('assessmentTitle') &&
                            b.hasOwnProperty('assessmentTitle')) {
                            if (a.assessmentTitle < b.assessmentTitle) {
                                return -1
                            } else if (a.assessmentTitle > b.assessmentTitle) {
                                return 1
                            }

                            return 0
                        }

                        return 0
                    })

                    return false
                })

                this.setState({
                    quizzes: quizzes,
                    quizzesForEndWeek: quizzesForEndWeek,
                })
                //console.log('quizzes', quizzes, quizzesForEndWeek)
            })
    }

    getBiologyStandards = () => {
        const standardsColType = getStandardsColType(this.props.teacherBaseProps.district.state)

        db.collection(standardsColType)
            .where('standardName', '==', 'Biology')
            .where('standardCategory', '==', 'Science')
            .orderBy('sortOrder')
            .get()
            .then((querySnapshot) => {
                let standards = []
                let standardsDict = {}
                querySnapshot.forEach((doc) => {
                    let standard = flattenDoc(doc)
                    standards.push(standard)
                    standardsDict[standard.standard] = standard
                })

                let pacingGuide = this.state.pacingGuide
                for (let standard of standards) {
                    pacingGuide.weeks[0].push(standard)
                }
                this.setState({
                    pacingGuide: pacingGuide,
                })
            })
    }

    filterOutBiology2Standards = () => {
        if (this.state.standardName === 'Biology') {
            let pacingGuide = this.state.pacingGuide
            for (let i = 1; i < this.state.totalWeeks; i++) {
                let standards = pacingGuide.weeks[i]
                let filteredStandards = []
                for (let standard of standards) {
                    if (this.state.biology1SCStandardsDict.hasOwnProperty(standard.standard)) {
                        filteredStandards.push(standard)
                    }
                }
                pacingGuide.weeks[i] = filteredStandards
            }
            this.setState({
                pacingGuide: pacingGuide,
            })
        }
    }

    setStandards = (standards) => {
        this.setStandards({
            standards: standards,
        })
    }

    onStandardNameChange = (value) => {
        this.setState({
            standardName: value,
        }, () => {
            this.getData()
            this.tryUpdateUserSettings()
        })
    }

    onStandardCategoryChange = (value) => {
        let stateObj = { standardCategory: value }
        if (!this.state.gradeDictAcrossCategories.hasOwnProperty(this.state.standardName)) {
            stateObj.standardName = 'Kindergarten'
        }
        this.setState(stateObj, () => {
            this.getData()
            this.tryUpdateUserSettings()
        })
    }

    setStandardNameAndCategory = (standardName, standardCategory) => {
        let stateObj = {
            standardName: standardName,
            standardCategory: standardCategory,
        }
        if (!this.state.gradeDictAcrossCategories.hasOwnProperty(standardName)) {
            stateObj.standardName = 'Kindergarten'
        }
        this.setState(stateObj, () => {
            this.getData()
        })
    }

    setWeeks = (weeks) => {
        if (this.state.pacingGuide) {
            // set immediately so that there is no visual delay
            // when the db update below finishes, the listener
            // will also update
            let pacingGuide = this.state.pacingGuide
            pacingGuide.weeks = weeks
            this.setState({
                pacingGuide: pacingGuide,
            })

            // Make a shallow copy of the object
            let weeksToDB = Object.assign({}, weeks);
            // Delete the first property (standards list that is
            // added in the onSnapshot listener on each update)
            // save space by not adding to db
            delete weeksToDB[0];

            db.collection(ColType.pacingGuides)
                .doc(this.state.pacingGuide.id)
                .update({
                    weeks: weeksToDB,
                })
        }
    }

    isStandardScheduled = (weeks, standard) => {
        for (let i = 1; i < this.state.totalWeeks; i++) {
            const standards = weeks[i]
            for (let j = 0; j < standards.length; j++) {
                const standardInternal = standards[j]
                if (standardInternal.standard === standard.standard) {
                    return true
                }
            }
        }
        return false
    }

    updatePacingGuideStandards = () => {
        // updates the pacing guide standards with the latest standards
        // based on the standard.standard
        if (!this.state.pacingGuide) {
            return
        }
        if (!this.state.standardsDict) {
            return
        }
        let weeks = {}
        for (let i = 0; i < this.state.totalWeeks; i++) {
            weeks[i] = []
        }
        for (let i = 1; i < this.state.totalWeeks; i++) {
            let standards = this.state.pacingGuide.weeks[i]
            for (let standard of standards) {
                if (!this.state.standardsDict.hasOwnProperty(standard.standard)) {
                    console.error('standard not found', standard.standard, 'not adding');
                    //return;
                    continue;
                }

                // add newer version of the standard
                weeks[i].push(this.state.standardsDict[standard.standard])
            }
        }

        console.log('updated');
        this.setWeeks(weeks);
    }

    setPacingGuideWithPresetStandards = () => {
        /*
        // KY - OpenSciEd 6th Grade Science Pacing Guide
        const pacingGuideStandardsDict = {
            1: ["6-PS1-1", "6-PS1-4"],
            2: ["6-PS1-1", "6-PS1-4"],
            3: ["6-PS1-1", "6-PS1-4"],
            4: ["6-PS1-1", "6-PS1-4"],
            5: ["6-PS2-1", "6-PS2-4"],
            6: ["6-PS2-1", "6-PS2-4"],
            7: ["6-PS1-4", "MS-ETS1-4"],
            8: ["6-PS1-4", "MS-ETS1-4"],
            9: ["6-PS1-4", "MS-ETS1-4"],
            10: ["6-PS1-4", "MS-ETS1-4"],
            11: ["6-PS1-4", "MS-ETS1-4"],
            12: ["6-PS1-4", "MS-ETS1-4"],
            13: ["6-ESS2-4", "6-ESS2-5"],
            14: ["6-ESS2-4", "6-ESS2-5"],
            15: ["6-ESS2-5", "6-ESS2-6"],
            16: ["6-ESS2-5", "6-ESS2-6"],
            17: ["6-ESS2-6", "6-PS1-4"],
            18: ["6-ESS2-6", "6-PS1-4"],
            19: ["6-ESS2-1", "6-ESS2-2"],
            20: ["6-ESS2-1", "6-ESS2-2"],
            21: ["6-ESS2-2", "6-ESS2-3"],
            22: ["6-ESS2-2", "6-ESS2-3"],
            23: ["6-ESS2-3", "6-ESS1-1"],
            24: ["6-ESS1-1", "6-ESS1-2"],
            25: ["6-ESS1-2", "6-ESS1-3"],
            26: ["6-ESS1-3", "MS-ETS1-1"],
            27: ["MS-ETS1-1", "MS-ETS1-2"],
            28: ["MS-ETS1-2", "MS-ETS1-3"],
            29: ["MS-ETS1-3", "MS-ETS1-4"],
            30: ["MS-ETS1-3", "MS-ETS1-4"],
            31: ["6-LS2-1", "6-LS2-2"],
            32: ["6-LS2-1", "6-LS2-2"],
            33: ["6-LS2-2", "6-LS2-3"],
            34: ["6-LS2-2", "6-LS2-3"],
            35: ["6-LS2-3", "6-LS1-6"],
            36: ["6-LS2-3", "6-LS1-6"]
        };
        */
        /*
        // KY - OpenSciEd 7th Grade Science Pacing Guide
        const pacingGuideStandardsDict = {
            1: ["7-PS1-2", "7-PS1-5", "7-LS1-8"],
            2: ["7-PS1-2", "7-PS1-5", "7-LS1-8"],
            3: ["7-PS1-2", "7-PS1-5", "7-LS1-8"],
            4: ["7-PS1-2", "7-PS1-5", "7-LS1-8"],
            5: ["7-PS1-2", "7-PS1-5", "7-LS1-8"],
            6: ["7-PS1-2", "7-PS1-5", "7-LS1-8"],
            7: ["7-PS1-6", "MS-ETS1-2", "MS-ETS1-3"],
            8: ["7-PS1-6", "MS-ETS1-2", "MS-ETS1-3"],
            9: ["7-PS1-6", "MS-ETS1-3", "MS-ETS1-4"],
            10: ["7-PS1-6", "MS-ETS1-3", "MS-ETS1-4"],
            11: ["7-PS1-6", "MS-ETS1-4", "7-PS3-3"],
            12: ["7-PS1-6", "MS-ETS1-4", "7-PS3-3"],
            13: ["7-LS1-1", "7-LS1-3", "7-LS1-7"],
            14: ["7-LS1-1", "7-LS1-3", "7-LS1-7"],
            15: ["7-LS1-1", "7-LS1-3", "7-LS1-7"],
            16: ["7-LS1-1", "7-LS1-3", "7-LS1-7"],
            17: ["7-LS1-1", "7-LS1-3", "7-PS3-4"],
            18: ["7-LS1-1", "7-LS1-3", "7-PS3-4"],
            19: ["7-LS1-2", "7-LS1-7", "7-PS1-5"],
            20: ["7-LS1-2", "7-LS1-7", "7-PS1-5"],
            21: ["7-LS1-2", "7-LS1-7", "7-PS3-1"],
            22: ["7-LS1-2", "7-LS1-7", "7-PS3-1"],
            23: ["7-LS1-2", "7-LS1-7", "7-PS3-2"],
            24: ["7-LS1-2", "7-LS1-7", "7-PS3-2"],
            25: ["MS-ETS1-1", "7-PS2-2", "7-PS2-3"],
            26: ["MS-ETS1-1", "7-PS2-2", "7-PS2-3"],
            27: ["MS-ETS1-1", "7-PS2-3", "7-PS2-5"],
            28: ["MS-ETS1-1", "7-PS2-3", "7-PS2-5"],
            29: ["MS-ETS1-1", "7-PS4-1", "7-PS4-2"],
            30: ["MS-ETS1-1", "7-PS4-2", "7-PS4-3"],
            31: ["MS-ETS1-2", "7-PS3-5", "7-PS4-1"],
            32: ["MS-ETS1-2", "7-PS3-5", "7-PS4-1"],
            33: ["MS-ETS1-2", "7-PS3-5", "7-PS4-2"],
            34: ["MS-ETS1-2", "7-PS3-5", "7-PS4-2"],
            35: ["MS-ETS1-2", "7-PS3-5", "7-PS4-3"],
            36: ["MS-ETS1-2", "7-PS3-5", "7-PS4-3"]
        };
        */
        /*
        // KY - OpenSciEd 8th Grade Science Pacing Guide
        const pacingGuideStandardsDict = {
            // Unit 1: Contact Forces (Weeks 1-6)
            1: ["MS-ETS1-2", "MS-ETS1-3", "8-LS1-4"],
            2: ["MS-ETS1-2", "MS-ETS1-3", "8-LS1-4"],
            3: ["MS-ETS1-2", "MS-ETS1-3", "8-LS1-4"],
            4: ["MS-ETS1-2", "MS-ETS1-3", "8-LS1-4"],
            5: ["MS-ETS1-2", "MS-ETS1-3", "8-LS1-4"],
            6: ["MS-ETS1-2", "MS-ETS1-3", "8-LS1-4"],

            // Unit 2: Sound Waves (Weeks 7-12)
            7: ["8-PS1-3", "8-ESS3-1", "8-ESS3-2"],
            8: ["8-PS1-3", "8-ESS3-1", "8-ESS3-2"],
            9: ["8-PS1-3", "8-ESS3-1", "8-ESS3-2"],
            10: ["8-PS1-3", "8-ESS3-1", "8-ESS3-2"],
            11: ["8-PS1-3", "8-ESS3-1", "8-ESS3-2"],
            12: ["8-PS1-3", "8-ESS3-1", "8-ESS3-2"],

            // Unit 3: Forces at a Distance (Weeks 13-18)
            13: ["8-ESS3-2", "8-ESS3-3", "8-ESS3-4"],
            14: ["8-ESS3-2", "8-ESS3-3", "8-ESS3-4"],
            15: ["8-ESS3-2", "8-ESS3-3", "8-ESS3-4"],
            16: ["8-ESS3-2", "8-ESS3-3", "8-ESS3-4"],
            17: ["8-ESS3-2", "8-ESS3-3", "8-ESS3-5"],
            18: ["8-ESS3-2", "8-ESS3-3", "8-ESS3-5"],

            // Unit 4: Earth in Space (Weeks 19-24)
            19: ["8-ESS1-4", "8-LS1-5", "8-LS2-4"],
            20: ["8-ESS1-4", "8-LS1-5", "8-LS2-4"],
            21: ["8-ESS1-4", "8-LS1-5", "8-LS2-4"],
            22: ["8-ESS1-4", "8-LS1-5", "8-LS2-5"],
            23: ["8-ESS1-4", "8-LS1-5", "8-LS2-5"],
            24: ["8-ESS1-4", "8-LS1-5", "8-LS2-5"],

            // Unit 5: Genetics (Weeks 25-30)
            25: ["8-LS1-5", "8-LS3-1", "8-LS3-2"],
            26: ["8-LS1-5", "8-LS3-1", "8-LS3-2"],
            27: ["8-LS1-5", "8-LS3-1", "8-LS3-2"],
            28: ["8-LS1-5", "8-LS3-1", "8-LS3-2"],
            29: ["8-LS1-5", "8-LS3-1", "8-LS4-5"],
            30: ["8-LS1-5", "8-LS3-1", "8-LS4-5"],

            // Unit 6: Natural Selection & Common Ancestry (Weeks 31-36)
            31: ["8-LS4-1", "8-LS4-2", "8-LS4-3"],
            32: ["8-LS4-1", "8-LS4-2", "8-LS4-3"],
            33: ["8-LS4-1", "8-LS4-2", "8-LS4-4"],
            34: ["8-LS4-1", "8-LS4-2", "8-LS4-4"],
            35: ["8-LS4-1", "8-LS4-6", "MS-ETS1-1"],
            36: ["8-LS4-1", "8-LS4-6", "MS-ETS1-4"]
        };
        */
        /*
         // KY - 6th Grade Default ELA Pacing Guide
         const pacingGuideStandardsDict = {
             // Unit 1: Foundations of Reading and Writing (Weeks 1-4)
             1: ["RL.6.1", "RI.6.1", "C.6.4", "L.6.1.a", "RL.6.10"],
             2: ["RL.6.1", "RI.6.1", "C.6.4", "L.6.1.b", "RI.6.10"],
             3: ["RL.6.1", "RI.6.1", "C.6.4", "L.6.1.c", "RL.6.10"],
             4: ["RL.6.1", "RI.6.1", "C.6.4", "L.6.2.a", "L.6.2.b", "RI.6.10"],
 
             // Unit 2: Analyzing Literary Elements (Weeks 5-8)
             5: ["RL.6.2", "RL.6.3", "RL.6.5", "RL.6.4", "C.6.7"],
             6: ["RL.6.2", "RL.6.3", "RL.6.6", "RL.6.4", "RL.6.10"],
             7: ["RL.6.2", "RL.6.5", "RL.6.6", "RL.6.4", "C.6.7"],
             8: ["RL.6.3", "RL.6.5", "RL.6.6", "RL.6.4", "RL.6.10"],
 
             // Unit 3: Informational Text Structures (Weeks 9-12)
             9: ["RI.6.2", "RI.6.3", "RI.6.5", "RI.6.4", "C.6.7"],
             10: ["RI.6.2", "RI.6.3", "RI.6.6", "RI.6.4", "RI.6.10"],
             11: ["RI.6.2", "RI.6.5", "RI.6.6", "RI.6.4", "C.6.7"],
             12: ["RI.6.3", "RI.6.5", "RI.6.6", "RI.6.4", "RI.6.10"],
 
             // Unit 4: Argumentative Writing (Weeks 13-16)
             13: ["C.6.1.a", "C.6.1.b", "C.6.1.c", "RI.6.8", "RL.6.10"],
             14: ["C.6.1.d", "C.6.1.e", "C.6.1.f", "RI.6.8", "C.6.7"],
             15: ["C.6.1.g", "C.6.1.a", "C.6.1.b", "RI.6.8", "RI.6.10"],
             16: ["C.6.1.c", "C.6.1.d", "C.6.1.e", "C.6.1.f", "C.6.7"],
 
             // Unit 5: Informative/Explanatory Writing (Weeks 17-20)
             17: ["C.6.2.a", "C.6.2.b", "C.6.2.c", "RI.6.7", "RL.6.10"],
             18: ["C.6.2.d", "C.6.2.e", "C.6.2.f", "RI.6.7", "C.6.7"],
             19: ["C.6.2.g", "C.6.2.h", "C.6.2.a", "RI.6.7", "RI.6.10"],
             20: ["C.6.2.b", "C.6.2.c", "C.6.2.d", "C.6.2.e", "C.6.7"],
 
             // Unit 6: Narrative Writing (Weeks 21-24)
             21: ["C.6.3.a", "C.6.3.b", "C.6.3.c", "RL.6.3", "RL.6.10"],
             22: ["C.6.3.d", "C.6.3.e", "C.6.3.f", "RL.6.3", "C.6.7"],
             23: ["C.6.3.g", "C.6.3.a", "C.6.3.b", "RL.6.3", "RI.6.10"],
             24: ["C.6.3.c", "C.6.3.d", "C.6.3.e", "C.6.3.f", "C.6.7"],
 
             // Unit 7: Language and Vocabulary Development (Weeks 25-28)
             25: ["L.6.3.a", "L.6.3.b", "L.6.4.a", "RL.6.10", "C.6.7"],
             26: ["L.6.4.b", "L.6.4.c", "L.6.4.d", "RI.6.10"],
             27: ["L.6.5.a", "L.6.5.b", "L.6.5.c", "RL.6.10", "C.6.7"],
             28: ["L.6.3.a", "L.6.4.a", "L.6.5.a", "RI.6.10"],
 
             // Unit 8: Research and Inquiry (Weeks 29-32)
             29: ["C.6.5", "C.6.6", "RI.6.9", "RL.6.10", "C.6.7"],
             30: ["C.6.5", "C.6.6", "RI.6.9", "RI.6.10"],
             31: ["C.6.5", "C.6.6", "RI.6.9", "RL.6.10", "C.6.7"],
             32: ["C.6.5", "C.6.6", "RI.6.9", "RI.6.10"],
 
             // Unit 9: Comparing Texts and Media (Weeks 33-36)
             33: ["RL.6.7", "RL.6.9", "RI.6.7", "RL.6.10", "C.6.7"],
             34: ["RL.6.7", "RL.6.9", "RI.6.9", "RI.6.10", "C.6.7"],
             35: ["RL.6.7", "RI.6.7", "RI.6.9", "RL.6.10", "C.6.7"],
             36: ["RL.6.9", "RI.6.7", "RI.6.9", "RI.6.10", "C.6.7"]
         };
         */
        /*
        // KY - 7th Grade Default ELA Pacing Guide
        const pacingGuideStandardsDict = {
            // Unit 1: Foundations of Reading and Writing (Weeks 1-4)
            1: ["RL.7.1", "RI.7.1", "C.7.4", "L.7.1.a", "RL.7.10"],
            2: ["RL.7.1", "RI.7.1", "C.7.4", "L.7.1.b", "RI.7.10"],
            3: ["RL.7.1", "RI.7.1", "C.7.4", "L.7.2.a", "C.7.7"],
            4: ["RL.7.1", "RI.7.1", "C.7.4", "L.7.2.b", "C.7.7"],

            // Unit 2: Analyzing Literary Elements (Weeks 5-8)
            5: ["RL.7.2", "RL.7.3", "RL.7.5", "RL.7.10", "C.7.7"],
            6: ["RL.7.2", "RL.7.3", "RL.7.6", "RL.7.10", "C.7.7"],
            7: ["RL.7.2", "RL.7.5", "RL.7.6", "RL.7.4", "RL.7.10"],
            8: ["RL.7.3", "RL.7.5", "RL.7.6", "RL.7.4", "RL.7.10"],

            // Unit 3: Informational Text Structures (Weeks 9-12)
            9: ["RI.7.2", "RI.7.3", "RI.7.5", "RI.7.10", "C.7.7"],
            10: ["RI.7.2", "RI.7.3", "RI.7.6", "RI.7.10", "C.7.7"],
            11: ["RI.7.2", "RI.7.5", "RI.7.6", "RI.7.4", "RI.7.10"],
            12: ["RI.7.3", "RI.7.5", "RI.7.6", "RI.7.4", "RI.7.10"],

            // Unit 4: Argumentative Writing (Weeks 13-16)
            13: ["C.7.1.a", "C.7.1.b", "C.7.1.c", "RI.7.8", "C.7.7"],
            14: ["C.7.1.d", "C.7.1.e", "C.7.1.f", "RI.7.8", "RL.7.10"],
            15: ["C.7.1.g", "C.7.1.a", "C.7.1.b", "RI.7.8", "RI.7.10"],
            16: ["C.7.1.c", "C.7.1.d", "C.7.1.e", "C.7.1.f", "C.7.7"],

            // Unit 5: Informative/Explanatory Writing (Weeks 17-20)
            17: ["C.7.2.a", "C.7.2.b", "C.7.2.c", "RI.7.7", "C.7.7"],
            18: ["C.7.2.d", "C.7.2.e", "C.7.2.f", "RI.7.7", "RL.7.10"],
            19: ["C.7.2.g", "C.7.2.h", "C.7.2.a", "RI.7.7", "RI.7.10"],
            20: ["C.7.2.b", "C.7.2.c", "C.7.2.d", "C.7.2.e", "C.7.7"],

            // Unit 6: Narrative Writing (Weeks 21-24)
            21: ["C.7.3.a", "C.7.3.b", "C.7.3.c", "RL.7.3", "C.7.7"],
            22: ["C.7.3.d", "C.7.3.e", "C.7.3.f", "RL.7.3", "RL.7.10"],
            23: ["C.7.3.g", "C.7.3.a", "C.7.3.b", "RL.7.3", "RI.7.10"],
            24: ["C.7.3.c", "C.7.3.d", "C.7.3.e", "C.7.3.f", "C.7.7"],

            // Unit 7: Language and Vocabulary Development (Weeks 25-28)
            25: ["L.7.3.a", "L.7.4.a", "L.7.4.b", "RL.7.10", "C.7.7"],
            26: ["L.7.4.c", "L.7.4.d", "L.7.5.a", "RI.7.10", "C.7.7"],
            27: ["L.7.5.b", "L.7.5.c", "L.7.4.a", "RL.7.10", "C.7.7"],
            28: ["L.7.3.a", "L.7.4.b", "L.7.5.a", "RI.7.10", "C.7.7"],

            // Unit 8: Research and Inquiry (Weeks 29-32)
            29: ["C.7.5", "C.7.6", "RI.7.9", "RL.7.10", "C.7.7"],
            30: ["C.7.5", "C.7.6", "RI.7.9", "RI.7.10", "C.7.7"],
            31: ["C.7.5", "C.7.6", "RI.7.9", "RL.7.10", "C.7.7"],
            32: ["C.7.5", "C.7.6", "RI.7.9", "RI.7.10", "C.7.7"],

            // Unit 9: Comparing Texts and Media (Weeks 33-36)
            33: ["RL.7.7", "RL.7.9", "RI.7.7", "RL.7.10", "C.7.7"],
            34: ["RL.7.7", "RL.7.9", "RI.7.9", "RI.7.10", "C.7.7"],
            35: ["RL.7.7", "RI.7.7", "RI.7.9", "RL.7.10", "C.7.7"],
            36: ["RL.7.9", "RI.7.7", "RI.7.9", "RI.7.10", "C.7.7"]
        };
        */
        /*
         // KY - 8th Grade Default ELA Pacing Guide
         const pacingGuideStandardsDict = {
             // Unit 1: Foundations of Reading and Writing (Weeks 1-4)
             1: ["RL.8.1", "RI.8.1", "C.8.4", "L.8.1.a", "RL.8.10"],
             2: ["RL.8.1", "RI.8.1", "C.8.4", "L.8.1.b", "RI.8.10"],
             3: ["RL.8.1", "RI.8.1", "C.8.4", "L.8.1.c", "C.8.7"],
             4: ["RL.8.1", "RI.8.1", "C.8.4", "L.8.2.a", "L.8.2.b", "L.8.2.c"],
 
             // Unit 2: Analyzing Literary Elements (Weeks 5-8)
             5: ["RL.8.2", "RL.8.3", "RL.8.5", "RL.8.10", "C.8.7"],
             6: ["RL.8.2", "RL.8.3", "RL.8.6", "RL.8.4", "RL.8.10"],
             7: ["RL.8.2", "RL.8.5", "RL.8.6", "RL.8.4", "C.8.7"],
             8: ["RL.8.3", "RL.8.5", "RL.8.6", "RL.8.4", "RL.8.10"],
 
             // Unit 3: Informational Text Structures (Weeks 9-12)
             9: ["RI.8.2", "RI.8.3", "RI.8.5", "RI.8.10", "C.8.7"],
             10: ["RI.8.2", "RI.8.3", "RI.8.6", "RI.8.4", "RI.8.10"],
             11: ["RI.8.2", "RI.8.5", "RI.8.6", "RI.8.4", "C.8.7"],
             12: ["RI.8.3", "RI.8.5", "RI.8.6", "RI.8.4", "RI.8.10"],
 
             // Unit 4: Argumentative Writing (Weeks 13-16)
             13: ["C.8.1.a", "C.8.1.b", "C.8.1.c", "RI.8.8", "C.8.7"],
             14: ["C.8.1.d", "C.8.1.e", "C.8.1.f", "RI.8.8", "RL.8.10"],
             15: ["C.8.1.g", "C.8.1.a", "C.8.1.b", "RI.8.8", "RI.8.10"],
             16: ["C.8.1.c", "C.8.1.d", "C.8.1.e", "C.8.1.f", "C.8.7"],
 
             // Unit 5: Informative/Explanatory Writing (Weeks 17-20)
             17: ["C.8.2.a", "C.8.2.b", "C.8.2.c", "RI.8.7", "C.8.7"],
             18: ["C.8.2.d", "C.8.2.e", "C.8.2.f", "RI.8.7", "RL.8.10"],
             19: ["C.8.2.g", "C.8.2.h", "C.8.2.a", "RI.8.7", "RI.8.10"],
             20: ["C.8.2.b", "C.8.2.c", "C.8.2.d", "C.8.2.e", "C.8.7"],
 
             // Unit 6: Narrative Writing (Weeks 21-24)
             21: ["C.8.3.a", "C.8.3.b", "C.8.3.c", "RL.8.3", "C.8.7"],
             22: ["C.8.3.d", "C.8.3.e", "C.8.3.f", "RL.8.3", "RL.8.10"],
             23: ["C.8.3.g", "C.8.3.a", "C.8.3.b", "RL.8.3", "RI.8.10"],
             24: ["C.8.3.c", "C.8.3.d", "C.8.3.e", "C.8.3.f", "C.8.7"],
 
             // Unit 7: Language and Vocabulary Development (Weeks 25-28)
             25: ["L.8.3.a", "L.8.4.a", "L.8.4.b", "RL.8.10", "C.8.7"],
             26: ["L.8.4.c", "L.8.4.d", "L.8.5.a", "RI.8.10", "C.8.7"],
             27: ["L.8.5.b", "L.8.5.c", "L.8.4.a", "RL.8.10", "C.8.7"],
             28: ["L.8.3.a", "L.8.4.b", "L.8.5.a", "RI.8.10", "C.8.7"],
 
             // Unit 8: Research and Inquiry (Weeks 29-32)
             29: ["C.8.5", "C.8.6", "RI.8.9", "RL.8.10", "C.8.7"],
             30: ["C.8.5", "C.8.6", "RI.8.9", "RI.8.10", "C.8.7"],
             31: ["C.8.5", "C.8.6", "RI.8.9", "RL.8.10", "C.8.7"],
             32: ["C.8.5", "C.8.6", "RI.8.9", "RI.8.10", "C.8.7"],
 
             // Unit 9: Comparing Texts and Media (Weeks 33-36)
             33: ["RL.8.7", "RL.8.9", "RI.8.7", "RL.8.10", "C.8.7"],
             34: ["RL.8.7", "RL.8.9", "RI.8.9", "RI.8.10", "C.8.7"],
             35: ["RL.8.7", "RI.8.7", "RI.8.9", "RL.8.10", "C.8.7"],
             36: ["RL.8.9", "RI.8.7", "RI.8.9", "RI.8.10", "C.8.7"]
         };
         */
        /*
        // KY -6th Grade OpenUp Math Pacing Guide
        const pacingGuideStandardsDict = {
            // Unit 1: Area And Surface Area (Weeks 1-4)
            1: ["KY.6.G.1", "KY.6.G.3", "KY.6.EE.2.c", "KY.6.NS.8"],
            2: ["KY.6.G.1", "KY.6.G.3", "KY.6.EE.2.c", "KY.6.NS.8"],
            3: ["KY.6.G.2", "KY.6.G.4", "KY.6.EE.2.c", "KY.6.NS.8"],
            4: ["KY.6.G.2", "KY.6.G.4", "KY.6.EE.2.c", "KY.6.NS.8"],

            // Unit 2: Introducing Ratios (Weeks 5-8)
            5: ["KY.6.RP.1", "KY.6.RP.2", "KY.6.RP.3.a", "KY.6.NS.4"],
            6: ["KY.6.RP.1", "KY.6.RP.2", "KY.6.RP.3.a", "KY.6.NS.4"],
            7: ["KY.6.RP.3.b", "KY.6.RP.3.c", "KY.6.EE.9.a", "KY.6.EE.9.b"],
            8: ["KY.6.RP.3.b", "KY.6.RP.3.c", "KY.6.EE.9.c", "KY.6.NS.4"],

            // Unit 3: Unit Rates And Percentages (Weeks 9-12)
            9: ["KY.6.RP.2", "KY.6.RP.3.b", "KY.6.RP.3.c", "KY.6.NS.3"],
            10: ["KY.6.RP.2", "KY.6.RP.3.b", "KY.6.RP.3.c", "KY.6.NS.3"],
            11: ["KY.6.RP.3.c", "KY.6.EE.9.a", "KY.6.EE.9.b", "KY.6.NS.3"],
            12: ["KY.6.RP.3.c", "KY.6.EE.9.c", "KY.6.NS.3", "KY.6.EE.2.c"],

            // Unit 4: Dividing Fractions (Weeks 13-16)
            13: ["KY.6.NS.1", "KY.6.NS.2.a", "KY.6.NS.2.b", "KY.6.EE.1"],
            14: ["KY.6.NS.1", "KY.6.NS.2.a", "KY.6.NS.2.b", "KY.6.EE.1"],
            15: ["KY.6.NS.1", "KY.6.NS.3", "KY.6.EE.1", "KY.6.EE.2.a"],
            16: ["KY.6.NS.1", "KY.6.NS.3", "KY.6.EE.1", "KY.6.EE.2.b"],

            // Unit 5: Arithmetic In Base Ten (Weeks 17-20)
            17: ["KY.6.NS.2.a", "KY.6.NS.2.b", "KY.6.NS.3", "KY.6.EE.2.c"],
            18: ["KY.6.NS.2.a", "KY.6.NS.2.b", "KY.6.NS.3", "KY.6.EE.2.c"],
            19: ["KY.6.NS.3", "KY.6.EE.2.a", "KY.6.EE.2.b", "KY.6.EE.2.c"],
            20: ["KY.6.NS.3", "KY.6.EE.2.a", "KY.6.EE.2.b", "KY.6.EE.2.c"],

            // Unit 6: Expressions And Equations (Weeks 21-24)
            21: ["KY.6.EE.3", "KY.6.EE.4", "KY.6.EE.5", "KY.6.EE.6"],
            22: ["KY.6.EE.3", "KY.6.EE.4", "KY.6.EE.5", "KY.6.EE.6"],
            23: ["KY.6.EE.7", "KY.6.EE.8", "KY.6.EE.9.a", "KY.6.EE.9.b"],
            24: ["KY.6.EE.7", "KY.6.EE.8", "KY.6.EE.9.c", "KY.6.EE.6"],

            // Unit 7: Rational Numbers (Weeks 25-28)
            25: ["KY.6.NS.5", "KY.6.NS.6.a", "KY.6.NS.6.b", "KY.6.NS.6.c"],
            26: ["KY.6.NS.5", "KY.6.NS.6.a", "KY.6.NS.6.b", "KY.6.NS.6.c"],
            27: ["KY.6.NS.7.a", "KY.6.NS.7.b", "KY.6.NS.7.c", "KY.6.NS.7.d"],
            28: ["KY.6.NS.7.a", "KY.6.NS.7.b", "KY.6.NS.7.c", "KY.6.NS.7.d"],

            // Unit 8: Data Sets And Distributions (Weeks 29-32)
            29: ["KY.6.SP.0.a", "KY.6.SP.0.b", "KY.6.SP.1", "KY.6.SP.2"],
            30: ["KY.6.SP.0.c", "KY.6.SP.0.d", "KY.6.SP.3", "KY.6.SP.4"],
            31: ["KY.6.SP.5.a", "KY.6.SP.5.b", "KY.6.SP.5.c", "KY.6.SP.5.d"],
            32: ["KY.6.SP.5.c", "KY.6.SP.5.d", "KY.6.SP.5.e", "KY.6.SP.4"],

            // Unit 9: Putting It ALL Together (Weeks 33-36)
            33: ["KY.6.RP.3.a", "KY.6.NS.8", "KY.6.EE.7", "KY.6.G.1"],
            34: ["KY.6.RP.3.b", "KY.6.NS.7.c", "KY.6.EE.8", "KY.6.G.2"],
            35: ["KY.6.RP.3.c", "KY.6.NS.3", "KY.6.EE.9.c", "KY.6.G.3"],
            36: ["KY.6.NS.1", "KY.6.EE.2.c", "KY.6.SP.5.e", "KY.6.G.4"]
        };
        */
        /*
         // KY - 7th Grade OpenUp Math Pacing Guide
         const pacingGuideStandardsDict = {
             // Unit 1: Scale Drawings (Weeks 1-4)
             1: ["KY.7.RP.1", "KY.7.G.1", "KY.7.RP.2.a", "KY.7.RP.2.b"],
             2: ["KY.7.RP.1", "KY.7.G.1", "KY.7.RP.2.c", "KY.7.RP.2.d"],
             3: ["KY.7.G.1", "KY.7.RP.2.a", "KY.7.RP.2.b", "KY.7.EE.3"],
             4: ["KY.7.G.1", "KY.7.RP.2.c", "KY.7.RP.2.d", "KY.7.EE.3"],
 
             // Unit 2: Introducing Proportional Relationships (Weeks 5-8)
             5: ["KY.7.RP.2.a", "KY.7.RP.2.b", "KY.7.RP.2.c", "KY.7.EE.2"],
             6: ["KY.7.RP.2.b", "KY.7.RP.2.c", "KY.7.RP.2.d", "KY.7.EE.2"],
             7: ["KY.7.RP.1", "KY.7.RP.2.d", "KY.7.EE.3", "KY.7.EE.4.a"],
             8: ["KY.7.RP.1", "KY.7.RP.2.a", "KY.7.EE.3", "KY.7.EE.4.b"],
 
             // Unit 3: Measuring Circles (Weeks 9-12)
             9: ["KY.7.G.4.a", "KY.7.G.4.b", "KY.7.EE.3", "KY.7.RP.2.c"],
             10: ["KY.7.G.4.a", "KY.7.G.4.b", "KY.7.EE.3", "KY.7.RP.2.d"],
             11: ["KY.7.G.4.a", "KY.7.G.4.b", "KY.7.EE.4.a", "KY.7.RP.1"],
             12: ["KY.7.G.4.a", "KY.7.G.4.b", "KY.7.EE.4.b", "KY.7.RP.1"],
 
             // Unit 4: Proportional Relationships And Percentages (Weeks 13-16)
             13: ["KY.7.RP.3.a", "KY.7.RP.3.b", "KY.7.EE.3", "KY.7.RP.2.c"],
             14: ["KY.7.RP.3.a", "KY.7.RP.3.b", "KY.7.EE.3", "KY.7.RP.2.d"],
             15: ["KY.7.RP.3.a", "KY.7.RP.3.b", "KY.7.EE.4.a", "KY.7.RP.2.a"],
             16: ["KY.7.RP.3.a", "KY.7.RP.3.b", "KY.7.EE.4.b", "KY.7.RP.2.b"],
 
             // Unit 5: Rational Number Arithmetic (Weeks 17-20)
             17: ["KY.7.NS.1.a", "KY.7.NS.1.b", "KY.7.NS.1.c", "KY.7.NS.1.d"],
             18: ["KY.7.NS.2.a", "KY.7.NS.2.b", "KY.7.NS.2.c", "KY.7.NS.3"],
             19: ["KY.7.NS.1.c", "KY.7.NS.1.d", "KY.7.NS.2.c", "KY.7.NS.3"],
             20: ["KY.7.NS.2.a", "KY.7.NS.2.b", "KY.7.NS.3", "KY.7.EE.3"],
 
             // Unit 6: Expressions, Equations, And Inequalities (Weeks 21-24)
             21: ["KY.7.EE.1", "KY.7.EE.2", "KY.7.EE.3", "KY.7.EE.4.a"],
             22: ["KY.7.EE.1", "KY.7.EE.2", "KY.7.EE.3", "KY.7.EE.4.b"],
             23: ["KY.7.EE.3", "KY.7.EE.4.a", "KY.7.NS.3", "KY.7.RP.2.c"],
             24: ["KY.7.EE.3", "KY.7.EE.4.b", "KY.7.NS.3", "KY.7.RP.2.d"],
 
             // Unit 7: Angles, Triangles, And Prisms (Weeks 25-28)
             25: ["KY.7.G.2", "KY.7.G.3", "KY.7.G.5", "KY.7.EE.4.a"],
             26: ["KY.7.G.2", "KY.7.G.3", "KY.7.G.5", "KY.7.EE.4.b"],
             27: ["KY.7.G.6.a", "KY.7.G.6.b", "KY.7.EE.3", "KY.7.NS.3"],
             28: ["KY.7.G.6.a", "KY.7.G.6.b", "KY.7.EE.3", "KY.7.NS.3"],
 
             // Unit 8: Probability And Sampling (Weeks 29-32)
             29: ["KY.7.SP.0", "KY.7.SP.1", "KY.7.SP.2.a", "KY.7.SP.2.b"],
             30: ["KY.7.SP.2.c", "KY.7.SP.3", "KY.7.SP.4", "KY.7.SP.5"],
             31: ["KY.7.SP.6", "KY.7.SP.7.a", "KY.7.SP.7.b", "KY.7.SP.8.a"],
             32: ["KY.7.SP.8.b", "KY.7.SP.8.c", "KY.7.SP.5", "KY.7.SP.6"],
 
             // Unit 9: Putting It ALL Together (Weeks 33-36)
             33: ["KY.7.RP.2.d", "KY.7.NS.3", "KY.7.EE.3", "KY.7.G.1"],
             34: ["KY.7.RP.3.b", "KY.7.EE.4.a", "KY.7.G.4.a", "KY.7.SP.4"],
             35: ["KY.7.NS.2.c", "KY.7.EE.4.b", "KY.7.G.6.b", "KY.7.SP.7.b"],
             36: ["KY.7.RP.1", "KY.7.EE.3", "KY.7.G.5", "KY.7.SP.8.c"]
         };
         */
        /*
        // KY - 8th Grade OpenUp Math Pacing Guide
        const pacingGuideStandardsDict = {
            // Unit 1: Rigid Transformations And Congruence (Weeks 1-4)
            1: ["KY.8.G.1", "KY.8.G.2", "KY.8.G.3", "KY.8.EE.6"],
            2: ["KY.8.G.1", "KY.8.G.2", "KY.8.G.3", "KY.8.EE.6"],
            3: ["KY.8.G.1", "KY.8.G.2", "KY.8.G.3", "KY.8.EE.6"],
            4: ["KY.8.G.1", "KY.8.G.2", "KY.8.G.3", "KY.8.EE.6"],

            // Unit 2: Dilations, Similarity, And Introducing Slope (Weeks 5-8)
            5: ["KY.8.G.3", "KY.8.G.4", "KY.8.EE.5", "KY.8.EE.6"],
            6: ["KY.8.G.3", "KY.8.G.4", "KY.8.EE.5", "KY.8.EE.6"],
            7: ["KY.8.G.3", "KY.8.G.4", "KY.8.EE.5", "KY.8.EE.6"],
            8: ["KY.8.G.3", "KY.8.G.4", "KY.8.EE.5", "KY.8.EE.6"],

            // Unit 3: Linear Relationships (Weeks 9-12)
            9: ["KY.8.EE.5", "KY.8.EE.6", "KY.8.F.1", "KY.8.F.2"],
            10: ["KY.8.EE.5", "KY.8.EE.6", "KY.8.F.3.a", "KY.8.F.3.b"],
            11: ["KY.8.F.4.a", "KY.8.F.4.b", "KY.8.F.5.a", "KY.8.F.5.b"],
            12: ["KY.8.F.1", "KY.8.F.2", "KY.8.F.3.a", "KY.8.F.3.b"],

            // Unit 4: Linear Equations And Linear Systems (Weeks 13-16)
            13: ["KY.8.EE.7.a", "KY.8.EE.7.b", "KY.8.EE.8.a", "KY.8.EE.8.b"],
            14: ["KY.8.EE.7.a", "KY.8.EE.7.b", "KY.8.EE.8.a", "KY.8.EE.8.b"],
            15: ["KY.8.EE.7.a", "KY.8.EE.7.b", "KY.8.EE.8.c", "KY.8.F.4.a"],
            16: ["KY.8.EE.8.a", "KY.8.EE.8.b", "KY.8.EE.8.c", "KY.8.F.4.b"],

            // Unit 5: Functions And Volume (Weeks 17-20)
            17: ["KY.8.F.1", "KY.8.F.2", "KY.8.F.3.a", "KY.8.F.3.b"],
            18: ["KY.8.F.4.a", "KY.8.F.4.b", "KY.8.F.5.a", "KY.8.F.5.b"],
            19: ["KY.8.G.9", "KY.8.F.1", "KY.8.F.2", "KY.8.F.3.a"],
            20: ["KY.8.G.9", "KY.8.F.4.a", "KY.8.F.4.b", "KY.8.F.5.b"],

            // Unit 6: Associations In Data (Weeks 21-24)
            21: ["KY.8.SP.1", "KY.8.SP.2", "KY.8.SP.3", "KY.8.F.3.a"],
            22: ["KY.8.SP.1", "KY.8.SP.2", "KY.8.SP.3", "KY.8.F.3.b"],
            23: ["KY.8.SP.1", "KY.8.SP.2", "KY.8.SP.3", "KY.8.F.4.a"],
            24: ["KY.8.SP.1", "KY.8.SP.2", "KY.8.SP.3", "KY.8.F.4.b"],

            // Unit 7: Exponents And Scientific Notation (Weeks 25-28)
            25: ["KY.8.EE.1", "KY.8.EE.2", "KY.8.EE.3", "KY.8.EE.4"],
            26: ["KY.8.EE.1", "KY.8.EE.2", "KY.8.EE.3", "KY.8.EE.4"],
            27: ["KY.8.EE.1", "KY.8.EE.2", "KY.8.EE.3", "KY.8.EE.4"],
            28: ["KY.8.EE.1", "KY.8.EE.2", "KY.8.EE.3", "KY.8.EE.4"],

            // Unit 8: Pythagorean Theorem And Irrational Numbers (Weeks 29-32)
            29: ["KY.8.G.6", "KY.8.G.7", "KY.8.G.8", "KY.8.NS.1"],
            30: ["KY.8.G.6", "KY.8.G.7", "KY.8.G.8", "KY.8.NS.2"],
            31: ["KY.8.G.6", "KY.8.G.7", "KY.8.G.8", "KY.8.NS.1"],
            32: ["KY.8.G.6", "KY.8.G.7", "KY.8.G.8", "KY.8.NS.2"],

            // Unit 9: Putting It ALL Together (Weeks 33-36)
            33: ["KY.8.EE.5", "KY.8.F.1", "KY.8.G.5", "KY.8.SP.3"],
            34: ["KY.8.EE.7.b", "KY.8.F.3.a", "KY.8.G.7", "KY.8.SP.2"],
            35: ["KY.8.EE.8.c", "KY.8.F.4.b", "KY.8.G.9", "KY.8.NS.2"],
            36: ["KY.8.EE.4", "KY.8.F.5.b", "KY.8.G.8", "KY.8.SP.1"]
        };
        */
        /*
         // KY - 6th Grade Default Social Studies Pacing Guide
         const pacingGuideStandardsDict = {
             // Unit 1: Introduction to Ancient Civilizations (Weeks 1-4)
             1: ["6.I.Q.1", "6.I.Q.2", "6.I.Q.3", "6.H.CH.1"],
             2: ["6.G.MM.1", "6.G.GR.1", "6.I.UE.1", "6.I.UE.2"],
             3: ["6.H.CE.1", "6.G.HE.1", "6.I.UE.3", "6.I.CC.1"],
             4: ["6.H.CO.2", "6.G.HI.1", "6.I.CC.2", "6.I.CC.3"],
 
             // Unit 2: River Valley Civilizations (Weeks 5-8)
             5: ["6.C.CP.1", "6.C.CP.2", "6.E.MA.1", "6.G.HE.1"],
             6: ["6.E.MI.3", "6.E.MI.4", "6.G.HI.1", "6.H.CH.1"],
             7: ["6.C.CV.1", "6.E.ST.1", "6.G.HE.2", "6.H.CE.1"],
             8: ["6.C.PR.1", "6.E.IC.1", "6.G.HI.2", "6.I.CC.5"],
 
             // Unit 3: Classical Empires: Greece and Rome (Weeks 9-12)
             9: ["6.C.CP.3", "6.C.RR.1", "6.H.KH.1", "6.G.MM.1"],
             10: ["6.C.CV.1", "6.E.MI.4", "6.H.CO.1", "6.G.HI.2"],
             11: ["6.C.PR.1", "6.E.ST.2", "6.H.CH.2", "6.G.HE.2"],
             12: ["6.C.RR.1", "6.E.IC.1", "6.H.CO.2", "6.I.CC.4"],
 
             // Unit 4: Ancient Economies and Trade (Weeks 13-16)
             13: ["6.E.MI.1", "6.E.MI.2", "6.E.MA.1", "6.E.MA.2"],
             14: ["6.E.MI.3", "6.E.MI.4", "6.E.ST.1", "6.E.ST.2"],
             15: ["6.E.IC.1", "6.H.CO.2", "6.G.HI.2", "6.I.UE.1"],
             16: ["6.E.MA.2", "6.H.CE.1", "6.G.MM.1", "6.I.CC.2"],
 
             // Unit 5: Geography and Ancient Civilizations (Weeks 17-20)
             17: ["6.G.MM.1", "6.G.HI.1", "6.G.HE.1", "6.G.GR.1"],
             18: ["6.G.HI.2", "6.G.HE.2", "6.H.CH.1", "6.I.UE.2"],
             19: ["6.G.GR.1", "6.H.CE.1", "6.E.MA.2", "6.I.UE.3"],
             20: ["6.G.HE.1", "6.G.HE.2", "6.H.CO.1", "6.I.CC.3"],
 
             // Unit 6: Ancient Religions and Cultures (Weeks 21-24)
             21: ["6.H.CH.2", "6.C.CP.2", "6.G.HI.2", "6.I.Q.1"],
             22: ["6.H.CO.1", "6.C.CV.1", "6.G.MM.1", "6.I.Q.2"],
             23: ["6.H.CH.2", "6.C.RR.1", "6.G.HE.2", "6.I.UE.1"],
             24: ["6.H.CO.2", "6.C.PR.1", "6.G.HI.1", "6.I.CC.5"],
 
             // Unit 7: Government and Citizenship in Ancient Times (Weeks 25-28)
             25: ["6.C.CP.1", "6.C.CP.2", "6.C.CP.3", "6.C.RR.1"],
             26: ["6.C.CV.1", "6.C.PR.1", "6.H.KH.1", "6.I.Q.3"],
             27: ["6.C.RR.1", "6.H.CO.1", "6.E.IC.1", "6.I.UE.2"],
             28: ["6.C.CP.3", "6.H.CH.1", "6.E.ST.2", "6.I.CC.4"],
 
             // Unit 8: Social Studies Skills and Methods (Weeks 29-32)
             29: ["6.I.Q.1", "6.I.Q.2", "6.I.Q.3", "6.I.UE.1"],
             30: ["6.I.UE.2", "6.I.UE.3", "6.I.CC.1", "6.I.CC.2"],
             31: ["6.I.CC.3", "6.I.CC.4", "6.I.CC.5", "6.G.GR.1"],
             32: ["6.H.CE.1", "6.E.MI.2", "6.C.CV.1", "6.I.CC.5"],
 
             // Unit 9: Ancient Civilizations' Legacy and Review (Weeks 33-36)
             33: ["6.H.KH.1", "6.C.CP.3", "6.E.MA.2", "6.G.HI.2"],
             34: ["6.H.CH.2", "6.C.RR.1", "6.E.ST.2", "6.G.HE.2"],
             35: ["6.H.CO.2", "6.C.PR.1", "6.E.IC.1", "6.I.CC.3"],
             36: ["6.H.CE.1", "6.C.CV.1", "6.E.MI.4", "6.I.CC.4"]
         };
         */
        /*
        // KY - 7th Grade Default Social Studies Pacing Guide
        const pacingGuideStandardsDict = {
            // Unit 1: Introduction to World History (600-1600 CE) (Weeks 1-4)
            1: ["7.I.Q.1", "7.I.Q.2", "7.I.Q.3", "7.H.CH.1"],
            2: ["7.G.MM.1", "7.G.GR.1", "7.I.UE.1", "7.I.UE.2"],
            3: ["7.H.CE.2", "7.G.HE.1", "7.I.UE.3", "7.I.CC.1"],
            4: ["7.H.CO.2", "7.G.HI.1", "7.I.CC.2", "7.I.CC.3"],

            // Unit 2: Empires and Political Systems (Weeks 5-8)
            5: ["7.C.CP.1", "7.C.RR.1", "7.C.CV.1", "7.C.PR.1"],
            6: ["7.H.CE.2", "7.G.HI.1", "7.E.IC.2", "7.I.CC.5"],
            7: ["7.C.CP.1", "7.C.RR.1", "7.G.HI.2", "7.I.UE.1"],
            8: ["7.C.CV.1", "7.C.PR.1", "7.H.CO.2", "7.I.CC.4"],

            // Unit 3: Economic Systems and Trade (Weeks 9-12)
            9: ["7.E.MI.1", "7.E.MI.2", "7.E.MI.3", "7.E.MA.1"],
            10: ["7.E.MA.2", "7.E.ST.1", "7.E.ST.2", "7.E.ST.3"],
            11: ["7.E.ST.4", "7.E.IC.1", "7.E.IC.2", "7.G.HI.2"],
            12: ["7.E.MA.1", "7.E.ST.3", "7.H.CE.2", "7.I.CC.2"],

            // Unit 4: Geographic Influences on Civilizations (Weeks 13-16)
            13: ["7.G.MM.1", "7.G.HI.1", "7.G.HE.1", "7.G.GR.1"],
            14: ["7.G.HI.2", "7.G.HE.2", "7.G.GR.2", "7.I.UE.2"],
            15: ["7.G.GR.1", "7.G.GR.2", "7.E.ST.4", "7.I.UE.3"],
            16: ["7.G.HE.1", "7.G.HE.2", "7.H.CO.1", "7.I.CC.3"],

            // Unit 5: Cultural Interactions and Diffusion (Weeks 17-20)
            17: ["7.H.CH.1", "7.G.HI.2", "7.C.RR.1", "7.I.Q.1"],
            18: ["7.H.CO.1", "7.G.MM.1", "7.E.ST.3", "7.I.Q.2"],
            19: ["7.H.CE.2", "7.G.HI.1", "7.E.ST.4", "7.I.UE.1"],
            20: ["7.H.CO.2", "7.G.HI.2", "7.E.IC.2", "7.I.CC.5"],

            // Unit 6: The Renaissance and Scientific Revolution (Weeks 21-24)
            21: ["7.H.CE.1", "7.C.CV.1", "7.E.ST.2", "7.I.Q.3"],
            22: ["7.H.CE.1", "7.C.RR.1", "7.E.ST.3", "7.I.UE.2"],
            23: ["7.H.CE.1", "7.G.HI.2", "7.E.IC.1", "7.I.CC.1"],
            24: ["7.H.CE.1", "7.G.GR.2", "7.E.IC.2", "7.I.CC.4"],

            // Unit 7: Religion and State-Building (Weeks 25-28)
            25: ["7.H.CO.1", "7.C.CP.1", "7.G.HI.1", "7.I.UE.1"],
            26: ["7.H.CO.1", "7.C.CV.1", "7.G.HI.2", "7.I.UE.2"],
            27: ["7.H.CO.1", "7.C.PR.1", "7.E.MA.2", "7.I.CC.2"],
            28: ["7.H.CO.1", "7.C.RR.1", "7.E.IC.1", "7.I.CC.3"],

            // Unit 8: Expansion and Exploration (Weeks 29-32)
            29: ["7.H.CH.1", "7.G.MM.1", "7.E.ST.4", "7.I.Q.1"],
            30: ["7.H.CO.2", "7.G.HE.2", "7.E.ST.1", "7.I.Q.2"],
            31: ["7.H.CE.2", "7.G.GR.1", "7.E.MA.1", "7.I.UE.3"],
            32: ["7.H.CO.2", "7.G.HI.2", "7.E.IC.2", "7.I.CC.5"],

            // Unit 9: Social Studies Skills and Methods (Weeks 33-36)
            33: ["7.I.Q.1", "7.I.Q.2", "7.I.Q.3", "7.I.UE.1"],
            34: ["7.I.UE.2", "7.I.UE.3", "7.I.CC.1", "7.I.CC.2"],
            35: ["7.I.CC.3", "7.I.CC.4", "7.I.CC.5", "7.G.GR.2"],
            36: ["7.H.CE.2", "7.E.MI.3", "7.C.CV.1", "7.I.CC.5"]
        };
        */
        /*
         // KY - 8th Grade Default Social Studies Pacing Guide
         const pacingGuideStandardsDict = {
             // Unit 1: Foundations of American Government (Weeks 1-4)
             1: ["8.I.Q.1", "8.I.Q.2", "8.I.Q.3", "8.C.CP.1"],
             2: ["8.C.CP.2", "8.C.CP.3", "8.C.PR.1", "8.I.UE.1"],
             3: ["8.H.CH.1", "8.H.CO.1", "8.G.MM.1", "8.I.UE.2"],
             4: ["8.E.MA.1", "8.G.HI.1", "8.H.KH.1", "8.I.CC.1"],
 
             // Unit 2: American Revolution and Early Republic (Weeks 5-8)
             5: ["8.H.CO.1", "8.H.CE.1", "8.C.RR.1", "8.E.IC.1"],
             6: ["8.H.CO.2", "8.C.CV.1", "8.G.HE.1", "8.I.UE.3"],
             7: ["8.E.MA.2", "8.G.GR.1", "8.H.KH.1", "8.I.CC.2"],
             8: ["8.C.KGO.1", "8.E.KE.1", "8.G.KGE.1", "8.I.CC.3"],
 
             // Unit 3: The U.S. Constitution and Citizenship (Weeks 9-12)
             9: ["8.C.CP.1", "8.C.CP.2", "8.C.CP.3", "8.C.RR.1"],
             10: ["8.C.RR.2", "8.C.RR.3", "8.C.CV.1", "8.C.PR.2"],
             11: ["8.H.CO.2", "8.E.MA.1", "8.E.MA.4", "8.I.UE.1"],
             12: ["8.C.KGO.1", "8.H.CE.1", "8.I.CC.4", "8.I.CC.5"],
 
             // Unit 4: Westward Expansion and Regional Differences (Weeks 13-16)
             13: ["8.G.MM.1", "8.G.HI.1", "8.G.HE.1", "8.G.GR.1"],
             14: ["8.E.ST.1", "8.E.IC.2", "8.H.CH.2", "8.I.UE.2"],
             15: ["8.H.CE.2", "8.E.MA.2", "8.G.KGE.1", "8.I.CC.1"],
             16: ["8.H.KH.2", "8.E.KE.2", "8.H.CH.3", "8.I.CC.3"],
 
             // Unit 5: Economic Development in the United States (Weeks 17-20)
             17: ["8.E.MI.1", "8.E.MI.2", "8.E.MA.1", "8.E.MA.2"],
             18: ["8.E.MA.3", "8.E.MA.4", "8.E.ST.1", "8.E.IC.1"],
             19: ["8.E.IC.2", "8.E.KE.1", "8.E.KE.2", "8.H.CH.4"],
             20: ["8.H.CE.1", "8.G.HI.1", "8.G.HE.1", "8.I.CC.2"],
 
             // Unit 6: Reform Movements and Social Change (Weeks 21-24)
             21: ["8.H.CH.2", "8.H.CO.3", "8.C.RR.2", "8.C.RR.3"],
             22: ["8.H.CE.2", "8.E.IC.2", "8.G.HI.1", "8.I.UE.3"],
             23: ["8.C.CV.1", "8.H.KH.1", "8.G.MM.1", "8.I.CC.3"],
             24: ["8.H.CH.3", "8.H.CH.4", "8.C.PR.2", "8.I.CC.4"],
 
             // Unit 7: Sectionalism and the Civil War (Weeks 25-28)
             25: ["8.H.CH.3", "8.H.CO.3", "8.H.CO.4", "8.E.ST.1"],
             26: ["8.H.CE.2", "8.G.HI.1", "8.G.HE.1", "8.I.UE.1"],
             27: ["8.C.KGO.1", "8.E.KE.1", "8.G.KGE.1", "8.I.CC.2"],
             28: ["8.H.KH.1", "8.H.KH.2", "8.G.MM.1", "8.I.CC.5"],
 
             // Unit 8: Reconstruction and Its Aftermath (Weeks 29-32)
             29: ["8.H.CH.5", "8.H.CE.2", "8.C.RR.2", "8.C.RR.3"],
             30: ["8.E.IC.2", "8.G.HI.1", "8.G.HE.1", "8.I.UE.2"],
             31: ["8.C.PR.2", "8.H.KH.1", "8.G.GR.1", "8.I.CC.1"],
             32: ["8.C.CV.1", "8.E.MA.4", "8.H.CO.2", "8.I.CC.3"],
 
             // Unit 9: Kentucky History and Geography (Weeks 33-36)
             33: ["8.H.KH.1", "8.H.KH.2", "8.G.KGE.1", "8.I.Q.1"],
             34: ["8.C.KGO.1", "8.E.KE.1", "8.E.KE.2", "8.I.Q.2"],
             35: ["8.G.MM.1", "8.G.HI.1", "8.G.HE.1", "8.I.UE.3"],
             36: ["8.H.CE.2", "8.E.ST.1", "8.G.GR.1", "8.I.CC.5"]
         };
         */

        /*
        // KY 6th Grade Math Coach Pacing Guide
        const pacingGuideStandardsDict = {
            // Unit 1: Area and Surface Area (4 weeks)
            1: ["KY.6.G.1", "KY.6.EE.2.a", "KY.6.EE.2.c"],
            2: ["KY.6.G.1", "KY.6.EE.2.a", "KY.6.EE.2.c", "KY.6.G.4"],
            3: ["KY.6.G.1", "KY.6.G.4", "KY.6.G.2"],
            4: ["KY.6.G.1", "KY.6.G.4", "KY.6.EE.1", "KY.6.EE.2.a"],

            // Unit 2: Introducing Ratios (4 weeks)
            5: ["KY.6.RP.1"],
            6: ["KY.6.RP.1", "KY.6.RP.3.a"],
            7: ["KY.6.RP.3.a", "KY.6.RP.3.b"],
            8: ["KY.6.RP.2", "KY.6.RP.3.a", "KY.6.RP.3.b"],

            // Unit 3: Unit Rate and Percentages (4 weeks)
            9: ["KY.6.RP.2", "KY.6.RP.3.b"],
            10: ["KY.6.RP.2", "KY.6.RP.3.b"],
            11: ["KY.6.RP.3.c"],
            12: ["KY.6.RP.3.c"],

            // Unit 4: Dividing Fractions (4 weeks)
            13: ["KY.6.NS.1"],
            14: ["KY.6.NS.1", "KY.6.G.1", "KY.6.G.2"],
            15: ["KY.6.NS.1", "KY.6.G.2"],
            16: ["KY.6.NS.1"],

            // Unit 5: Arithmetic in Base Ten (4 weeks)
            17: ["KY.6.NS.3"],
            18: ["KY.6.NS.2.a", "KY.6.NS.2.b", "KY.6.NS.3"],
            19: ["KY.6.NS.2.a", "KY.6.NS.2.b", "KY.6.NS.3"],
            20: ["KY.6.EE.4", "KY.6.NS.3"],

            // Unit 6: Expressions and Equations (4 weeks)
            21: ["KY.6.EE.5", "KY.6.EE.6", "KY.6.EE.7"],
            22: ["KY.6.EE.2.a", "KY.6.EE.2.c", "KY.6.EE.6", "KY.6.EE.4"],
            23: ["KY.6.EE.1", "KY.6.EE.2.b", "KY.6.EE.3"],
            24: ["KY.6.EE.1", "KY.6.EE.2.c", "KY.6.EE.5", "KY.6.EE.9.a", "KY.6.EE.9.b", "KY.6.EE.9.c"],

            // Unit 7: Rational Numbers (4 weeks)
            25: ["KY.6.NS.5", "KY.6.NS.6.a", "KY.6.NS.7.a"],
            26: ["KY.6.NS.7.b", "KY.6.NS.5"],
            27: ["KY.6.NS.7.c", "KY.6.NS.7.d", "KY.6.NS.6.a", "KY.6.EE.8"],
            28: ["KY.6.NS.6.b", "KY.6.NS.6.c", "KY.6.NS.8", "KY.6.G.3", "KY.6.NS.4"],

            // Unit 8: Data Sets and Distributions (4 weeks)
            29: ["KY.6.SP.0.a", "KY.6.SP.0.b", "KY.6.SP.1", "KY.6.SP.5.b"],
            30: ["KY.6.SP.2", "KY.6.SP.0.c", "KY.6.SP.4"],
            31: ["KY.6.SP.3", "KY.6.SP.0.d", "KY.6.SP.5.c"],
            32: ["KY.6.SP.5.b", "KY.6.SP.5.c", "KY.6.SP.5.d", "KY.6.SP.1", "KY.6.SP.4", "KY.6.SP.5.a", "KY.6.SP.5.e"],

            // Unit 9: Putting It All Together (4 weeks)
            33: ["KY.6.G.1", "KY.6.NS.2.a", "KY.6.RP.1"],
            34: ["KY.6.NS.2.b", "KY.6.RP.1", "KY.6.NS.1"],
            35: ["KY.6.RP.1", "KY.6.RP.3.a", "KY.6.RP.3.c"],
            36: ["KY.6.NS.3", "KY.6.RP.2", "KY.6.RP.3.a", "KY.6.RP.3.c"]
        };*/
        /*
        // KY 7th Grade Math Coach Pacing Guide
        const pacingGuideStandardsDict = {
            // Unit 1: Scale Drawings (4 weeks)
            1: ["KY.7.G.1"],
            2: ["KY.7.G.1"],
            3: ["KY.7.G.1", "KY.7.G.6.a", "KY.7.G.6.b"],
            4: ["KY.7.G.1", "KY.7.EE.1", "KY.7.EE.2"],

            // Unit 2: Introducing Proportional Relationships (4 weeks)
            5: ["KY.7.G.1", "KY.7.RP.2.a", "KY.7.RP.2.b"],
            6: ["KY.7.RP.2.a", "KY.7.RP.2.b", "KY.7.RP.2.c"],
            7: ["KY.7.RP.2.a", "KY.7.RP.2.b", "KY.7.RP.2.c", "KY.7.RP.2.d"],
            8: ["KY.7.G.6.a", "KY.7.G.6.b", "KY.7.RP.1", "KY.7.RP.2.a", "KY.7.RP.2.b", "KY.7.RP.2.c", "KY.7.RP.2.d"],

            // Unit 3: Measuring Circles (4 weeks)
            9: ["KY.7.RP.2.a", "KY.7.G.2"],
            10: ["KY.7.G.4.a", "KY.7.G.4.b", "KY.7.RP.2.a"],
            11: ["KY.7.G.4.a", "KY.7.G.4.b", "KY.7.RP.2.a", "KY.7.RP.2.c", "KY.7.RP.3.a", "KY.7.RP.3.b"],
            12: ["KY.7.G.1", "KY.7.G.6.a", "KY.7.G.6.b", "KY.7.EE.3", "KY.7.G.4.a", "KY.7.G.4.b"],

            // Unit 4: Proportional Relationships and Percentages (4 weeks)
            13: ["KY.7.RP.1", "KY.7.RP.2.a", "KY.7.RP.2.b", "KY.7.RP.2.c", "KY.7.RP.2.d"],
            14: ["KY.7.RP.2.a", "KY.7.RP.2.b", "KY.7.RP.2.c", "KY.7.RP.2.d"],
            15: ["KY.7.RP.3.a", "KY.7.RP.3.b"],
            16: ["KY.7.RP.3.a", "KY.7.RP.3.b"],

            // Unit 5: Rational Number Arithmetic (4 weeks)
            17: ["KY.7.NS.1.a", "KY.7.NS.1.b", "KY.7.NS.1.c", "KY.7.NS.1.d"],
            18: ["KY.7.NS.2.a", "KY.7.NS.2.b", "KY.7.NS.2.c", "KY.7.RP.2.a", "KY.7.RP.2.b", "KY.7.RP.2.c", "KY.7.RP.2.d"],
            19: ["KY.7.NS.2.a", "KY.7.NS.2.b", "KY.7.NS.2.c"],
            20: ["KY.7.EE.3", "KY.7.NS.3", "KY.7.EE.4.a"],

            // Unit 6: Expressions, Equations, and Inequalities (4 weeks)
            21: ["KY.7.EE.3", "KY.7.EE.4.a"],
            22: ["KY.7.EE.4.a"],
            23: ["KY.7.EE.4.b"],
            24: ["KY.7.EE.1", "KY.7.NS.1.a", "KY.7.NS.1.b", "KY.7.NS.1.c", "KY.7.NS.1.d"],

            // Unit 7: Angles, Triangles, and Prisms (4 weeks)
            25: ["KY.7.G.5"],
            26: ["KY.7.G.2", "KY.7.NS.1.a", "KY.7.NS.1.b", "KY.7.NS.1.c", "KY.7.NS.1.d"],
            27: ["KY.7.G.2", "KY.7.G.3"],
            28: ["KY.7.G.6.a", "KY.7.G.6.b", "KY.7.RP.1"],

            // Unit 8: Probability and Sampling (4 weeks)
            29: ["KY.7.SP.5", "KY.7.SP.6", "KY.7.SP.7.a"],
            30: ["KY.7.SP.7.b", "KY.7.SP.8.a", "KY.7.SP.8.b", "KY.7.SP.8.c"],
            31: ["KY.7.SP.1", "KY.7.SP.2.a", "KY.7.SP.2.b", "KY.7.SP.2.c"],
            32: ["KY.7.SP.3", "KY.7.SP.4", "KY.7.SP.0"],

            // Unit 9: Putting It All Together (4 weeks)
            33: ["KY.7.RP.3.a", "KY.7.RP.3.b"],
            34: ["KY.7.EE.4.a", "KY.7.NS.3", "KY.7.RP.2.a", "KY.7.RP.2.b", "KY.7.RP.2.c", "KY.7.RP.2.d", "KY.7.RP.3.a", "KY.7.RP.3.b", "KY.7.SP.4"],
            35: ["KY.7.G.6.a", "KY.7.G.6.b", "KY.7.RP.1", "KY.7.RP.2.a", "KY.7.RP.2.b", "KY.7.RP.2.c", "KY.7.RP.2.d"],
            36: ["KY.7.G.4.a", "KY.7.G.4.b", "KY.7.RP.1"]
        };
        */
        /*
         // KY 8th Grade Math Coach Pacing Guide
         const pacingGuideStandardsDict = {
             // Unit 1: Rigid Transformations and Congruence (4 weeks)
             1: ["KY.8.G.1"],
             2: ["KY.8.G.1", "KY.8.G.3"],
             3: ["KY.8.G.1", "KY.8.G.2"],
             4: ["KY.8.G.1", "KY.8.G.2", "KY.8.G.5"],
 
             // Unit 2: Dilations, Similarity, and Introducing Slope (3 weeks)
             5: ["KY.8.G.3", "KY.8.G.4"],
             6: ["KY.8.G.2", "KY.8.G.4", "KY.8.G.5"],
             7: ["KY.8.EE.6"],
 
             // Unit 3: Linear Relationships (3 weeks)
             8: ["KY.8.EE.5", "KY.8.EE.6"],
             9: ["KY.8.EE.5", "KY.8.EE.6"],
             10: ["KY.8.EE.6", "KY.8.EE.8.a"],
 
             // Unit 4: Linear Equations and Linear Systems (4 weeks)
             11: ["KY.8.EE.7.a", "KY.8.EE.7.b"],
             12: ["KY.8.EE.7.a", "KY.8.EE.7.b"],
             13: ["KY.8.EE.8.a", "KY.8.EE.8.b"],
             14: ["KY.8.EE.8.b", "KY.8.EE.8.c"],
 
             // Unit 5: Functions and Volume (6 weeks)
             15: ["KY.8.F.1", "KY.8.F.2"],
             16: ["KY.8.F.3.a", "KY.8.F.3.b"],
             17: ["KY.8.F.4.a", "KY.8.F.4.b"],
             18: ["KY.8.F.5.a", "KY.8.F.5.b"],
             19: ["KY.8.G.9"],
             20: ["KY.8.G.9"],
 
             // Unit 6: Associations in Data (3 weeks)
             21: ["KY.8.SP.1", "KY.8.SP.2"],
             22: ["KY.8.SP.3"],
             23: ["KY.8.SP.1", "KY.8.SP.2", "KY.8.SP.3"],
 
             // Unit 7: Exponents and Scientific Notation (4 weeks)
             24: ["KY.8.EE.1"],
             25: ["KY.8.EE.1"],
             26: ["KY.8.EE.3", "KY.8.EE.4"],
             27: ["KY.8.EE.3", "KY.8.EE.4"],
 
             // Unit 8: Pythagorean Theorem and Irrational Numbers (4 weeks)
             28: ["KY.8.NS.1", "KY.8.NS.2"],
             29: ["KY.8.EE.2", "KY.8.NS.2"],
             30: ["KY.8.G.6", "KY.8.G.7"],
             31: ["KY.8.G.7", "KY.8.G.8"],
 
             // Unit 9: Putting It All Together (5 weeks)
             32: ["KY.8.G.1", "KY.8.G.2", "KY.8.G.3", "KY.8.G.4", "KY.8.G.5"],
             33: ["KY.8.F.1", "KY.8.F.2", "KY.8.F.3.a", "KY.8.F.3.b", "KY.8.F.4.a", "KY.8.F.4.b", "KY.8.F.5.a", "KY.8.F.5.b"],
             34: ["KY.8.SP.1", "KY.8.SP.2", "KY.8.SP.3"],
             35: ["KY.8.EE.1", "KY.8.EE.2", "KY.8.EE.3", "KY.8.EE.4"],
             36: ["KY.8.NS.1", "KY.8.NS.2", "KY.8.G.6", "KY.8.G.7", "KY.8.G.8", "KY.8.G.9"]
         };
         */
        /*
        // KY 6th Grade ELA CommonLit Pacing Guide
        const pacingGuideStandardsDict = {
            // Unit 1: Characters Who Change and Grow (Weeks 1-6)
            1: ["RL.6.1", "RL.6.2", "RL.6.3", "RI.6.1"],
            2: ["RL.6.4", "RL.6.5", "RI.6.2", "C.6.3.a"],
            3: ["RL.6.6", "RI.6.3", "C.6.3.b", "C.6.3.c"],
            4: ["RL.6.10", "RI.6.10", "C.6.3.d", "C.6.3.e"],
            5: ["RL.6.1", "C.6.3.f", "C.6.3.g", "L.6.1.a"],
            6: ["RI.6.1", "L.6.1.c", "L.6.2.a", "L.6.2.b"],

            // Unit 2: The Recipe for Success (Weeks 7-12)
            7: ["RI.6.1", "RI.6.2", "RI.6.3", "RI.6.4"],
            8: ["RI.6.5", "RI.6.6", "RI.6.7", "RI.6.8"],
            9: ["RI.6.9", "C.6.2.a", "C.6.2.b", "C.6.2.c"],
            10: ["RI.6.1", "C.6.2.d", "C.6.2.e", "C.6.2.f"],
            11: ["RI.6.2", "C.6.2.h", "C.6.4", "C.6.5"],
            12: ["RI.6.3", "L.6.3.a", "L.6.3.b", "L.6.4.b"],

            // Unit 3: The Giver By Lois Lowry (Weeks 13-18)
            13: ["RL.6.1", "RL.6.2", "RL.6.3", "RL.6.4"],
            14: ["RL.6.5", "RL.6.6", "RL.6.7", "RL.6.9"],
            15: ["RL.6.10", "C.6.1.a", "C.6.1.b", "C.6.1.c"],
            16: ["RL.6.1", "C.6.1.d", "C.6.1.e", "C.6.1.f"],
            17: ["RI.6.1", "RI.6.2", "RI.6.3", "RI.6.4"],
            18: ["RI.6.5", "RI.6.7", "RI.6.10", "C.6.7"],

            // Unit 4: Our Changing Oceans (Weeks 19-24)
            19: ["RI.6.1", "RI.6.2", "RI.6.3", "RI.6.4"],
            20: ["RI.6.5", "RI.6.6", "RI.6.7", "RI.6.8"],
            21: ["RI.6.9", "RI.6.10", "C.6.2.a", "C.6.2.b"],
            22: ["RI.6.1", "C.6.2.c", "C.6.2.d", "C.6.2.e"],
            23: ["RI.6.2", "C.6.2.g", "C.6.2.h", "C.6.4"],
            24: ["RI.6.3", "C.6.6", "L.6.4.d", "L.6.5.a"],

            // Unit 5: The Forces that Shape Us (Weeks 25-30)
            25: ["RL.6.1", "RL.6.2", "RL.6.3", "RL.6.4"],
            26: ["RL.6.5", "RL.6.6", "RL.6.10", "RI.6.1"],
            27: ["RI.6.3", "RI.6.4", "RI.6.6", "RI.6.7"],
            28: ["RI.6.10", "C.6.1.a", "C.6.1.b", "C.6.1.c"],
            29: ["RL.6.1", "C.6.1.d", "C.6.1.e", "C.6.1.f"],
            30: ["RI.6.3", "C.6.7", "L.6.5.c", "L.6.4.a"],

            // Unit 6: The Power of Play (Weeks 31-36)
            31: ["RI.6.1", "RI.6.2", "RI.6.3", "RI.6.4"],
            32: ["RI.6.5", "RI.6.6", "RI.6.7", "RI.6.10"],
            33: ["RI.6.1", "C.6.1.a", "C.6.1.b", "C.6.1.c"],
            34: ["RI.6.2", "C.6.1.e", "C.6.1.f", "C.6.1.g"],
            35: ["RI.6.3", "C.6.5", "C.6.6", "C.6.7"],
            36: ["RI.6.4", "L.6.4.d", "L.6.5.a", "L.6.5.b"]
        };
        */
        /*
         // KY 7th Grade ELA CommonLit Pacing Guide
         const pacingGuideStandardsDict = {
             // Unit 1: Community and Belonging (Weeks 1-6)
             1: ["RL.7.1", "RL.7.2", "RL.7.3", "C.7.2.a"],
             2: ["RI.7.1", "RI.7.2", "RI.7.3", "C.7.2.b"],
             3: ["RL.7.6", "RI.7.7", "C.7.2.c", "C.7.2.d"],
             4: ["RI.7.10", "C.7.2.e", "C.7.2.f", "L.7.1.a"],
             5: ["RL.7.10", "C.7.2.g", "C.7.2.h", "L.7.1.b"],
             6: ["RI.7.6", "C.7.7", "L.7.2.a", "L.7.2.b"],
 
             // Unit 2: Adolescence (Weeks 7-12)
             7: ["RL.7.1", "RL.7.4", "RL.7.5", "C.7.1.a"],
             8: ["RI.7.1", "RI.7.4", "RI.7.5", "C.7.1.b"],
             9: ["RL.7.7", "RL.7.9", "C.7.1.c", "C.7.1.d"],
             10: ["RI.7.7", "RI.7.8", "C.7.1.e", "C.7.1.f"],
             11: ["RL.7.2", "RI.7.2", "C.7.1.g", "L.7.3.a"],
             12: ["RI.7.9", "RI.7.3", "L.7.4.a", "L.7.4.b"],
 
             // Unit 3: Brown Girl Dreaming by Jacqueline Woodson (Weeks 13-18)
             13: ["RL.7.1", "RL.7.3", "RL.7.4", "C.7.3.a"],
             14: ["RI.7.1", "RL.7.5", "RL.7.6", "C.7.3.b"],
             15: ["RL.7.2", "RI.7.2", "C.7.3.c", "C.7.3.d"],
             16: ["RI.7.4", "RI.7.5", "C.7.3.e", "C.7.3.f"],
             17: ["RL.7.7", "RI.7.6", "C.7.3.g", "L.7.4.c"],
             18: ["RI.7.7", "RL.7.9", "C.7.7", "L.7.4.d"],
 
             // Unit 4: Social Media: Risks and Rewards (Weeks 19-24)
             19: ["RI.7.1", "RI.7.2", "RI.7.3", "C.7.1.a"],
             20: ["RI.7.4", "RI.7.5", "C.7.1.b", "C.7.1.c"],
             21: ["RI.7.6", "RI.7.7", "C.7.1.d", "C.7.1.e"],
             22: ["RI.7.8", "RL.7.1", "C.7.1.f", "C.7.1.g"],
             23: ["RI.7.9", "RL.7.2", "C.7.4", "C.7.5"],
             24: ["RI.7.10", "RL.7.10", "C.7.6", "L.7.5.a"],
 
             // Unit 5: Influential Voices (Weeks 25-30)
             25: ["RL.7.1", "RL.7.4", "RL.7.6", "C.7.2.a"],
             26: ["RI.7.1", "RI.7.2", "RI.7.4", "C.7.2.b"],
             27: ["RL.7.2", "RI.7.6", "C.7.2.c", "C.7.2.d"],
             28: ["RI.7.7", "RI.7.8", "C.7.2.e", "C.7.2.f"],
             29: ["RL.7.7", "RI.7.9", "C.7.2.g", "C.7.2.h"],
             30: ["RI.7.10", "RL.7.9", "C.7.7", "L.7.5.b"],
 
             // Unit 6: School Electives: Which One Matters Most? (Weeks 31-36)
             31: ["RI.7.1", "RI.7.2", "RI.7.3", "C.7.1.a"],
             32: ["RI.7.7", "RL.7.1", "C.7.1.b", "C.7.1.c"],
             33: ["RI.7.8", "RL.7.2", "C.7.1.d", "C.7.1.e"],
             34: ["RI.7.9", "RL.7.4", "C.7.1.f", "C.7.1.g"],
             35: ["RI.7.10", "RL.7.6", "C.7.4", "C.7.5"],
             36: ["RL.7.10", "RI.7.6", "C.7.6", "L.7.5.c"]
         };
         */
        /*
        // KY 8th Grade ELA CommonLit Pacing Guide
        const pacingGuideStandardsDict = {
            // Unit 1: The Art of Suspense (Weeks 1-6)
            1: ["RL.8.1", "RL.8.2", "RL.8.3", "RI.8.1"],
            2: ["RL.8.5", "RL.8.6", "RI.8.2", "RI.8.7"],
            3: ["RL.8.10", "RI.8.10", "C.8.2.a", "C.8.2.b"],
            4: ["RI.8.2", "C.8.2.c", "C.8.2.d", "C.8.7"],
            5: ["RL.8.3", "L.8.1.a", "L.8.1.b", "L.8.2.a"],
            6: ["RL.8.4", "RI.8.1", "L.8.2.b", "L.8.2.c"],

            // Unit 2: Conveying Courage (Weeks 7-12)
            7: ["RL.8.1", "RL.8.2", "RL.8.4", "RI.8.1"],
            8: ["RL.8.5", "RL.8.6", "RI.8.2", "RI.8.3"],
            9: ["RI.8.4", "RI.8.5", "RI.8.7", "C.8.1.a"],
            10: ["RL.8.7", "C.8.1.b", "C.8.1.c", "C.8.1.d"],
            11: ["RI.8.3", "C.8.1.e", "C.8.1.f", "C.8.1.g"],
            12: ["RL.8.9", "RI.8.8", "L.8.3.a", "L.8.4.a"],

            // Unit 3: Twelve Angry Men by Reginald Rose (Weeks 13-18)
            13: ["RL.8.1", "RL.8.2", "RL.8.3", "RL.8.4"],
            14: ["RL.8.5", "RL.8.6", "RL.8.7", "RI.8.1"],
            15: ["RL.8.9", "RI.8.2", "RI.8.3", "RI.8.4"],
            16: ["RI.8.6", "RI.8.7", "C.8.2.e", "C.8.2.f"],
            17: ["RL.8.10", "C.8.2.g", "C.8.2.h", "C.8.3.a"],
            18: ["RI.8.9", "C.8.3.b", "C.8.3.c", "C.8.3.d"],

            // Unit 4: Contact Sports: Worth the risk? (Weeks 19-24)
            19: ["RI.8.1", "RI.8.2", "RI.8.3", "RI.8.6"],
            20: ["RI.8.7", "RI.8.8", "RI.8.9", "RI.8.10"],
            21: ["RI.8.4", "C.8.3.e", "C.8.3.f", "C.8.3.g"],
            22: ["RI.8.5", "C.8.4", "C.8.5", "C.8.6"],
            23: ["RI.8.3", "L.8.4.b", "L.8.4.c", "L.8.4.d"],
            24: ["RI.8.1", "RI.8.2", "L.8.5.a", "L.8.5.b"],

            // Unit 5: Not that Different (Weeks 25-30)
            25: ["RL.8.1", "RL.8.2", "RL.8.3", "RL.8.4"],
            26: ["RL.8.5", "RL.8.6", "RI.8.1", "RI.8.2"],
            27: ["RI.8.3", "RI.8.4", "RI.8.5", "RI.8.7"],
            28: ["RL.8.7", "RI.8.6", "C.8.1.a", "C.8.1.b"],
            29: ["RL.8.9", "C.8.1.c", "C.8.1.d", "C.8.1.e"],
            30: ["RI.8.8", "C.8.1.f", "C.8.1.g", "L.8.5.c"],

            // Unit 6: The Debate Over School Start Time (Weeks 31-36)
            31: ["RI.8.1", "RI.8.2", "RI.8.3", "RI.8.7"],
            32: ["RI.8.8", "RI.8.9", "RI.8.10", "C.8.2.a"],
            33: ["RI.8.4", "C.8.2.b", "C.8.2.c", "C.8.2.d"],
            34: ["RI.8.5", "C.8.2.e", "C.8.2.f", "C.8.2.g"],
            35: ["RI.8.6", "C.8.2.h", "C.8.4", "C.8.5"],
            36: ["RL.8.10", "C.8.6", "C.8.7", "L.8.1.c"]
        };
        */
        /*
         // KY 6th Grade Social Studies Pacing Guide (resources)
         const pacingGuideStandardsDict = {
             // Unit 1: Prehistoric (Pre-3500 B.C.E.) (Weeks 1-4)
             1: ["6.I.Q.1", "6.I.Q.2", "6.I.Q.3", "6.G.MM.1"],
             2: ["6.G.HE.1", "6.G.HE.2", "6.G.GR.1", "6.I.UE.1"],
             3: ["6.I.UE.2", "6.I.UE.3", "6.I.CC.1", "6.I.CC.2"],
             4: ["6.I.CC.3", "6.I.CC.4", "6.I.CC.5", "6.H.CE.1"],
 
             // Unit 2: First River Civilizations (3500 B.C.E. - 600 B.C.E) (Weeks 5-8)
             5: ["6.C.CP.1", "6.C.CP.2", "6.C.CP.3", "6.C.CV.1"],
             6: ["6.E.MI.1", "6.E.MI.2", "6.E.MI.3", "6.E.MI.4"],
             7: ["6.E.MA.1", "6.E.MA.2", "6.E.ST.1", "6.E.ST.2"],
             8: ["6.E.IC.1", "6.G.HI.1", "6.G.HI.2", "6.H.CH.1"],
 
             // Unit 3: Comparing Egypt and Mesopotamia (3500 B.C.E. - 600 B.C.E.) (Weeks 9-12)
             9: ["6.C.CP.1", "6.C.CP.2", "6.C.CP.3", "6.G.MM.1"],
             10: ["6.G.HE.1", "6.G.HE.2", "6.G.GR.1", "6.H.CH.1"],
             11: ["6.H.CH.2", "6.H.CE.1", "6.H.CO.1", "6.H.CO.2"],
             12: ["6.I.UE.1", "6.I.UE.2", "6.I.UE.3", "6.I.CC.1"],
 
             // Unit 4: The Americas (600 B.C.E. - 600 C.E.) (Weeks 13-16)
             13: ["6.G.MM.1", "6.G.HI.1", "6.G.HI.2", "6.G.HE.1"],
             14: ["6.G.HE.2", "6.G.GR.1", "6.H.CH.1", "6.H.CH.2"],
             15: ["6.E.MI.1", "6.E.MI.2", "6.E.MI.3", "6.E.MI.4"],
             16: ["6.E.MA.1", "6.E.MA.2", "6.E.ST.1", "6.E.ST.2"],
 
             // Unit 5: Africa (600 B.C.E. - 600 C.E.) (Weeks 17-20)
             17: ["6.G.MM.1", "6.G.HI.1", "6.G.HI.2", "6.G.HE.1"],
             18: ["6.G.HE.2", "6.G.GR.1", "6.H.CH.1", "6.H.CH.2"],
             19: ["6.E.MI.1", "6.E.MI.2", "6.E.MI.3", "6.E.MI.4"],
             20: ["6.E.MA.1", "6.E.MA.2", "6.E.ST.1", "6.E.ST.2"],
 
             // Unit 6: Greece and Persia (600 B.C.E. - 600 C.E.) (Weeks 21-24)
             21: ["6.C.CP.1", "6.C.CP.2", "6.C.CP.3", "6.C.RR.1"],
             22: ["6.C.CV.1", "6.C.PR.1", "6.H.CO.1", "6.H.CO.2"],
             23: ["6.G.MM.1", "6.G.HI.1", "6.G.HI.2", "6.G.HE.1"],
             24: ["6.G.HE.2", "6.G.GR.1", "6.H.CH.1", "6.H.CH.2"],
 
             // Unit 7: Rome (600 B.C.E. - 600 C.E.) (Weeks 25-28)
             25: ["6.C.CP.1", "6.C.CP.2", "6.C.CP.3", "6.C.RR.1"],
             26: ["6.C.CV.1", "6.C.PR.1", "6.H.CO.1", "6.H.CO.2"],
             27: ["6.G.MM.1", "6.G.HI.1", "6.G.HI.2", "6.G.HE.1"],
             28: ["6.G.HE.2", "6.G.GR.1", "6.H.CH.1", "6.H.CH.2"],
 
             // Unit 8: China (600 B.C.E. - 600 C.E.) (Weeks 29-32)
             29: ["6.C.CP.1", "6.C.CP.2", "6.C.CP.3", "6.C.CV.1"],
             30: ["6.E.MI.1", "6.E.MI.2", "6.E.MI.3", "6.E.MI.4"],
             31: ["6.G.MM.1", "6.G.HI.1", "6.G.HI.2", "6.G.HE.1"],
             32: ["6.G.HE.2", "6.G.GR.1", "6.H.CH.1", "6.H.CH.2"],
 
             // Unit 9: India (600 B.C.E. - 600 C.E.) (Weeks 33-36)
             33: ["6.C.CP.1", "6.C.CP.2", "6.C.CP.3", "6.C.CV.1"],
             34: ["6.E.MI.1", "6.E.MI.2", "6.E.MI.3", "6.E.MI.4"],
             35: ["6.G.MM.1", "6.G.HI.1", "6.G.HI.2", "6.G.HE.1"],
             36: ["6.G.HE.2", "6.G.GR.1", "6.H.CH.1", "6.H.KH.1"]
         };
         */
        /*
        // KY 7th Grade Social Studies Pacing Guide (resources)
        const pacingGuideStandardsDict = {
            // Unit 1: Civilization Complexity Overview (600-1600 C.E.) (Weeks 1-4)
            1: ["7.I.Q.1", "7.I.Q.2", "7.I.Q.3", "7.C.CP.1"],
            2: ["7.E.MI.1", "7.E.MI.2", "7.E.MI.3", "7.G.GR.1"],
            3: ["7.G.GR.2", "7.H.CH.1", "7.I.UE.1", "7.I.UE.2"],
            4: ["7.I.UE.3", "7.I.CC.1", "7.I.CC.2", "7.I.CC.3"],

            // Unit 2: Rise and Spread of Islam (600-800 C.E.) (Weeks 5-8)
            5: ["7.C.CP.1", "7.C.RR.1", "7.C.CV.1", "7.C.PR.1"],
            6: ["7.E.MA.1", "7.E.MA.2", "7.G.MM.1", "7.G.HI.1"],
            7: ["7.G.HI.2", "7.G.HE.1", "7.G.HE.2", "7.H.CO.1"],
            8: ["7.H.CO.2", "7.I.CC.4", "7.I.CC.5", "7.E.ST.1"],

            // Unit 3: Kingdoms of Africa (600-1600 C.E.) (Weeks 9-12)
            9: ["7.E.ST.2", "7.E.ST.3", "7.E.ST.4", "7.E.IC.1"],
            10: ["7.E.IC.2", "7.G.MM.1", "7.G.HI.1", "7.G.HI.2"],
            11: ["7.G.HE.1", "7.G.HE.2", "7.G.GR.1", "7.G.GR.2"],
            12: ["7.H.CE.1", "7.H.CE.2", "7.H.CO.1", "7.H.CO.2"],

            // Unit 4: European Middle Ages (600-1600 C.E.) (Weeks 13-16)
            13: ["7.C.CP.1", "7.C.RR.1", "7.C.CV.1", "7.C.PR.1"],
            14: ["7.E.MA.1", "7.E.MA.2", "7.E.ST.1", "7.E.ST.2"],
            15: ["7.G.MM.1", "7.G.HI.1", "7.G.HI.2", "7.G.HE.1"],
            16: ["7.H.CH.1", "7.H.CE.1", "7.H.CE.2", "7.H.CO.1"],

            // Unit 5: Americas (600-1491 C.E.) (Weeks 17-20)
            17: ["7.G.MM.1", "7.G.HI.1", "7.G.HI.2", "7.G.HE.1"],
            18: ["7.G.HE.2", "7.G.GR.1", "7.G.GR.2", "7.H.CH.1"],
            19: ["7.E.MI.1", "7.E.MI.2", "7.E.MI.3", "7.E.MA.1"],
            20: ["7.E.ST.3", "7.E.ST.4", "7.E.IC.1", "7.E.IC.2"],

            // Unit 6: Renaissance in Tang and Song China (600-1300 C.E.) (Weeks 21-24)
            21: ["7.C.CP.1", "7.C.RR.1", "7.C.CV.1", "7.C.PR.1"],
            22: ["7.E.MA.1", "7.E.MA.2", "7.E.ST.1", "7.E.ST.2"],
            23: ["7.G.HI.1", "7.G.HI.2", "7.G.HE.1", "7.G.HE.2"],
            24: ["7.H.CE.1", "7.H.CE.2", "7.H.CO.1", "7.H.CO.2"],

            // Unit 7: Rise of the Mongols (1200-1400 C.E.) (Weeks 25-28)
            25: ["7.C.CP.1", "7.C.RR.1", "7.G.MM.1", "7.G.HI.1"],
            26: ["7.G.HI.2", "7.G.HE.1", "7.G.HE.2", "7.G.GR.1"],
            27: ["7.H.CH.1", "7.H.CE.1", "7.H.CE.2", "7.H.CO.1"],
            28: ["7.E.ST.3", "7.E.ST.4", "7.E.IC.1", "7.E.IC.2"],

            // Unit 8: European Renaissance and Reformation (1350-1600 C.E.) (Weeks 29-32)
            29: ["7.C.CP.1", "7.C.RR.1", "7.C.CV.1", "7.C.PR.1"],
            30: ["7.E.MA.1", "7.E.MA.2", "7.H.CH.1", "7.H.CE.1"],
            31: ["7.G.HI.1", "7.G.HI.2", "7.G.HE.1", "7.G.HE.2"],
            32: ["7.H.CE.2", "7.H.CO.1", "7.H.CO.2", "7.I.CC.5"],

            // Unit 9: Changing the Balance of Power (1450-1600 C.E.) (Weeks 33-36)
            33: ["7.E.ST.1", "7.E.ST.2", "7.E.ST.3", "7.E.ST.4"],
            34: ["7.G.MM.1", "7.G.HI.1", "7.G.HI.2", "7.G.GR.2"],
            35: ["7.H.CH.1", "7.H.CE.1", "7.H.CE.2", "7.H.CO.2"],
            36: ["7.I.UE.1", "7.I.UE.2", "7.I.UE.3", "7.I.CC.4"]
        };
        */
        /*
         // KY 8th Grade Social Studies Pacing Guide (resources)
         const pacingGuideStandardsDict = {
             // Unit 1: British Colonial America (1600-1750) (Weeks 1-4)
             1: ["8.I.Q.1", "8.I.Q.2", "8.I.Q.3", "8.C.CP.1"],
             2: ["8.E.MI.1", "8.E.MI.2", "8.G.MM.1", "8.G.HI.1"],
             3: ["8.H.CH.1", "8.H.CE.1", "8.H.KH.1", "8.H.KH.2"],
             4: ["8.I.UE.1", "8.I.UE.2", "8.I.UE.3", "8.I.CC.1"],
 
             // Unit 2: Road toward Revolution (1750-1776) (Weeks 5-7)
             5: ["8.C.CP.2", "8.C.RR.1", "8.C.CV.1", "8.E.MA.1"],
             6: ["8.E.MA.2", "8.E.ST.1", "8.G.HE.1", "8.G.GR.1"],
             7: ["8.H.CO.1", "8.H.CE.1", "8.E.KE.1", "8.I.CC.2"],
 
             // Unit 3: The American Revolution (1776-1783) (Weeks 8-11)
             8: ["8.C.CP.3", "8.C.RR.2", "8.E.MA.3", "8.E.MA.4"],
             9: ["8.E.IC.1", "8.G.MM.1", "8.G.KGE.1", "8.H.CH.2"],
             10: ["8.H.CE.1", "8.H.CO.2", "8.H.KH.1", "8.E.KE.2"],
             11: ["8.I.UE.1", "8.I.UE.2", "8.I.UE.3", "8.I.CC.3"],
 
             // Unit 4: The U.S. Constitution (1783-1787) (Weeks 12-14)
             12: ["8.C.CP.1", "8.C.CP.2", "8.C.CP.3", "8.C.RR.1"],
             13: ["8.C.CV.1", "8.C.PR.1", "8.C.PR.2", "8.C.KGO.1"],
             14: ["8.E.MA.1", "8.E.MA.3", "8.H.CO.2", "8.I.CC.4"],
 
             // Unit 5: The New Republic (1787-1800) (Weeks 15-18)
             15: ["8.C.RR.2", "8.C.RR.3", "8.E.MI.1", "8.E.MI.2"],
             16: ["8.E.MA.2", "8.E.ST.1", "8.E.IC.1", "8.E.IC.2"],
             17: ["8.G.HI.1", "8.G.HE.1", "8.H.CH.2", "8.H.CE.1"],
             18: ["8.I.UE.1", "8.I.UE.2", "8.I.UE.3", "8.I.CC.5"],
 
             // Unit 6: Antebellum America (1800-1840) (Weeks 19-21)
             19: ["8.C.CP.2", "8.C.RR.1", "8.C.RR.2", "8.E.MA.1"],
             20: ["8.E.MA.2", "8.E.ST.1", "8.G.MM.1", "8.G.HI.1"],
             21: ["8.H.CH.2", "8.H.CH.3", "8.H.CH.4", "8.I.CC.1"],
 
             // Unit 7: American Expansion (1820-1850) (Weeks 22-25)
             22: ["8.C.RR.3", "8.E.MI.2", "8.E.MA.2", "8.E.ST.1"],
             23: ["8.G.MM.1", "8.G.HI.1", "8.G.HE.1", "8.G.GR.1"],
             24: ["8.H.CH.3", "8.H.CH.4", "8.H.CE.2", "8.H.CO.2"],
             25: ["8.I.UE.1", "8.I.UE.2", "8.I.UE.3", "8.I.CC.2"],
 
             // Unit 8: Road to the Civil War (1850-1860) (Weeks 26-28)
             26: ["8.C.CP.2", "8.C.RR.2", "8.C.RR.3", "8.E.IC.1"],
             27: ["8.E.IC.2", "8.G.MM.1", "8.G.HI.1", "8.G.HE.1"],
             28: ["8.H.CH.3", "8.H.CH.4", "8.H.CE.2", "8.I.CC.3"],
 
             // Unit 9: The Civil War (1860-1865) (Weeks 29-32)
             29: ["8.C.CP.2", "8.C.RR.2", "8.E.MA.3", "8.E.MA.4"],
             30: ["8.G.MM.1", "8.G.HI.1", "8.G.HE.1", "8.G.GR.1"],
             31: ["8.H.CH.5", "8.H.CE.2", "8.H.CO.4", "8.H.KH.1"],
             32: ["8.I.UE.1", "8.I.UE.2", "8.I.UE.3", "8.I.CC.4"],
 
             // Unit 10: Reconstruction (1865-1877) (Weeks 33-36)
             33: ["8.C.CP.2", "8.C.RR.1", "8.C.RR.2", "8.C.RR.3"],
             34: ["8.E.MA.2", "8.E.ST.1", "8.E.IC.1", "8.E.IC.2"],
             35: ["8.G.MM.1", "8.G.HI.1", "8.H.CH.5", "8.H.CE.2"],
             36: ["8.I.CC.1", "8.I.CC.2", "8.I.CC.3", "8.I.CC.5"]
         };
         */

        /*
       // KY High School Earth and Space Science Pacing Guide (Default)
       const pacingGuideStandardsDict = {
           // Unit 1: Earth's Place in the Universe
           1: ["HS-ESS1-1", "HS-ESS1-2"],
           2: ["HS-ESS1-2", "HS-ESS1-3"],
           3: ["HS-ESS1-3", "HS-ESS1-4"],
           4: ["HS-ESS1-4", "HS-ESS1-5"],
           5: ["HS-ESS1-5", "HS-ESS1-6"],
           6: ["HS-ESS1-6", "HS-ETS1-1"],

           // Unit 2: Earth's Systems I
           7: ["HS-ESS2-1", "HS-ESS2-2"],
           8: ["HS-ESS2-2", "HS-ESS2-3"],
           9: ["HS-ESS2-3", "HS-ESS2-4"],
           10: ["HS-ESS2-4", "HS-ESS2-5"],
           11: ["HS-ESS2-5", "HS-ESS2-6"],
           12: ["HS-ESS2-6", "HS-ETS1-2"],

           // Unit 3: Earth's Systems II
           13: ["HS-ESS2-7", "HS-ESS3-1"],
           14: ["HS-ESS3-1", "HS-ESS3-2"],
           15: ["HS-ESS3-2", "HS-ESS3-3"],
           16: ["HS-ESS3-3", "HS-ESS3-4"],
           17: ["HS-ESS3-4", "HS-ESS3-5"],
           18: ["HS-ESS3-5", "HS-ETS1-3"],

           // Unit 4: Earth and Human Activity I
           19: ["HS-ESS3-6", "HS-ESS1-1"],
           20: ["HS-ESS1-2", "HS-ESS1-3"],
           21: ["HS-ESS1-4", "HS-ESS1-5"],
           22: ["HS-ESS1-6", "HS-ESS2-1"],
           23: ["HS-ESS2-2", "HS-ESS2-3"],
           24: ["HS-ESS2-4", "HS-ETS1-4"],

           // Unit 5: Earth and Human Activity II
           25: ["HS-ESS2-5", "HS-ESS2-6"],
           26: ["HS-ESS2-7", "HS-ESS3-1"],
           27: ["HS-ESS3-2", "HS-ESS3-3"],
           28: ["HS-ESS3-4", "HS-ESS3-5"],
           29: ["HS-ESS3-6", "HS-ETS1-1"],
           30: ["HS-ETS1-2", "HS-ETS1-3"],

           // Unit 6: Review and Integration
           31: ["HS-ESS1-1", "HS-ESS1-2", "HS-ESS1-3"],
           32: ["HS-ESS1-4", "HS-ESS1-5", "HS-ESS1-6"],
           33: ["HS-ESS2-1", "HS-ESS2-2", "HS-ESS2-3"],
           34: ["HS-ESS2-4", "HS-ESS2-5", "HS-ESS2-6", "HS-ESS2-7"],
           35: ["HS-ESS3-1", "HS-ESS3-2", "HS-ESS3-3", "HS-ESS3-4"],
           36: ["HS-ESS3-5", "HS-ESS3-6", "HS-ETS1-4"]
       };
       */
        /*
          // KY High School Life Science Pacing Guide (Default)
          const pacingGuideStandardsDict = {
              // Unit 1: From Molecules to Organisms
              1: ["HS-LS1-1", "HS-LS1-2"],
              2: ["HS-LS1-2", "HS-LS1-3"],
              3: ["HS-LS1-3", "HS-LS1-4"],
              4: ["HS-LS1-4", "HS-LS1-5"],
              5: ["HS-LS1-5", "HS-LS1-6"],
              6: ["HS-LS1-6", "HS-LS1-7"],
  
              // Unit 2: Ecosystems: Interactions, Energy, and Dynamics
              7: ["HS-LS2-1", "HS-LS2-2"],
              8: ["HS-LS2-2", "HS-LS2-3"],
              9: ["HS-LS2-3", "HS-LS2-4"],
              10: ["HS-LS2-4", "HS-LS2-5"],
              11: ["HS-LS2-5", "HS-LS2-6"],
              12: ["HS-LS2-6", "HS-LS2-7"],
  
              // Unit 3: Heredity and Biological Evolution
              13: ["HS-LS2-8", "HS-LS3-1"],
              14: ["HS-LS3-1", "HS-LS3-2"],
              15: ["HS-LS3-2", "HS-LS3-3"],
              16: ["HS-LS3-3", "HS-LS4-1"],
              17: ["HS-LS4-1", "HS-LS4-2"],
              18: ["HS-LS4-2", "HS-LS4-3"],
  
              // Unit 4: Biological Evolution and Human Impact
              19: ["HS-LS4-3", "HS-LS4-4"],
              20: ["HS-LS4-4", "HS-LS4-5"],
              21: ["HS-LS4-5", "HS-LS4-6"],
              22: ["HS-LS4-6", "HS-LS2-7"],
              23: ["HS-LS2-7", "HS-ETS1-1"],
              24: ["HS-ETS1-1", "HS-ETS1-2"],
  
              // Unit 5: Engineering Design in Life Sciences
              25: ["HS-ETS1-2", "HS-ETS1-3"],
              26: ["HS-ETS1-3", "HS-ETS1-4"],
              27: ["HS-ETS1-4", "HS-LS1-1"],
              28: ["HS-LS1-2", "HS-LS1-3"],
              29: ["HS-LS2-1", "HS-LS2-2"],
              30: ["HS-LS3-1", "HS-LS3-2"],
  
              // Unit 6: Review and Integration
              31: ["HS-LS1-4", "HS-LS1-5", "HS-LS1-6"],
              32: ["HS-LS1-7", "HS-LS2-3", "HS-LS2-4"],
              33: ["HS-LS2-5", "HS-LS2-6", "HS-LS2-8"],
              34: ["HS-LS3-3", "HS-LS4-1", "HS-LS4-2"],
              35: ["HS-LS4-3", "HS-LS4-4", "HS-LS4-5"],
              36: ["HS-LS4-6", "HS-ETS1-3", "HS-ETS1-4"]
          };
          */
        /*
       // KY High School Physical Sciences Pacing Guide (Default)
       const pacingGuideStandardsDict = {
           // Unit 1: Matter and Its Interactions
           1: ["HS-PS1-1", "HS-PS1-2"],
           2: ["HS-PS1-3", "HS-PS1-4"],
           3: ["HS-PS1-5", "HS-PS1-6"],
           4: ["HS-PS1-7", "HS-PS1-8"],
           5: ["HS-PS1-1", "HS-PS1-3"],
           6: ["HS-PS1-5", "HS-PS1-7"],

           // Unit 2: Motion and Stability: Forces and Interactions
           7: ["HS-PS2-1", "HS-PS2-2"],
           8: ["HS-PS2-3", "HS-PS2-4"],
           9: ["HS-PS2-5", "HS-PS2-6"],
           10: ["HS-PS2-1", "HS-PS2-3"],
           11: ["HS-PS2-4", "HS-PS2-5"],
           12: ["HS-PS2-2", "HS-PS2-6"],

           // Unit 3: Energy
           13: ["HS-PS3-1", "HS-PS3-2"],
           14: ["HS-PS3-3", "HS-PS3-4"],
           15: ["HS-PS3-5", "HS-PS3-1"],
           16: ["HS-PS3-2", "HS-PS3-3"],
           17: ["HS-PS3-4", "HS-PS3-5"],
           18: ["HS-PS3-1", "HS-PS3-3"],

           // Unit 4: Waves and Their Applications
           19: ["HS-PS4-1", "HS-PS4-2"],
           20: ["HS-PS4-3", "HS-PS4-4"],
           21: ["HS-PS4-5", "HS-PS4-1"],
           22: ["HS-PS4-2", "HS-PS4-3"],
           23: ["HS-PS4-4", "HS-PS4-5"],
           24: ["HS-PS4-1", "HS-PS4-5"],

           // Unit 5: Engineering Design
           25: ["HS-ETS1-1", "HS-ETS1-2"],
           26: ["HS-ETS1-3", "HS-ETS1-4"],
           27: ["HS-ETS1-1", "HS-ETS1-3"],
           28: ["HS-ETS1-2", "HS-ETS1-4"],
           29: ["HS-PS1-6", "HS-PS2-3"],
           30: ["HS-PS3-3", "HS-PS4-5"],

           // Unit 6: Review and Integration
           31: ["HS-PS1-1", "HS-PS1-4", "HS-PS1-7"],
           32: ["HS-PS2-1", "HS-PS2-4", "HS-PS2-6"],
           33: ["HS-PS3-1", "HS-PS3-3", "HS-PS3-5"],
           34: ["HS-PS4-1", "HS-PS4-3", "HS-PS4-5"],
           35: ["HS-ETS1-1", "HS-ETS1-3", "HS-PS1-6"],
           36: ["HS-PS2-3", "HS-PS3-3", "HS-PS4-5"]
       };
       */
        /*
          // KY 6th Grade Science Pacing Guide (NextGeneration Science)
          const pacingGuideStandardsDict = {
              1: [],
              2: ["6-PS1-1", "6-LS1-6"],
              3: ["6-PS1-1", "6-LS1-6"],
              4: ["6-PS1-1", "6-LS1-6"],
              5: ["6-PS1-1", "6-LS1-6"],
              6: ["6-PS1-1", "6-LS1-6", "MS-ETS1-1"],
  
              7: ["6-PS1-4", "6-PS2-1", "6-PS2-4"],
              8: ["6-PS1-4", "6-PS2-1", "6-PS2-4"],
              9: ["6-PS1-4", "6-PS2-1", "6-PS2-4"],
              10: ["6-PS1-4", "6-PS2-1", "6-PS2-4"],
              11: ["6-PS1-4", "6-PS2-1", "6-PS2-4"],
              12: ["6-PS1-4", "6-PS2-1", "6-PS2-4", "MS-ETS1-2"],
  
              13: ["6-ESS2-4", "6-ESS2-5", "6-ESS2-6"],
              14: ["6-ESS2-4", "6-ESS2-5", "6-ESS2-6"],
              15: ["6-ESS2-4", "6-ESS2-5", "6-ESS2-6"],
              16: ["6-ESS2-4", "6-ESS2-5", "6-ESS2-6"],
              17: ["6-ESS2-4", "6-ESS2-5", "6-ESS2-6"],
              18: ["6-ESS2-4", "6-ESS2-5", "6-ESS2-6", "MS-ETS1-3"],
  
              19: ["6-ESS2-1", "6-ESS2-2", "6-ESS2-3"],
              20: ["6-ESS2-1", "6-ESS2-2", "6-ESS2-3"],
              21: ["6-ESS2-1", "6-ESS2-2", "6-ESS2-3"],
              22: ["6-ESS2-1", "6-ESS2-2", "6-ESS2-3"],
              23: ["6-ESS2-1", "6-ESS2-2", "6-ESS2-3"],
              24: ["6-ESS2-1", "6-ESS2-2", "6-ESS2-3", "MS-ETS1-4"],
  
              25: ["6-PS1-1", "6-LS2-1", "6-LS2-2", "6-LS2-3"],
              26: ["6-PS1-1", "6-LS2-1", "6-LS2-2", "6-LS2-3"],
              27: ["6-PS1-1", "6-LS2-1", "6-LS2-2", "6-LS2-3"],
              28: ["6-PS1-1", "6-LS2-1", "6-LS2-2", "6-LS2-3"],
              29: ["6-PS1-1", "6-LS2-1", "6-LS2-2", "6-LS2-3"],
              30: ["6-PS1-1", "6-LS2-1", "6-LS2-2", "6-LS2-3", "MS-ETS1-1"],
  
              31: ["6-ESS1-1", "6-ESS1-2", "6-ESS1-3"],
              32: ["6-ESS1-1", "6-ESS1-2", "6-ESS1-3"],
              33: ["6-ESS1-1", "6-ESS1-2", "6-ESS1-3"],
              34: ["6-ESS1-1", "6-ESS1-2", "6-ESS1-3"],
              35: ["6-ESS1-1", "6-ESS1-2", "6-ESS1-3"],
              36: ["6-ESS1-1", "6-ESS1-2", "6-ESS1-3", "MS-ETS1-2"]
          };
          */
        /*
       // KY 7th Grade Science Pacing Guide (NextGeneration Science)
       const pacingGuideStandardsDict = {
           1: [],
           2: ["7-LS1-1", "7-LS1-2"],
           3: ["7-LS1-3", "7-LS1-8"],
           4: ["7-LS1-3", "7-LS1-8"],
           5: ["7-LS1-1", "7-LS1-2", "7-LS1-3", "7-LS1-8"],
           6: ["7-LS1-1", "7-LS1-2", "7-LS1-3", "7-LS1-8"],

           7: ["7-PS1-2", "7-PS1-5", "7-PS1-6"],
           8: ["7-PS1-2", "7-PS1-5", "7-PS1-6"],
           9: ["7-PS3-3", "7-PS3-4", "7-PS3-5"],
           10: ["7-PS3-3", "7-PS3-4", "7-PS3-5"],
           11: ["MS-ETS1-2", "MS-ETS1-3", "MS-ETS1-4"],
           12: ["MS-ETS1-2", "MS-ETS1-3", "MS-ETS1-4"],

           13: ["7-LS1-7", "7-PS3-1"],
           14: ["7-LS1-7", "7-PS3-1"],
           15: ["7-PS3-2"],
           16: ["7-PS3-2"],
           17: ["7-LS1-7", "7-PS3-1", "7-PS3-2"],
           18: ["7-LS1-7", "7-PS3-1", "7-PS3-2"],

           19: ["7-PS1-6", "7-LS1-7"],
           20: ["7-PS1-6", "7-LS1-7"],
           21: ["7-PS1-6", "7-LS1-7"],
           22: ["7-PS1-6", "7-LS1-7"],
           23: ["7-PS1-6", "7-LS1-7"],
           24: ["7-PS1-6", "7-LS1-7"],

           25: ["7-PS1-2", "7-PS1-5"],
           26: ["7-PS1-2", "7-PS1-5"],
           27: ["7-PS3-3", "7-PS3-4"],
           28: ["7-PS3-3", "7-PS3-4"],
           29: ["7-PS3-5", "MS-ETS1-1"],
           30: ["7-PS3-5", "MS-ETS1-1"],

           31: ["7-PS2-2", "7-PS2-3"],
           32: ["7-PS2-2", "7-PS2-3"],
           33: ["7-PS2-5", "7-PS4-1"],
           34: ["7-PS4-2", "7-PS4-3"],
           35: ["MS-ETS1-2", "MS-ETS1-3"],
           36: ["MS-ETS1-4"]
       };
       */
        /*
          // KY 8th Grade Science Pacing Guide (NextGeneration Science)
          const pacingGuideStandardsDict = {
              1: [],
              2: ["8-PS1-3", "MS-ETS1-1", "MS-ETS1-2"],
              3: ["8-PS1-3", "MS-ETS1-3", "MS-ETS1-4"],
              4: ["8-PS1-3", "MS-ETS1-3", "MS-ETS1-4"],
              5: ["8-PS1-3", "8-LS1-4", "8-LS1-5"],
              6: ["8-PS1-3", "8-LS1-4", "8-LS1-5"],
  
              7: ["8-LS1-4", "8-LS1-5"],
              8: ["8-LS1-4", "8-LS1-5"],
              9: ["8-LS1-4", "8-LS1-5"],
              10: ["8-LS1-4", "8-LS1-5"],
              11: ["8-LS2-4", "8-LS2-5"],
              12: ["8-LS2-4", "8-LS2-5"],
  
              13: ["8-LS2-4", "8-LS2-5"],
              14: ["8-LS2-4", "8-LS2-5"],
              15: ["8-LS2-4", "8-LS2-5"],
              16: ["8-LS2-4", "8-LS2-5"],
              17: ["8-ESS1-4", "8-ESS3-1"],
              18: ["8-ESS1-4", "8-ESS3-1"],
  
              19: ["8-ESS1-4", "8-ESS3-1"],
              20: ["8-ESS1-4", "8-ESS3-1"],
              21: ["8-ESS3-2", "8-ESS3-3"],
              22: ["8-ESS3-2", "8-ESS3-3"],
              23: ["8-ESS3-4", "8-ESS3-5"],
              24: ["8-ESS3-4", "8-ESS3-5"],
  
              25: ["8-LS3-1", "8-LS3-2"],
              26: ["8-LS3-1", "8-LS3-2"],
              27: ["8-LS3-1", "8-LS3-2"],
              28: ["8-LS3-1", "8-LS3-2"],
              29: ["8-LS4-1", "8-LS4-2"],
              30: ["8-LS4-1", "8-LS4-2"],
  
              31: ["8-LS4-3", "8-LS4-4"],
              32: ["8-LS4-3", "8-LS4-4"],
              33: ["8-LS4-5", "8-LS4-6"],
              34: ["8-LS4-5", "8-LS4-6"],
              35: ["MS-ETS1-1", "MS-ETS1-2"],
              36: ["MS-ETS1-3", "MS-ETS1-4"]
          };
          */
        /*
        // 4th Grade Open Court ELA
        const pacingGuideStandardsDict = {
            1: [],
            2: [
                "ELA.4.AOR.1.1",
                "ELA.4.AOR.9.1",
                "ELA.4.AOR.8.1.a",
                "ELA.4.AOR.8.1.c",
                "ELA.4.F.4.2",
                "ELA.4.C.4.1.e",
                "ELA.4.C.4.1.h",
                "ELA.4.C.1.1.a",
                "ELA.4.C.1.1.b",
                "ELA.4.C.4.1.c",
            ],
            3: [
                "ELA.4.AOR.3.1.b",
                "ELA.4.AOR.5.3",
                "ELA.4.AOR.7.1.a",
                "ELA.4.AOR.1.2",
                "ELA.4.AOR.6.1.a",
                "ELA.4.C.4.1.c",
                "ELA.4.C.1.1.b",
                "ELA.4.C.4.1.g"
            ],
            4: [
                "ELA.4.AOR.7.1.c",
                "ELA.4.AOR.2.1",
                "ELA.4.AOR.10.1",
                "ELA.4.C.4.1.f",
                "ELA.4.C.4.1.g",
                "ELA.4.C.4.1.h",
                "ELA.4.C.1.1.c",
            ],
            5: [
                "ELA.4.AOR.7.1.b",
                "ELA.4.AOR.6.1.b",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.g",
                "ELA.4.R.1.1.a",
                "ELA.4.R.1.1.b",
            ],
            6: [
                "ELA.4.AOR.8.1.b",
                "ELA.4.C.4.1.e",
                "ELA.4.C.3.1.d",
                "ELA.4.C.3.1.b",
                "ELA.4.C.4.1.a",
                "ELA.4.C.4.1.b"
            ],
            7: [
                "ELA.4.AOR.9.1",
                "ELA.4.AOR.3.1.a",
                "ELA.4.AOR.6.1.a",
                "ELA.4.AOR.8.1.a",
                "ELA.4.AOR.8.1.c",
                "ELA.4.C.4.1.b",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.a",
                "ELA.4.R.1.5",
            ],
            8: [
                "ELA.4.AOR.7.1.c",
                "ELA.4.AOR.1.2",
                "ELA.4.C.4.1.a",
                "ELA.4.C.4.1.h",
                "ELA.4.C.4.1.i",
                "ELA.4.C.3.1.f"
            ],
            9: [
                "ELA.4.AOR.9.1",
                "ELA.4.AOR.2.2",
                "ELA.4.AOR.5.2",
                "ELA.4.C.4.1.b",
                "ELA.4.C.3.1.e"
            ],
            10: [
                "ELA.4.AOR.7.1.b",
                "ELA.4.AOR.6.1.b",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.h",
                "ELA.4.C.2.1.d",
                "ELA.4.C.2.1.e"
            ],
            11: [
                "ELA.4.AOR.5.1",
                "ELA.4.AOR.8.1.c",
                "ELA.4.C.4.1.a",
                "ELA.4.C.4.1.b",
                "ELA.4.C.4.1.c",
                "ELA.4.C.3.1.d",
                "ELA.4.C.4.1.i"
            ],
            12: [
                "ELA.4.AOR.1.2",
                "ELA.4.AOR.4.1",
                "ELA.4.AOR.7.1.a",
                "ELA.4.AOR.8.1.a",
                "ELA.4.C.4.1.f",
                "ELA.4.C.4.1.h",
                "ELA.4.C.2.1.b"
            ],
            13: [
                "ELA.4.AOR.1.1",
                "ELA.4.AOR.5.1",
                "ELA.4.C.4.1.b",
                "ELA.4.C.4.1.d",
                "ELA.4.C.4.1.g",
                "ELA.4.C.3.1.a",
                "ELA.4.C.3.1.b"
            ],
            14: [
                "ELA.4.AOR.2.2",
                "ELA.4.AOR.10.1",
                "ELA.4.AOR.7.1.a",
                "ELA.4.AOR.7.1.c",
                "ELA.4.C.4.1.e",
                "ELA.4.C.4.1.h",
                "ELA.4.C.3.1.c",
                "ELA.4.C.3.1.d",
                "ELA.4.R.1.2"
            ],
            15: [
                "ELA.4.AOR.2.1",
                "ELA.4.AOR.6.1.a",
                "ELA.4.C.4.1.a",
                "ELA.4.C.4.1.b",
                "ELA.4.C.4.1.c",
                "ELA.4.C.2.1.f",
                "ELA.4.C.7.1.a"
            ],
            16: [
                "ELA.4.AOR.1.1",
                "ELA.4.AOR.3.1.a",
                "ELA.4.AOR.6.1.a",
                "ELA.4.C.4.1.a",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.g",
                "ELA.4.C.6.1",
                "ELA.4.C.9.1"
            ],
            17: [
                "ELA.4.AOR.4.1",
                "ELA.4.AOR.8.1.a",
                "ELA.4.C.4.1.b",
                "ELA.4.C.4.1.h",
                "ELA.4.C.4.1.i",
                "ELA.4.C.1.1.d",
                "ELA.4.C.8.1.a",
                "ELA.4.C.8.1.b"
            ],
            18: [
                "ELA.4.AOR.1.2",
                "ELA.4.AOR.3.1.b",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.d",
                "ELA.4.C.4.1.e",
                "ELA.4.C.2.1.a",
                "ELA.4.C.2.1.c"
            ],
            19: [
                "ELA.4.AOR.7.1.b",
                "ELA.4.AOR.10.1",
                "ELA.4.C.4.1.a",
                "ELA.4.C.4.1.g",
                "ELA.4.C.4.1.h",
                "ELA.4.C.3.1.c",
                "ELA.4.C.3.1.d"
            ],
            20: [
                "ELA.4.AOR.5.1",
                "ELA.4.AOR.6.1.b",
                "ELA.4.C.4.1.b",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.f",
                "ELA.4.C.2.1.e",
                "ELA.4.C.5.1"
            ],
            21: [
                "ELA.4.AOR.2.2",
                "ELA.4.AOR.5.2",
                "ELA.4.AOR.8.1.c",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.e",
                "ELA.4.C.1.1.b",
                "ELA.4.C.1.1.c",
                "ELA.4.R.1.3"
            ],
            22: [
                "ELA.4.AOR.4.1",
                "ELA.4.AOR.10.1",
                "ELA.4.AOR.7.1.a",
                "ELA.4.AOR.8.1.a",
                "ELA.4.C.4.1.a",
                "ELA.4.C.4.1.h",
                "ELA.4.C.2.1.d",
                "ELA.4.C.5.1",
                "ELA.4.C.7.1.b"
            ],
            23: [
                "ELA.4.AOR.1.1",
                "ELA.4.AOR.6.1.a",
                "ELA.4.AOR.8.1.a",
                "ELA.4.AOR.8.1.b",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.g",
                "ELA.4.C.1.1.a"
            ],
            24: [
                "ELA.4.AOR.3.1.a",
                "ELA.4.AOR.3.1.b",
                "ELA.4.C.4.1.d",
                "ELA.4.C.4.1.i",
                "ELA.4.C.3.1.a",
                "ELA.4.C.3.1.e",
                "ELA.4.C.9.1"
            ],
            25: [
                "ELA.4.AOR.7.1.c",
                "ELA.4.AOR.8.1.c",
                "ELA.4.AOR.9.1",
                "ELA.4.C.4.1.b",
                "ELA.4.C.4.1.e",
                "ELA.4.C.1.1.b",
                "ELA.4.C.1.1.d",
                "ELA.4.C.6.1",
                "ELA.4.C.8.1.a"
            ],
            26: [
                "ELA.4.AOR.1.2",
                "ELA.4.AOR.6.1.a",
                "ELA.4.AOR.6.1.b",
                "ELA.4.C.4.1.a",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.g",
                "ELA.4.C.2.1.f",
                "ELA.4.C.4.1.i"
            ],
            27: [
                "ELA.4.AOR.1.1",
                "ELA.4.AOR.2.1",
                "ELA.4.C.4.1.f",
                "ELA.4.C.4.1.h",
                "ELA.4.C.3.1.b",
                "ELA.4.C.3.1.f",
                "ELA.4.C.8.1.b",
                "ELA.4.R.1.4"
            ],
            28: [
                "ELA.4.AOR.5.1",
                "ELA.4.AOR.10.1",
                "ELA.4.C.4.1.b",
                "ELA.4.C.4.1.d",
                "ELA.4.C.2.1.c",
                "ELA.4.C.2.1.e",
                "ELA.4.C.5.1",
                "ELA.4.C.7.1.a"
            ],
            29: [
                "ELA.4.AOR.6.1.b",
                "ELA.4.AOR.4.1",
                "ELA.4.AOR.8.1.a",
                "ELA.4.C.4.1.a",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.e",
                "ELA.4.C.3.1.c",
                "ELA.4.C.3.1.d"
            ],
            30: [
                "ELA.4.AOR.2.2",
                "ELA.4.AOR.5.2",
                "ELA.4.AOR.7.1.a",
                "ELA.4.C.4.1.g",
                "ELA.4.C.4.1.h",
                "ELA.4.C.2.1.a",
                "ELA.4.C.2.1.b",
                "ELA.4.C.2.1.d",
                "ELA.4.C.7.1.b",
                "ELA.4.C.9.1"
            ],
            31: [
                "ELA.4.AOR.1.1",
                "ELA.4.AOR.3.1.a",
                "ELA.4.AOR.3.1.b",
                "ELA.4.AOR.6.1.a",
                "ELA.4.C.4.1.a",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.f",
                "ELA.4.C.1.1.c",
                "ELA.4.C.6.1",
                "ELA.4.C.8.1.a",
                "ELA.4.C.8.1.b"
            ],
            32: [
                "ELA.4.AOR.1.2",
                "ELA.4.AOR.8.1.a",
                "ELA.4.AOR.8.1.c",
                "ELA.4.C.4.1.b",
                "ELA.4.C.4.1.h",
                "ELA.4.C.4.1.i",
                "ELA.4.C.3.1.e",
                "ELA.4.C.5.1"
            ],
            33: [
                "ELA.4.AOR.5.1",
                "ELA.4.AOR.6.1.b",
                "ELA.4.C.4.1.d",
                "ELA.4.C.4.1.g",
                "ELA.4.C.2.1.e",
                "ELA.4.C.2.1.f",
                "ELA.4.C.3.1.b",
                "ELA.4.C.7.1.a"
            ],
            34: [
                "ELA.4.AOR.5.2",
                "ELA.4.AOR.10.1",
                "ELA.4.AOR.7.1.b",
                "ELA.4.AOR.7.1.c",
                "ELA.4.C.4.1.c",
                "ELA.4.C.4.1.e",
                "ELA.4.C.1.1.a",
                "ELA.4.C.1.1.d",
                "ELA.4.C.3.1.d",
                "ELA.4.C.9.1"
            ],
            35: [
                "ELA.4.AOR.2.1",
                "ELA.4.AOR.4.1",
                "ELA.4.AOR.8.1.a",
                "ELA.4.AOR.9.1",
                "ELA.4.C.4.1.a",
                "ELA.4.C.4.1.b",
                "ELA.4.C.4.1.f",
                "ELA.4.C.4.1.h",
                "ELA.4.C.3.1.c",
                "ELA.4.C.3.1.f",
                "ELA.4.C.6.1",
                "ELA.4.C.8.1.a",
                "ELA.4.C.8.1.b"
            ],
            36: [
                // Foundations of Literacy
                "ELA.4.F.4.2",

                // Applications of Reading (key standards)
                "ELA.4.AOR.1.1", "ELA.4.AOR.2.1", "ELA.4.AOR.3.1.a",
                "ELA.4.AOR.5.1", "ELA.4.AOR.6.1.a", "ELA.4.AOR.7.1.a",
                "ELA.4.AOR.8.1.a", "ELA.4.AOR.9.1", "ELA.4.AOR.10.1",

                // Written and Oral Communications (key standards)
                "ELA.4.C.1.1.a", "ELA.4.C.2.1.a", "ELA.4.C.3.1.a",
                "ELA.4.C.4.1.a", "ELA.4.C.5.1", "ELA.4.C.6.1",
                "ELA.4.C.7.1.a", "ELA.4.C.8.1.a", "ELA.4.C.9.1",

                // Research (one key standard)
                "ELA.4.R.1.5"
            ]
        };
        */
        // 5th Grade Open Court ELA
        const pacingGuideStandardsDict = {
            1: [],
            2: [
                "ELA.5.AOR.8.1.b",
                "ELA.5.AOR.9.1",
                "ELA.5.AOR.10.1",
                "ELA.5.AOR.2.1",
                "ELA.5.AOR.6.1.b",
                "ELA.5.C.1.1.a",
                "ELA.5.C.1.1.b",
                "ELA.5.C.4.1.e",
                "ELA.5.C.4.1.f",
            ],
            3: [
                "ELA.5.AOR.5.3",
                "ELA.5.AOR.7.1.a",
                "ELA.5.AOR.7.1.c",
                "ELA.5.AOR.3.1.b",
                "ELA.5.AOR.1.2",
                "ELA.5.C.4.1.g",
                "ELA.5.C.4.1.h",
                "ELA.5.C.1.1.b",
                "ELA.5.C.1.1.e"
            ],
            4: [
                "ELA.5.AOR.9.1",
                "ELA.5.AOR.6.1.a",
                "ELA.5.AOR.3.1.a",
                "ELA.5.AOR.5.1",
                "ELA.5.AOR.8.1.a",
                "ELA.5.C.4.1.b",
                "ELA.5.C.1.1.c"
            ],
            5: [
                "ELA.5.AOR.8.1.b",
                "ELA.5.AOR.3.1.b",
                "ELA.5.AOR.7.1.b",
                "ELA.5.C.4.1.e",
                "ELA.5.C.4.1.g",
                "ELA.5.AOR.8.1.a",
                "ELA.5.AOR.7.1.a",
                "ELA.5.C.1.1.d"
            ],
            6: [
                "ELA.5.AOR.10.1",
                "ELA.5.AOR.2.1",
                "ELA.5.AOR.10.1",
                "ELA.5.AOR.8.1.a",
                "ELA.5.C.4.1.a",
                "ELA.5.C.4.1.b"
            ],
            7: [
                "ELA.5.AOR.8.1.b",
                "ELA.5.AOR.10.1",
                "ELA.5.AOR.6.1.a",
                "ELA.5.AOR.6.1.b",
                "ELA.5.C.4.1.e",
                "ELA.5.C.4.1.i",
                "ELA.5.C.3.1.f"
            ],
            8: [
                "ELA.5.AOR.7.1.c",
                "ELA.5.AOR.6.1.b",
                "ELA.5.AOR.1.1",
                "ELA.5.C.4.1.j",
                "ELA.5.C.3.1.d"
            ],
            9: [
                "ELA.5.AOR.9.1",
                "ELA.5.AOR.8.1.a",
                "ELA.5.AOR.8.1.b",
                "ELA.5.C.4.1.c",
                "ELA.5.C.4.1.d",
                "ELA.5.C.6.1"
            ],
            10: [
                "ELA.5.AOR.7.1.a",
                "ELA.5.AOR.2.1",
                "ELA.5.AOR.2.2",
                "ELA.5.C.4.1.e",
                "ELA.5.C.3.1.a"
            ],
            11: [
                "ELA.5.AOR.1.2",
                "ELA.5.AOR.3.1.b",
                "ELA.5.AOR.7.1.b",
                "ELA.5.C.3.1.b"
            ],
            12: [
                "ELA.5.AOR.10.1",
                "ELA.5.AOR.6.1.a",
                "ELA.5.AOR.6.1.b",
                "ELA.5.AOR.3.1.a",
                "ELA.5.C.3.1.c"
            ],
            13: [
                "ELA.5.AOR.1.1",
                "ELA.5.AOR.7.1.c",
                "ELA.5.C.4.1.a",
                "ELA.5.C.4.1.c",
                "ELA.5.C.4.1.d",
                "ELA.5.C.3.1.d",
                "ELA.5.C.3.1.e"
            ],
            14: [
                "ELA.5.AOR.8.1.a",
                "ELA.5.AOR.8.1.b",
                "ELA.5.C.4.1.i",
                "ELA.5.C.2.1.d"
            ],
            15: [
                "ELA.5.AOR.9.1",
                "ELA.5.AOR.5.1",
                "ELA.5.C.4.1.g",
                "ELA.5.C.4.1.h",
                "ELA.5.C.2.1.a",
                "ELA.5.C.2.1.c",
                "ELA.5.C.2.1.e"
            ],
            16: [
                "ELA.5.AOR.7.1.a",
                "ELA.5.AOR.2.2",
                "ELA.5.AOR.3.1.b",
                "ELA.5.C.4.1.e",
                "ELA.5.C.1.1.b",
                "ELA.5.C.1.1.e"
            ],
            17: [
                "ELA.5.AOR.8.1.b",
                "ELA.5.AOR.10.1",
                "ELA.5.AOR.4.1",
                "ELA.5.C.4.1.j",
                "ELA.5.C.2.1.b",
                "ELA.5.C.3.1.a",
                "ELA.5.C.3.1.b"
            ],
            18: [
                "ELA.5.AOR.9.1",
                "ELA.5.AOR.1.2",
                "ELA.5.AOR.5.2",
                "ELA.5.C.4.1.a",
                "ELA.5.C.4.1.c",
                "ELA.5.C.4.1.f",
                "ELA.5.C.3.1.c",
                "ELA.5.C.3.1.f"
            ],
            19: [
                "ELA.5.AOR.7.1.c",
                "ELA.5.AOR.6.1.a",
                "ELA.5.C.4.1.d",
                "ELA.5.C.4.1.g",
                "ELA.5.C.2.1.d",
                "ELA.5.C.7.1.c"
            ],
            20: [
                "ELA.5.AOR.8.1.a",
                "ELA.5.AOR.10.1",
                "ELA.5.AOR.2.1",
                "ELA.5.AOR.6.1.b",
                "ELA.5.C.4.1.h",
                "ELA.5.C.2.1.e",
                "ELA.5.C.5.1"
            ],
            21: [
                "ELA.5.AOR.8.1.b",
                "ELA.5.AOR.2.2",
                "ELA.5.AOR.1.1",
                "ELA.5.C.4.1.b",
                "ELA.5.C.4.1.f",
                "ELA.5.C.1.1.a",
                "ELA.5.C.8.1.a",
                "ELA.5.C.8.1.b"
            ],
            22: [
                "ELA.5.AOR.9.1",
                "ELA.5.AOR.10.1",
                "ELA.5.AOR.6.1.a",
                "ELA.5.AOR.4.1",
                "ELA.5.C.4.1.i",
                "ELA.5.C.4.1.j",
                "ELA.5.C.3.1.d",
                "ELA.5.C.3.1.e"
            ],
            23: [
                "ELA.5.AOR.1.2",
                "ELA.5.AOR.5.1",
                "ELA.5.C.4.1.a",
                "ELA.5.C.4.1.e",
                "ELA.5.C.1.1.d",
                "ELA.5.C.2.1.a",
                "ELA.5.C.7.1.a",
                "ELA.5.C.9.1"
            ],
            24: [
                "ELA.5.AOR.7.1.a",
                "ELA.5.AOR.7.1.c",
                "ELA.5.AOR.8.1.a",
                "ELA.5.C.4.1.c",
                "ELA.5.C.4.1.g",
                "ELA.5.C.2.1.b",
                "ELA.5.C.6.1"
            ],
            25: [
                "ELA.5.AOR.8.1.b",
                "ELA.5.AOR.3.1.a",
                "ELA.5.AOR.3.1.b",
                "ELA.5.C.4.1.b",
                "ELA.5.C.4.1.f",
                "ELA.5.C.1.1.c",
                "ELA.5.C.3.1.b",
                "ELA.5.C.3.1.f"
            ],
            26: [
                "ELA.5.AOR.7.1.a",
                "ELA.5.AOR.7.1.c",
                "ELA.5.C.4.1.a",
                "ELA.5.C.4.1.e",
                "ELA.5.C.4.1.h",
                "ELA.5.C.4.1.j",
                "ELA.5.C.3.1.a",
                "ELA.5.C.9.1"
            ],
            27: [
                "ELA.5.AOR.8.1.b",
                "ELA.5.AOR.9.1",
                "ELA.5.C.4.1.c",
                "ELA.5.C.4.1.g",
                "ELA.5.C.4.1.i",
                "ELA.5.C.2.1.c",
                "ELA.5.C.2.1.e",
                "ELA.5.C.7.1.b"
            ],
            28: [
                "ELA.5.AOR.8.1.a",
                "ELA.5.AOR.10.1",
                "ELA.5.AOR.1.2",
                "ELA.5.C.4.1.d",
                "ELA.5.C.4.1.f",
                "ELA.5.C.4.1.h",
                "ELA.5.C.1.1.e",
                "ELA.5.C.5.1"
            ],
            29: [
                "ELA.5.AOR.7.1.b",
                "ELA.5.AOR.2.1",
                "ELA.5.AOR.5.2",
                "ELA.5.C.4.1.a",
                "ELA.5.C.4.1.b",
                "ELA.5.C.2.1.d",
                "ELA.5.C.3.1.c"
            ],
            30: [
                "ELA.5.AOR.9.1",
                "ELA.5.AOR.6.1.a",
                "ELA.5.AOR.6.1.b",
                "ELA.5.AOR.4.1",
                "ELA.5.C.4.1.e",
                "ELA.5.C.4.1.j",
                "ELA.5.C.1.1.a",
                "ELA.5.C.1.1.b",
                "ELA.5.C.3.1.d",
                "ELA.5.C.3.1.e",
                "ELA.5.C.8.1.a"
            ],
            31: [
                "ELA.5.AOR.1.1",
                "ELA.5.AOR.7.1.a",
                "ELA.5.AOR.7.1.c",
                "ELA.5.C.4.1.c",
                "ELA.5.C.4.1.g",
                "ELA.5.C.2.1.a",
                "ELA.5.C.2.1.b",
                "ELA.5.C.2.1.e",
                "ELA.5.C.6.1",
                "ELA.5.R.1.5"
            ],
            32: [
                "ELA.5.AOR.8.1.b",
                "ELA.5.AOR.10.1",
                "ELA.5.AOR.3.1.b",
                "ELA.5.C.4.1.a",
                "ELA.5.C.4.1.d",
                "ELA.5.C.4.1.f",
                "ELA.5.C.4.1.i",
                "ELA.5.C.1.1.c",
                "ELA.5.C.1.1.d",
                "ELA.5.C.3.1.b",
                "ELA.5.C.3.1.f",
                "ELA.5.C.8.1.b"
            ],
            33: [
                "ELA.5.AOR.9.1",
                "ELA.5.AOR.2.2",
                "ELA.5.AOR.5.1",
                "ELA.5.C.4.1.b",
                "ELA.5.C.4.1.h",
                "ELA.5.C.4.1.j",
                "ELA.5.C.2.1.c",
                "ELA.5.C.7.1.a",
                "ELA.5.C.7.1.c"
            ], 34: [
                "ELA.5.AOR.8.1.a",
                "ELA.5.AOR.1.2",
                "ELA.5.AOR.6.1.a",
                "ELA.5.C.4.1.c",
                "ELA.5.C.4.1.e",
                "ELA.5.C.4.1.i",
                "ELA.5.C.1.1.e",
                "ELA.5.C.5.1",
                "ELA.5.C.9.1"
            ],
            35: [
                "ELA.5.AOR.7.1.b",
                "ELA.5.AOR.7.1.c",
                "ELA.5.AOR.9.1",
                "ELA.5.AOR.10.1",
                "ELA.5.C.4.1.a",
                "ELA.5.C.4.1.d",
                "ELA.5.C.4.1.f",
                "ELA.5.C.4.1.g",
                "ELA.5.C.4.1.h",
                "ELA.5.C.2.1.d",
                "ELA.5.C.3.1.a",
                "ELA.5.C.3.1.c",
                "ELA.5.C.3.1.e",
                "ELA.5.C.6.1",
                "ELA.5.C.8.1.a"
            ],
            36: [
                "ELA.5.F.4.2",
                "ELA.5.AOR.5.3",
                "ELA.5.R.1.1.a",
                "ELA.5.R.1.1.b",
                "ELA.5.R.1.2",
                "ELA.5.R.1.3",
                "ELA.5.R.1.4",
            ]
        };

        // ensure all standards exist in standardsDict
        for (let week in pacingGuideStandardsDict) {
            for (let standard of pacingGuideStandardsDict[week]) {
                if (!this.state.standardsDict.hasOwnProperty(standard)) {
                    console.error('standard does not exist', standard)
                    return
                }
            }
        }

        // sort by category
        /*for (let week in pacingGuideStandardsDict) {
            pacingGuideStandardsDict[week].sort((a, b) => {
                if (a.includes('RL') && !b.includes('RL')) {
                    return -1;
                }
                if (!a.includes('RL') && b.includes('RL')) {
                    return 1;
                }
                if (a.includes('RL') && b.includes('RL')) {
                    return a.localeCompare(b);
                }
                if (a.includes('RI') && !b.includes('RI')) {
                    return -1;
                }
                if (!a.includes('RI') && b.includes('RI')) {
                    return 1;
                }
                if (a.includes('RI') && b.includes('RI')) {
                    return a.localeCompare(b);
                }
                // AOR > C > R
                if (a.includes('AOR') && !b.includes('AOR')) {
                    return -1;
                }
                if (!a.includes('AOR') && b.includes('AOR')) {
                    return 1;
                }
                if (a.includes('C') && !b.includes('C')) {
                    return -1;
                }
                if (!a.includes('C') && b.includes('C')) {
                    return 1;
                }
                if (a.includes('C') && b.includes('C')) {
                    return a.localeCompare(b);
                }
                if (a.includes('R') && !b.includes('R')) {
                    return -1;
                }
                if (!a.includes('R') && b.includes('R')) {
                    return 1;
                }
                return a.localeCompare(b);
            });
        }*/

        let standardsScheduled = {}
        let weeks = {}
        for (let i = 0; i < this.state.totalWeeks; i++) {
            weeks[i] = []
        }

        for (let week in pacingGuideStandardsDict) {
            for (let standard of pacingGuideStandardsDict[week]) {
                weeks[parseInt(week)].push(this.state.standardsDict[standard])
                standardsScheduled[standard] = true
            }
        }

        // console.log if not all standards are scheduled
        let notAllScheduled = false
        for (let i = 0; i < this.state.standards.length; i++) {
            const standard = this.state.standards[i].standard
            if (!standardsScheduled.hasOwnProperty(standard)) {
                console.error('standard not scheduled', standard)
                notAllScheduled = true
            }
        }

        if (notAllScheduled) {
            console.error('not all standards scheduled, returning');
            return;
        }

        console.log('all standards scheduled, all standards exist');

        this.setWeeks(weeks);
    }

    /*setDefaultPacingGuide = () => {
        if (this.state.standards.length === 0) {
            return;
        }

        let standards = JSON.parse(JSON.stringify(this.state.standards));

        // Group the standards by their category
        const groupedStandards = standards.reduce((acc, curr) => {
            // ELA is [0], ELA K-2 is [2], Math is [1], change for the subject and grade level
            let category = curr.standard.split('.');
            category = category[1]

            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push(curr);
            return acc;
        }, {});

        // Sort each group
        for (const category in groupedStandards) {
            groupedStandards[category].sort((a, b) => {
                const partsA = a.standard.split('.');
                const partsB = b.standard.split('.');
                for (let i = 1; i < Math.min(partsA.length, partsB.length); i++) {
                    if (partsA[i] !== partsB[i]) {
                        if (isNaN(partsA[i]) || isNaN(partsB[i])) {
                            return partsA[i].localeCompare(partsB[i]);
                        }
                        return parseInt(partsA[i]) - parseInt(partsB[i]);
                    }
                }
                return partsA.length - partsB.length;
            });
        }

        console.log(groupedStandards)

        const weeks = Array.from({ length: 37 }, () => []);

        // Distribute each standard to cover all 36 weeks
        for (const category in groupedStandards) {
            let categoryStandards = groupedStandards[category];
            let numStandards = categoryStandards.length;
            let distributionSpan = Math.floor(36 / numStandards); // Span of weeks for each standard
            if (numStandards > 36) {
                distributionSpan = 1;
            }
            console.log(category, distributionSpan)
            if (distributionSpan > 30) {
                //distributionSpan /= 10;
                //distributionSpan = Math.floor(distributionSpan);
            }
            let weekIdx = 1;
            for (let i = 0; i < numStandards; i++) {
                let standard = categoryStandards[i];
                if (weekIdx + distributionSpan > 36) {
                    const weeksLeft = 36 - weekIdx + 1;
                    for (let j = 0; j < weeksLeft; j++) {
                        weeks[weekIdx + j].push(standard);
                    }
                    for (let standard of categoryStandards) {
                        if (this.isStandardScheduled(weeks, standard)) {
                            continue;
                        }
                        weeks[36].push(standard);
                    }
                    break;
                }
                for (let j = 0; j < distributionSpan; j++) {
                    const week = weekIdx + j;
                    if (week > 36) {
                        break;
                    }
                    weeks[week].push(standard);
                    if (category === 'RL' ||
                        category === 'RI' ||
                        category === 'F' ||
                        category === 'AOR' ||
                        category === 'NS' ||
                        category === 'ATO' ||
                        category === 'NSBT' ||
                        category === 'G' ||
                        category === 'H' ||
                        category == 'AG' ||
                        category == 'ER' ||
                        category == 'PS4' ||
                        category === 'LS1') {
                        if ((distributionSpan === 1 && i < numStandards - 1) ||
                            (j > 0 && i < numStandards - 1)) {
                            //(numStandards > 36 && i < numStandards - 1)) {
                            weeks[week].push(categoryStandards[i + 1]);
                        }
                    }
                }
                weekIdx += distributionSpan;
            }
        }

        for (let i = 1; i < 37; i++) {
            weeks[i].sort((a, b) => {
                // RL, RI, W, I, C
                let partsA = a.standard.split('.');
                let partsB = b.standard.split('.');
                if (partsA[0] === 'RL' && partsB[0] !== 'RL') {
                    return -1;
                } else if (partsA[0] !== 'RL' && partsB[0] === 'RL') {
                    return 1;
                } else if (partsA[0] === 'RI' && partsB[0] !== 'RI') {
                    return -1;
                } else if (partsA[0] !== 'RI' && partsB[0] === 'RI') {
                    return 1;
                } else if (partsA[0] === 'W' && partsB[0] !== 'W') {
                    return -1;
                } else if (partsA[0] !== 'W' && partsB[0] === 'W') {
                    return 1;
                } else if (partsA[0] === 'I' && partsB[0] !== 'I') {
                    return -1;
                } else if (partsA[0] !== 'I' && partsB[0] === 'I') {
                    return 1;
                } else if (partsA[0] === 'C' && partsB[0] !== 'C') {
                    return -1;
                } else if (partsA[0] !== 'C' && partsB[0] === 'C') {
                    return 1;
                }

                partsA = a.standard.split('.')[1];
                partsB = b.standard.split('.')[1];
                if (partsA === 'ATO' && partsB !== 'ATO') {
                    return -1;
                } else if (partsA !== 'ATO' && partsB === 'ATO') {
                    return 1;
                } else if (partsA === 'NSBT' && partsB !== 'NSBT') {
                    return -1;
                } else if (partsA !== 'NSBT' && partsB === 'NSBT') {
                    return 1;
                } else if (partsA === 'G' && partsB !== 'G') {
                    return -1;
                } else if (partsA !== 'G' && partsB === 'G') {
                    return 1;
                } else {
                    return 0;
                }

                return 0;
            });
        }

        weeks[0] = JSON.parse(JSON.stringify(standards)); // Optionally keep a copy of all standards
        this.setWeeks(weeks);
    }*/

    setFuturePacingGuide = () => {
        if (!this.state.futureStandards ||
            this.state.futureStandards.length === 0 ||
            !this.state.futureStandardsDict) {
            return;
        }

        let pacingGuideStandardsDict = {
            // Unit 1: Area and Surface Area (4 weeks)
            1: ["KY.6.G.1", "KY.6.EE.2.a", "KY.6.EE.2.c"],
            2: ["KY.6.G.1", "KY.6.EE.2.a", "KY.6.EE.2.c", "KY.6.G.4"],
            3: ["KY.6.G.1", "KY.6.G.4", "KY.6.G.2"],
            4: ["KY.6.G.1", "KY.6.G.4", "KY.6.EE.1", "KY.6.EE.2.a"],

            // Unit 2: Introducing Ratios (4 weeks)
            5: ["KY.6.RP.1"],
            6: ["KY.6.RP.1", "KY.6.RP.3.a"],
            7: ["KY.6.RP.3.a", "KY.6.RP.3.b"],
            8: ["KY.6.RP.2", "KY.6.RP.3.a", "KY.6.RP.3.b"],

            // Unit 3: Unit Rate and Percentages (4 weeks)
            9: ["KY.6.RP.2", "KY.6.RP.3.b"],
            10: ["KY.6.RP.2", "KY.6.RP.3.b"],
            11: ["KY.6.RP.3.c"],
            12: ["KY.6.RP.3.c"],

            // Unit 4: Dividing Fractions (4 weeks)
            13: ["KY.6.NS.1"],
            14: ["KY.6.NS.1", "KY.6.G.1", "KY.6.G.2"],
            15: ["KY.6.NS.1", "KY.6.G.2"],
            16: ["KY.6.NS.1"],

            // Unit 5: Arithmetic in Base Ten (4 weeks)
            17: ["KY.6.NS.3"],
            18: ["KY.6.NS.2.a", "KY.6.NS.2.b", "KY.6.NS.3"],
            19: ["KY.6.NS.2.a", "KY.6.NS.2.b", "KY.6.NS.3"],
            20: ["KY.6.EE.4", "KY.6.NS.3"],

            // Unit 6: Expressions and Equations (4 weeks)
            21: ["KY.6.EE.5", "KY.6.EE.6", "KY.6.EE.7"],
            22: ["KY.6.EE.2.a", "KY.6.EE.2.c", "KY.6.EE.6", "KY.6.EE.4"],
            23: ["KY.6.EE.1", "KY.6.EE.2.b", "KY.6.EE.3"],
            24: ["KY.6.EE.1", "KY.6.EE.2.c", "KY.6.EE.5", "KY.6.EE.9.a", "KY.6.EE.9.b", "KY.6.EE.9.c"],

            // Unit 7: Rational Numbers (4 weeks)
            25: ["KY.6.NS.5", "KY.6.NS.6.a", "KY.6.NS.7.a"],
            26: ["KY.6.NS.7.b", "KY.6.NS.5"],
            27: ["KY.6.NS.7.c", "KY.6.NS.7.d", "KY.6.NS.6.a", "KY.6.EE.8"],
            28: ["KY.6.NS.6.b", "KY.6.NS.6.c", "KY.6.NS.8", "KY.6.G.3", "KY.6.NS.4"],

            // Unit 8: Data Sets and Distributions (4 weeks)
            29: ["KY.6.SP.0.a", "KY.6.SP.0.b", "KY.6.SP.1", "KY.6.SP.5.b"],
            30: ["KY.6.SP.2", "KY.6.SP.0.c", "KY.6.SP.4"],
            31: ["KY.6.SP.3", "KY.6.SP.0.d", "KY.6.SP.5.c"],
            32: ["KY.6.SP.5.b", "KY.6.SP.5.c", "KY.6.SP.5.d", "KY.6.SP.1", "KY.6.SP.4", "KY.6.SP.5.a", "KY.6.SP.5.e"],

            // Unit 9: Putting It All Together (4 weeks)
            33: ["KY.6.G.1", "KY.6.NS.2.a", "KY.6.RP.1"],
            34: ["KY.6.NS.2.b", "KY.6.RP.1", "KY.6.NS.1"],
            35: ["KY.6.RP.1", "KY.6.RP.3.a", "KY.6.RP.3.c"],
            36: ["KY.6.NS.3", "KY.6.RP.2", "KY.6.RP.3.a", "KY.6.RP.3.c"]
        };

        // ensure all standards exist in futureStandardsDict
        for (let week in pacingGuideStandardsDict) {
            for (let standard of pacingGuideStandardsDict[week]) {
                if (!this.state.futureStandardsDict.hasOwnProperty(standard)) {
                    console.error('standard does not exist', standard)
                    return
                }
            }
        }

        // see if all are scheduled
        let scheduled = Object.assign({}, this.state.futureStandardsDict);
        for (let week in pacingGuideStandardsDict) {
            for (let standard of pacingGuideStandardsDict[week]) {
                delete scheduled[standard];
            }
        }
        if (Object.keys(scheduled).length > 0) {
            console.error('standards not scheduled', scheduled)
            return;
        }

        let weeks = {}
        for (let i = 0; i < this.state.totalWeeks; i++) {
            weeks[i] = []
        }

        for (let week in pacingGuideStandardsDict) {
            for (let standard of pacingGuideStandardsDict[week]) {
                weeks[parseInt(week)].push(this.state.futureStandardsDict[standard])
            }
        }

        let futurePacingGuide = {
            standardName: this.state.standardName,
            standardCategory: this.state.standardCategory,
            state: 'SC',
            year: '2023',
            weeks: weeks,
            timeStamp: firebase.firestore.FieldValue.serverTimestamp()
        }

        if (this.state.futurePacingGuide) {
            db.collection(ColType.futurePacingGuides)
                .doc(this.state.futurePacingGuide.id)
                .set(futurePacingGuide, { merge: true })
                .then(() => {
                    //console.log('Future pacing guide updated')
                })
                .catch(error => {
                    //console.error('Error updating future pacing guide', error)
                })
        } else {
            db.collection(ColType.futurePacingGuides)
                .add(futurePacingGuide)
                .then(docRef => {
                    //console.log('Future pacing guide added', docRef.id)
                    futurePacingGuide.id = docRef.id;
                    this.setState({ futurePacingGuide: futurePacingGuide })
                })
                .catch(error => {
                    console.error('Error adding future pacing guide', error)
                })
        }
    }


    copyWeeksToTargetWeek = (weekStart, weekEnd, targetWeekStart) => {
        //console.log('copyWeeksToTargetWeek', weekStart, weekEnd, targetWeekStart)
        const weekDiff = weekEnd - weekStart;
        if (targetWeekStart + weekDiff > 36) {
            //console.log('target week > 36')
            return;
        }
        let pacingGuide = this.state.pacingGuide;
        for (let i = 0; i <= weekDiff; i++) {
            pacingGuide.weeks[targetWeekStart + i] = pacingGuide.weeks[weekStart + i];
        }
        this.setWeeks(pacingGuide.weeks);
    }

    setWeeksToStandards = (standardName, weeksDict) => {
        if (standardName !== this.state.standardName) {
            //console.log('standard name mismatch')
            return;
        }
        let pacingGuide = this.state.pacingGuide;
        Object.keys(weeksDict).map((key) => {
            const weekStandards = weeksDict[key];
            const week = parseInt(key);
            pacingGuide.weeks[week] = [];
            for (let i = 0; i < weekStandards.length; i++) {
                const weekStandard = weekStandards[i];
                if (!this.state.standardsDict.hasOwnProperty(weekStandard)) {
                    //console.log('standard not found', weekStandard, weekStandards)
                    return false
                }
                const standard = this.state.standardsDict[weekStandard];
                pacingGuide.weeks[week].push(standard);
            }
            return false;
        })
        this.setWeeks(pacingGuide.weeks);
    }

    /*shiftWeeksDown = () => {
        let pacingGuide = this.state.pacingGuide;
        let weeks = pacingGuide.weeks;
        const maxWeek = this.state.totalWeeks - 1;

        for (let i = maxWeek; i >= 2; i--) {
            weeks[i] = weeks[i - 1];
        }

        weeks[1] = [];

        //this.setWeeks(weeks);
    }*/

    render() {
        const { match: { url } } = this.props

        return (
            <div>
                <Route path={`${url}/guide/*`}
                    render={props => PacingGuideComponent(props, this.props, this.state)} />
                <Route path={`${url}/lesson-plan/*`}
                    render={props => LessonPlanComponent(props, this.props, this.state)} />
                <Route path={`${url}/weekly-planner/*`}
                    render={props => WeeklyPlannerComponent(props, this.props, this.state)} />

                {/*<Button style={{
                    position: 'fixed',
                    top: '88px',
                }}
                    onClick={() => {
                        //this.setPacingGuideWithPresetStandards()
                        //this.setFuturePacingGuide()
                        //this.shiftWeeksDown()
                        //this.updatePacingGuideStandards()
                    }}
                >
                    Set pacing guide with preset standards
                </Button>*/}
            </div>
        )
    }
}

export default PacingGuide