import React, { Component } from 'react'
import '../styles/GlobalStyle.css'
import { db, firebase } from '../firebase/Firebase'
import ColType from '.././Types'
import { flattenDoc, getCurrentYear, getYearStart, manualCheckAssessment, manualCheckQuestionDoc } from '.././Util'
import SuperAdminHeader from './SuperAdminHeader'
import { Layout, Icon, Menu, Select, Radio, notification, Badge, Popconfirm, Button, Input } from 'antd'
import { Document } from '../teacher/edvizv2/Edviz'
import DocumentDelegator from '../edviz/EdvizDelegator'

import CustomFooter from '../login/CustomFooter'
import Empty from '../customcomponents/Empty'
const Sider = Layout.Sider
const Content = Layout.Content
var moment = require('moment')

class SuperAdminTestReview extends Component {
    state = {
        districtId: '64bec570267c8bfeea81f9ea',
        districtIds: [
            '64bec570267c8bfeea81f9ea',
            '64e3cb7826043cd12a0f829e',
            '596759b5a22ee600019e49aa',
            '521ce2f24da7037445000552',
            '534d91329c86b4f878000912',
        ],
        districts: [],
        districtsDict: {},
        quizzes: [],
        quizzesDict: {},
        quizzesGroupedByWeek: {},
        quizListener: null,
        selectedQuiz: null,
        standardBreakdown: {},
        approvedDict: {},
        approvedNotReviewedDict: {},
        showAdvanced: false,
        isSuper: false,
        reloadingQuestionsDict: {},
    }

    componentDidMount() {
        this.getDistricts()
        this.getQuizzes()
        this.getInitialApprovedQuizzes()
    }

    componentWillUnmount() {
        if (this.state.quizListener) {
            this.state.quizListener()
        }
    }

    getDistricts() {
        this.state.districtIds.forEach((districtId) => {
            db.collection(ColType.district)
                .doc(districtId)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        let district = flattenDoc(doc)
                        let districts = this.state.districts
                        let districtsDict = this.state.districtsDict
                        districts.push(district)
                        districtsDict[district.id] = district
                        this.setState({
                            districts: districts,
                            districtsDict: districtsDict,
                        })
                    }
                })
        })
    }

    onSelectDistrict(districtId) {
        this.setState({
            districtId: districtId,
        }, () => {
            this.getQuizzes()
        })
    }

    isNotApproved = (quiz) => {
        return !quiz.hasOwnProperty('approved') ||
            (quiz.hasOwnProperty('approved') && quiz.approved === 'not-approved')
    }

    isApproved = (quiz) => {
        return quiz.hasOwnProperty('approved') && quiz.approved === 'approved'
    }

    isReviewedNotApproved = (quiz) => {
        return quiz.hasOwnProperty('approved') && quiz.approved === 'reviewed-not-approved'
    }

    async getInitialApprovedQuizzes() {
        let approvedDict = {}
        let approvedNotReviewedDict = {}
        for (let i = 0; i < this.state.districtIds.length; i++) {
            approvedDict[this.state.districtIds[i]] = {}
            approvedNotReviewedDict[this.state.districtIds[i]] = {}
        }
        this.setState({
            approvedDict: approvedDict,
            approvedNotReviewedDict: approvedNotReviewedDict,
        })

        for (let i = 0; i < this.state.districtIds.length; i++) {
            const districtId = this.state.districtIds[i]
            db.collection(ColType.quizzes)
                .where('districtId', '==', districtId)
                .where('queryId', '==', districtId)
                .where('year', '==', getCurrentYear())
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        const quiz = flattenDoc(doc)
                        if (this.isNotApproved(quiz)) {
                            approvedDict[districtId][quiz.id] = false
                        } else if (this.isReviewedNotApproved(quiz)) {
                            approvedNotReviewedDict[districtId][quiz.id] = false
                        }
                    })

                    this.setState({
                        approvedDict: approvedDict,
                    })
                })
        }
    }

    async getQuizzes() {
        if (!this.state.districtId) {
            return
        }

        if (this.state.quizListener) {
            this.state.quizListener()
        }
        this.setState({
            quizListener: null,
        })

        const quizListener = db.collection(ColType.quizzes)
            .where('districtId', '==', this.state.districtId)
            .where('queryId', '==', this.state.districtId)
            .where('year', '==', getCurrentYear())
            //.where('createdAt', '>', getYearStart())
            .onSnapshot((querySnapshot) => {
                let quizzes = []
                let quizzesDict = {}
                let quizzesGroupedByWeek = {}
                let selectedQuiz = null
                querySnapshot.forEach((doc) => {
                    const quiz = flattenDoc(doc)
                    quiz.docs = JSON.parse(quiz.docs)
                    quizzes.push(quiz)
                    quizzesDict[quiz.id] = quiz

                    if (quiz.id !== quiz.uuid) {
                        console.error('Quiz id does not match uuid')
                    }
                    const key = quiz.startWeek + '-' + quiz.endWeek
                    if (!quizzesGroupedByWeek.hasOwnProperty(key)) {
                        quizzesGroupedByWeek[key] = []
                    }
                    quizzesGroupedByWeek[key].push(quiz)

                    if (this.state.selectedQuiz && quiz.id === this.state.selectedQuiz.id) {
                        selectedQuiz = quiz
                    }
                })

                // sort by assessmentTitle
                Object.keys(quizzesGroupedByWeek).forEach((key) => {
                    quizzesGroupedByWeek[key].sort((a, b) => {
                        if (a.assessmentTitle < b.assessmentTitle) {
                            return -1
                        }
                        if (a.assessmentTitle > b.assessmentTitle) {
                            return 1
                        }
                        return 0
                    })
                })

                this.setState({
                    quizzes: quizzes,
                    quizzesDict: quizzesDict,
                    quizzesGroupedByWeek: quizzesGroupedByWeek,
                    selectedQuiz: selectedQuiz,
                })
            })
        this.setState({
            quizListener: quizListener,
        })
    }

    selectQuiz = (quizKey) => {
        const quiz = this.state.quizzesDict &&
            this.state.quizzesDict.hasOwnProperty(quizKey) &&
            this.state.quizzesDict[quizKey] ?
            this.state.quizzesDict[quizKey] : null

        this.setState({
            selectedQuiz: quiz,
        }, () => {
            this.getStandardQuestionBreakdown()
            window.scrollTo(0, 0)
        })
    }

    allQuestionsAreValid = () => {
        if (!this.state.selectedQuiz) {
            return false
        }
        return manualCheckAssessment(this.state.selectedQuiz)
    }

    getNumberOfDOKQuestions = (dokLevel) => {
        let numDOKQuestions = 0
        for (let i = 0; i < this.state.selectedQuiz.docs.length; i++) {
            const questionDoc = this.state.selectedQuiz.docs[i]
            if (typeof questionDoc.metadata.dokLevel === 'string') {
                return 'DOK Level has type of string, expected int'
            }
            if (questionDoc.metadata.dokLevel === dokLevel) {
                numDOKQuestions += 1
            }
        }
        return numDOKQuestions
    }

    getStandardQuestionBreakdown = () => {
        let standardBreakdown = {}
        this.state.selectedQuiz.docs.forEach((questionDoc) => {
            const standard = questionDoc.metadata.standard.standard
            if (!standardBreakdown.hasOwnProperty(standard)) {
                standardBreakdown[standard] = 0
            }
            standardBreakdown[standard] += 1
        })

        this.setState({
            standardBreakdown: standardBreakdown,
        })

        return standardBreakdown
    }

    setQuizApproved = (approvedStr) => {
        if (approvedStr === 'approved') {
            let approvedDict = this.state.approvedDict
            if (approvedDict.hasOwnProperty(this.state.districtId)) {
                delete approvedDict[this.state.districtId][this.state.selectedQuiz.id]
                this.setState({
                    approvedDict: approvedDict,
                })
            }
            let approvedNotReviewedDict = this.state.approvedNotReviewedDict
            if (approvedNotReviewedDict.hasOwnProperty(this.state.districtId)) {
                delete approvedNotReviewedDict[this.state.districtId][this.state.selectedQuiz.id]
                this.setState({
                    approvedNotReviewedDict: approvedNotReviewedDict,
                })
            }
        } else if (approvedStr === 'reviewed-not-approved') {
            let approvedDict = this.state.approvedDict
            if (approvedDict.hasOwnProperty(this.state.districtId)) {
                delete approvedDict[this.state.districtId][this.state.selectedQuiz.id]
                this.setState({
                    approvedDict: approvedDict,
                })
            }
            let approvedNotReviewedDict = this.state.approvedNotReviewedDict
            if (approvedNotReviewedDict.hasOwnProperty(this.state.districtId)) {
                approvedNotReviewedDict[this.state.districtId][this.state.selectedQuiz.id] = false
                this.setState({
                    approvedNotReviewedDict: approvedNotReviewedDict,
                })
            }
        } else {
            let approvedDict = this.state.approvedDict
            if (approvedDict.hasOwnProperty(this.state.districtId)) {
                approvedDict[this.state.districtId][this.state.selectedQuiz.id] = false
                this.setState({
                    approvedDict: approvedDict,
                })
            }
            let approvedNotReviewedDict = this.state.approvedNotReviewedDict
            if (approvedNotReviewedDict.hasOwnProperty(this.state.districtId)) {
                delete approvedNotReviewedDict[this.state.districtId][this.state.selectedQuiz.id]
                this.setState({
                    approvedNotReviewedDict: approvedNotReviewedDict,
                })
            }
        }

        let approvedDisplayStr = ''
        if (approvedStr === 'not-approved') {
            approvedDisplayStr = 'Not Approved'
        } else if (approvedStr === 'approved') {
            approvedDisplayStr = 'Approved'
        } else if (approvedStr === 'reviewed-not-approved') {
            approvedDisplayStr = 'Reviewed and still not approved'
        } else {
            console.error('Invalid approvedStr', approvedStr)
            return
        }

        db.collection(ColType.quizzes)
            .doc(this.state.selectedQuiz.id)
            .update({
                approved: approvedStr,
            })
            .then(() => {
                notification.success({
                    message: 'Success',
                    description: 'Assessment has been set to: ' + approvedDisplayStr + '.',
                })
            })
            .catch((error) => {
                console.error('Error updating quiz', error)
                notification.error({
                    message: 'Error',
                    description: 'An error occurred.',
                })
            });
    }

    deleteQuiz = (quiz) => {
        if (quiz.live) {
            console.log('Cannot delete live quiz')
            return
        }

        db.collection(ColType.quizzes)
            .doc(quiz.id)
            .delete()
            .then(() => {
                notification.success({
                    message: 'Success',
                    description: 'Assessment has been deleted.',
                    placement: 'bottomRight',
                })
                this.setState({
                    selectedQuiz: null,
                })
            })
            .catch((error) => {
                console.error('Error deleting quiz', error)
                notification.error({
                    message: 'Error',
                    description: 'An error occurred.',
                })
            });
    }

    setAllTestsLive = () => {
        // calling will toggle live and unlive for all quizzes in this district in this week
        if (!this.state.selectedQuiz) {
            return
        }

        const key = this.state.selectedQuiz.startWeek + '-' + this.state.selectedQuiz.endWeek
        if (!this.state.quizzesGroupedByWeek.hasOwnProperty(key)) {
            return
        }
        let prevLive = this.state.selectedQuiz.live
        let live = !prevLive
        let canView = live
        let quizzes = this.state.quizzesGroupedByWeek[key]
        console.log('found', quizzes)

        if (quizzes.length === 0) {
            console.error('No quizzes found')
            return
        }


        let districtId = quizzes[0].districtId
        let numQuestions = quizzes[0].docs.length
        for (let i = 0; i < quizzes.length; i++) {
            const quiz = quizzes[i]
            if (quiz.districtId !== districtId) {
                console.error('District id does not match')
                return
            }
            if (quiz.docs.length !== numQuestions) {
                console.error('Number of questions do not match')
                return
            }
            if (!manualCheckAssessment(quiz)) {
                console.error('Quiz is not valid')
                return
            }
        }

        let promises = []
        for (let i = 0; i < quizzes.length; i++) {
            const quiz = quizzes[i]
            promises.push(new Promise((resolve, reject) => {
                db.collection(ColType.quizzes)
                    .doc(quiz.id)
                    .update({
                        live: live,
                        canView: canView,
                    })
                    .then(() => {
                        console.log('Quiz updated')
                        resolve()
                    })
                    .catch((error) => {
                        console.error('Error updating quiz', error)
                        reject()
                    });
            }));
        }

        Promise.all(promises).then(() => {
            notification.success({
                message: 'Success',
                description: 'All tests for weeks ' + this.state.selectedQuiz.startWeek + '-' + this.state.selectedQuiz.endWeek + ' have been set to live: ' + live + '.',
                placement: 'bottomRight',
            })
        }).catch((error) => {
            console.log('Error updating quizzes', error)
            notification.error({
                message: 'Error',
                description: 'An error occurred.',
                placement: 'bottomRight',
            })
        })
    }

    render() {
        console.log(this.state)
        let totalNotApprovedQuizzesWeek = {}
        if (this.state.quizzesGroupedByWeek) {
            Object.keys(this.state.quizzesGroupedByWeek).forEach((weekStr) => {
                totalNotApprovedQuizzesWeek[weekStr] = { 'reviewed-not-approved': 0, 'not-approved': 0 }
                this.state.quizzesGroupedByWeek[weekStr].forEach((quiz) => {
                    if (this.isNotApproved(quiz)) {
                        totalNotApprovedQuizzesWeek[weekStr]['not-approved'] += 1
                    }
                    if (this.isReviewedNotApproved(quiz)) {
                        totalNotApprovedQuizzesWeek[weekStr]['reviewed-not-approved'] += 1
                    }
                })
            })
        }
        console.log(totalNotApprovedQuizzesWeek)
        return <div>
            <SuperAdminHeader menuKey={'test-review'} />
            <div>
                <Sider
                    width={270}
                    style={{
                        overflow: 'auto', overflowX: 'hidden', height: 'calc(100% - 64px)',
                        position: 'fixed',
                        left: 0, borderRight: '1px solid #f1f1f1',
                        background: '#fff',
                    }}>
                    <div style={{ marginTop: 64 }}>
                        <div className='pl-1 pr-1 mb-3'>
                            <Select value={this.state.districtId}
                                size='large' style={{ width: '100%' }} onChange={(e) => {
                                    this.onSelectDistrict(e)
                                }}>
                                {this.state.districts.map((district) => {
                                    return <Select.Option value={district.id}>
                                        <div className='flex flex-v-center'>
                                            {this.state.approvedDict.hasOwnProperty(district.id) &&
                                                Object.keys(this.state.approvedDict[district.id]).length > 0 ?
                                                <Badge
                                                    className='mr-1'
                                                    count={Object.keys(this.state.approvedDict[district.id]).length} />
                                                : ''}
                                            {this.state.approvedNotReviewedDict.hasOwnProperty(district.id) &&
                                                Object.keys(this.state.approvedNotReviewedDict[district.id]).length > 0 ?
                                                <Badge
                                                    className='mr-1'
                                                    count={Object.keys(this.state.approvedNotReviewedDict[district.id]).length}
                                                    style={{ backgroundColor: '#faad14' }} />
                                                : ''}
                                            {this.state.approvedDict.hasOwnProperty(district.id) &&
                                                this.state.approvedNotReviewedDict.hasOwnProperty(district.id) &&
                                                Object.keys(this.state.approvedDict[district.id]).length +
                                                Object.keys(this.state.approvedNotReviewedDict[district.id]).length === 0 ?
                                                <Icon type='check' className='mr-1 text-success' />
                                                : ''}
                                            {district.district}</div>
                                    </Select.Option>
                                })}
                            </Select>
                        </div>
                        <Menu selectedKeys={this.state.menuKey} mode='inline' onSelect={(e) => {
                            console.log('selected', e.key)
                            this.setState({
                                menuKey: e.key,
                            })
                            this.selectQuiz(e.key)
                        }}>
                            {this.state.quizzesGroupedByWeek &&
                                Object.keys(this.state.quizzesGroupedByWeek).sort((a, b) => {
                                    const aSplit = a.split('-')
                                    const bSplit = b.split('-')
                                    let aEndWeek = aSplit[0]
                                    let bEndWeek = bSplit[0]
                                    if (aSplit.length > 1) {
                                        aEndWeek = aSplit[1]
                                    }
                                    if (bSplit.length > 1) {
                                        bEndWeek = bSplit[1]
                                    }
                                    return parseInt(bEndWeek, 10) - parseInt(aEndWeek, 10)
                                }).map((quizzes) => {
                                    return <Menu.SubMenu title={<div className='font-16'>
                                        <div className='flex flex-v-center'>
                                            {totalNotApprovedQuizzesWeek[quizzes]['not-approved'] > 0 ?
                                                <Badge
                                                    className='mr-1'
                                                    count={totalNotApprovedQuizzesWeek[quizzes]['not-approved']} />
                                                : ''}
                                            {totalNotApprovedQuizzesWeek[quizzes]['reviewed-not-approved'] > 0 ?
                                                <Badge
                                                    className='mr-1'
                                                    count={totalNotApprovedQuizzesWeek[quizzes]['reviewed-not-approved']}
                                                    style={{ backgroundColor: '#faad14' }} />
                                                : ''}
                                            {totalNotApprovedQuizzesWeek[quizzes]['not-approved'] === 0 &&
                                                totalNotApprovedQuizzesWeek[quizzes]['reviewed-not-approved'] === 0 ?
                                                <Icon type='check' className='mr-1 text-success' />
                                                : ''}
                                            {quizzes.split('-')[0] === quizzes.split('-')[1] ?
                                                'Week ' + quizzes.split('-')[0] :
                                                'Week ' + quizzes.split('-')[0] + ' - ' + quizzes.split('-')[1]}
                                        </div>
                                    </div>}>
                                        {this.state.quizzesGroupedByWeek[quizzes].map((quiz) => {
                                            return <Menu.Item key={quiz.id}>
                                                <div className='flex flex-v-center'>
                                                    {this.isNotApproved(quiz) ?
                                                        <Badge count={'1'} className='mr-1' />
                                                        : ''}
                                                    {this.isReviewedNotApproved(quiz) ?
                                                        <Badge count={'1'} className='mr-1' style={{ backgroundColor: '#faad14' }} />
                                                        : ''}
                                                    {this.isApproved(quiz) ?
                                                        <Icon type='check' className='mr-1 text-success' />
                                                        : ''}
                                                    {quiz.live ?
                                                        <div className='mr-1 background-green p-1 br-4 font-fff line-height-normal-important'>Live</div>
                                                        :
                                                        <div className='mr-1 background-grey p-1 br-4 font-black line-height-normal-important'>Not live</div>
                                                    }
                                                    <div className='font-16'>
                                                        {quiz.assessmentTitle}
                                                    </div>
                                                    {/*
                                                            <div className='font-14 text-muted ml-auto'>
                                                                {quiz.createdAt &&
                                                                    quiz.createdAt.hasOwnProperty('seconds') &&
                                                                    quiz.createdAt.seconds &&
                                                                    moment.utc(quiz.createdAt.seconds * 1000).format('MM/DD/YYYY')}
                                                            </div>
                                                                */}
                                                </div>
                                            </Menu.Item>
                                        })}
                                    </Menu.SubMenu>
                                })}
                        </Menu>
                    </div>
                </Sider>
                <Layout className="content" style={{ marginLeft: 270, marginTop: "32px", overflowX: 'initial' }}>
                    <Content
                        className={"layout-content background-fff p-3 pt-0 br-4 print-remove-padding print-remove-margin"}
                    >
                        <div className='pl-4 pr-4'>
                            <div className='question-container'>
                                {!this.state.selectedQuiz ?
                                    <div>
                                        <Empty
                                            containerClassName="mt-50 text-center"
                                            width={55}
                                            height={55}
                                            image={'/empty/goals.png'}
                                            description={'There is currently no assessment selected. Please select an assessment.'}
                                        />
                                    </div>
                                    :
                                    <div>
                                        <div className='mt-50'>
                                            {this.state.isSuper ?
                                                <div className='flex flex-v-center w-100 mb-4'>
                                                    <div>
                                                        <Popconfirm
                                                            title={"Set all tests live: " + (this.state.selectedQuiz.live ? 'false' : 'true') + "?"}
                                                            onConfirm={() => {
                                                                this.setAllTestsLive()
                                                            }}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button type='primary' size='large'>
                                                                Set All Tests Live: {this.state.selectedQuiz.live ? 'false' : 'true'}
                                                                {' (Tests for weeks ' + this.state.selectedQuiz.startWeek + '-' + this.state.selectedQuiz.endWeek + ')'}
                                                            </Button>
                                                        </Popconfirm>
                                                    </div>
                                                    <div className='ml-auto'>
                                                        {!this.state.selectedQuiz.live ?
                                                            <Popconfirm
                                                                title="Are you sure you want to delete this assessment?"
                                                                onConfirm={() => {
                                                                    this.deleteQuiz(this.state.selectedQuiz)
                                                                }}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button type='danger' size='large'
                                                                    disabled={this.state.selectedQuiz.live}
                                                                >
                                                                    Delete Assessment
                                                                </Button>
                                                            </Popconfirm> : ''}
                                                    </div>
                                                </div> : ''}
                                            {!this.state.selectedQuiz.live ?
                                                <Radio.Group size='large'
                                                    className='radio-group-grey'
                                                    value={!this.state.selectedQuiz.hasOwnProperty('approved') ?
                                                        'not-approved' : this.state.selectedQuiz.approved}
                                                    onChange={(e) => {
                                                        console.log('approved', e.target.value)
                                                        this.setQuizApproved(e.target.value)
                                                    }}>
                                                    <Radio.Button value='not-approved'>
                                                        <div className='text-danger font-bold'>
                                                            Not Approved
                                                        </div>
                                                    </Radio.Button>
                                                    <Radio.Button value='reviewed-not-approved'>
                                                        <div className='text-warning font-bold'>
                                                            Reviewed and still not approved
                                                        </div>
                                                    </Radio.Button>
                                                    <Radio.Button value='approved'>
                                                        <div className='text-success font-bold'>
                                                            <Icon type='check' className='mr-1' />
                                                            Approved
                                                        </div>
                                                    </Radio.Button>
                                                </Radio.Group> : ''}
                                        </div>
                                        <div className='mt-2 mb-2 flex font-16'>
                                            Approve the assessment only if it is ready to be taken in its current form. If there are any issues, set it to "Reviewed but still not approved" and we will fix the issues. Thank you!
                                        </div>
                                        <div className='mt-4'>
                                            <div className={'font-24 font-bold'}>
                                                {this.state.selectedQuiz.assessmentTitle}
                                            </div>
                                        </div>
                                        <div className='font-20'>{this.state.selectedQuiz.docs.length} questions</div>
                                        <div className='font-20 mt-1'>Standards:</div>
                                        <div className='mb-2'>
                                            {Object.keys(this.state.standardBreakdown).map((standard, idx) => {
                                                return <span className='font 20'>{standard} ({this.state.standardBreakdown[standard]}){idx !== Object.keys(this.state.standardBreakdown).length - 1 ? ', ' : ''}</span>
                                            })}
                                        </div>

                                        <div className='flex w-100'>
                                            {this.allQuestionsAreValid() ?
                                                <div className='font-16 font-bold text-success mb-1'>
                                                    <Icon type='check' className='mr-1' />All questions have been validated.</div>
                                                :
                                                <div className='font-16 font-bold text-danger mb-1'>
                                                    <Icon type='warning' className='mr-1' />Not all questions are valid (ids are not 0, 1, 2, 3), etc.</div>}
                                        </div>
                                        <div className='font-16 text-muted-lighter'>
                                            {this.state.selectedQuiz.createdAt &&
                                                this.state.selectedQuiz.createdAt.hasOwnProperty('seconds') &&
                                                this.state.selectedQuiz.createdAt.seconds &&
                                                moment.utc(this.state.selectedQuiz.createdAt.seconds * 1000).format('MM/DD/YYYY')}
                                        </div>


                                        {this.state.selectedQuiz.docs.length === 0 ?
                                            <div>
                                                <Empty
                                                    containerClassName="mt-50 text-center"
                                                    width={55}
                                                    height={55}
                                                    image={'/empty/goals.png'}
                                                    description={'This assessment currently has no questions. Add a question by using the menu to the right.'}
                                                />
                                            </div> : ''}

                                        {this.state.selectedQuiz.docs.map((questionDoc, questionDocIdx) => {
                                            const questionDocIdxVar = questionDocIdx + 1;
                                            return <div className='flex w-100'>
                                                <div className='mt-2 font-black font-20 border ant-shadow br-4 p-3 mb-50'
                                                    key={'assessment-idx-' + questionDocIdx}>
                                                    <div className='font-30 mb-1 flex flex-v-center'>
                                                        <div>Question {questionDocIdx + 1}</div>
                                                        <div className='ml-auto'>
                                                            <Button className='lg-btn' type='primary'
                                                                disabled={
                                                                    this.state.selectedQuiz.live ||
                                                                    this.state.selectedQuiz.approved === 'approved' ||
                                                                    this.state.reloadingQuestionsDict[questionDocIdx + 1]
                                                                }
                                                                onClick={() => {
                                                                    if (this.state.selectedQuiz.live) {
                                                                        console.error('Cannot replace question for live quiz')
                                                                        return
                                                                    }
                                                                    console.log(JSON.stringify({
                                                                        quiz_id: this.state.selectedQuiz.id,
                                                                        question_idxs: [questionDocIdx + 1],
                                                                    }));
                                                                    let reloadingQuestionsDict = this.state.reloadingQuestionsDict
                                                                    reloadingQuestionsDict[questionDocIdx + 1] = true
                                                                    this.setState({
                                                                        reloadingQuestionsDict: reloadingQuestionsDict,
                                                                    })

                                                                    firebase.auth().currentUser.getIdToken(true).then((idToken) => {
                                                                        let headers = new Headers()

                                                                        headers.append('Authorization', 'Bearer ' + idToken)
                                                                        headers.append('Origin', 'https://my.dotit.app')
                                                                        headers.append('Content-Type', 'application/json')
                                                                        headers.append('Access-Control-Allow-Origin', 'https://my.dotit.app')

                                                                        fetch("https://us-central1-education-9d7f3.cloudfunctions.net/app/dotit/replace-questions", {
                                                                            method: "POST",
                                                                            body: JSON.stringify({
                                                                                quizId: this.state.selectedQuiz.id,
                                                                                questionIdxs: [questionDocIdx + 1],
                                                                            }),
                                                                            headers: headers,
                                                                        })
                                                                            .then(res => res.json())
                                                                            .then(data => {
                                                                                let reloadingQuestionsDict = this.state.reloadingQuestionsDict
                                                                                reloadingQuestionsDict[questionDocIdx + 1] = false
                                                                                this.setState({
                                                                                    reloadingQuestionsDict: reloadingQuestionsDict,
                                                                                });
                                                                                notification.success({
                                                                                    message: 'Success',
                                                                                    description: 'Question #' + questionDocIdxVar + ' has been replaced.',
                                                                                });
                                                                            })
                                                                            .catch(e => {
                                                                                console.log("assessment error", e)
                                                                                let reloadingQuestionsDict = this.state.reloadingQuestionsDict
                                                                                reloadingQuestionsDict[questionDocIdx + 1] = false
                                                                                this.setState({
                                                                                    reloadingQuestionsDict: reloadingQuestionsDict,
                                                                                })
                                                                            })
                                                                    })
                                                                        .catch((error) => {
                                                                            console.error('error', error)
                                                                            let reloadingQuestionsDict = this.state.reloadingQuestionsDict
                                                                            reloadingQuestionsDict[questionDocIdx + 1] = false
                                                                            this.setState({
                                                                                reloadingQuestionsDict: reloadingQuestionsDict,
                                                                            })
                                                                        });
                                                                }}>
                                                                {this.state.reloadingQuestionsDict[questionDocIdx + 1] ?
                                                                    'Replacing question...'
                                                                    :
                                                                    'Replace question'}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    {!manualCheckQuestionDoc(questionDoc) ?
                                                        <div className='font-16 text-danger mb-1 font-bold'>
                                                            <Icon type='warning' className='mr-1' />This question is not valid (ids are not 0, 1, 2, 3), etc.</div>
                                                        : ''}

                                                    {/*<Document
                                                        assessment={this.state.selectedQuiz}
                                                        components={questionDoc.components}
                                                        additionalData={{
                                                            showAllReasons: true,
                                                        }}
                                                    />*/}

                                                    <DocumentDelegator
                                                        assessment={this.state.selectedQuiz}
                                                        document={{
                                                            components: questionDoc.components,
                                                            metadata: { 'edvizVersion': '2' }
                                                        }}
                                                        additionalData={{
                                                            showAllReasons: true,
                                                        }}
                                                    />

                                                    <div className='mt-4'>
                                                        <div className='font-16 font-bold'>
                                                            This question is for the standard: {questionDoc.metadata.standard.standard}
                                                        </div>
                                                        {questionDoc.metadata.standard.hasOwnProperty('isPriority') &&
                                                            questionDoc.metadata.standard.isPriority ?
                                                            <div className='font-16'>*Priority Standard</div>
                                                            : ''}
                                                        <div className='mt-1 font-16'>{questionDoc.metadata.standard.title}</div>
                                                    </div>
                                                </div>

                                            </div>
                                        })}
                                    </div>}
                            </div>
                            <div className='mt-50'>
                                <CustomFooter />
                            </div>
                        </div>
                    </Content>
                </Layout>
            </div>

        </div>
    }
}

export default SuperAdminTestReview